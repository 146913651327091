import React, { useState } from "react";
import { Form, Modal, Input, Upload, notification } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

// import { AuthContext } from '../../../App';
import { useDocumentUpload } from "../../../hooks/useDocumentUpload";

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

const AddDocumentModal = (props) => {
  const { t } = useTranslation();

  // const { authState } = useContext(AuthContext);

  const { uploadDocument } = useDocumentUpload();
  const { docType, item, visible, setVisible, dispatch } = props;

  const [form] = Form.useForm();

  const [attachedFileList, setAttachedFileList] = useState([]);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleOk = (e) => {
    e.preventDefault();

    form.validateFields().then(async (values, err) => {
      if (!err) {
        setIsSubmitting(true);
        // console.log(attachedFileList);
        const formData = new FormData();
        formData.append("docType", docType);
        formData.append("name", values.name);
        formData.append("description", values.description);
        // formData.append('trackThroughAntChain', values.trackThroughAntChain ? true : false);
        formData.append("foreignId", item.id);

        attachedFileList.forEach((file) => {
          formData.append("docs", file);
        });

        const res = await uploadDocument(formData);

        if (res.status === "success") {
          notification.success({
            message: t("notification.success"),
            top: 72,
          });
          dispatch({ type: "refresh" });
          closeModal();
        } else {
          notification.error({
            message: t("notification.success"),
            top: 72,
          });
        }
        setIsSubmitting(false);
      }
    });
  };

  const closeModal = () => setVisible(false);

  const handleCancel = () => closeModal();

  return (
    <Modal
      title={t("documentUpload.title")}
      destroyOnClose
      visible={visible}
      confirmLoading={isSubmitting}
      onOk={handleOk}
      onCancel={handleCancel}
      okText={t("action.submit")}
    >
      <Form form={form} {...layout} name="document-upload-form">
        <Form.Item name="name" label={t("documentUpload.docName")} rules={[{ required: true }]}>
          <Input />
        </Form.Item>

        <Form.Item name="description" label={t("common.description")}>
          <Input.TextArea />
        </Form.Item>

        {/* <Form.Item
					name='trackThroughAntChain'
					label={t('common.trackThroughAntChain')}
					valuePropName='checked'
				>
					<Checkbox />
				</Form.Item> */}

        <Form.Item name="uploads" label={t("common.attachment")} rules={[{ required: true }]}>
          <div>
            <Upload
              name="logo"
              accept=".pdf,.jpg,.png"
              beforeUpload={() => false}
              onChange={(info) => {
                setAttachedFileList(info.fileList.map((item) => item.originFileObj));
              }}
              multiple={true}
              onPreview={() => {}}
              listType="picture-card"
            >
              <PlusOutlined />
              <div>.pdf .jpg .png</div>
            </Upload>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  );
};
export default AddDocumentModal;
