import { useCallback, useContext } from "react";
import { AuthContext, fp } from "../App";
import Constants from "../utils/Constants";
import { useFetch } from "../hooks/useFetch";

const adminBaseUrl = Constants.SERVER_URL + "/wapi/account";
const publicBaseUrl = Constants.SERVER_URL + "/wapi-501/account";

const useAccount = () => {
  const { authState } = useContext(AuthContext);
  const { send } = useFetch();

  const findAccountInfo = useCallback(async () => {
    const config = {
      method: "get",
      headers: {
        Authorization: "Bearer " + authState.token,
        "Content-Type": "application/json",
        FpId: (await fp).visitorId,
      },
    };
    return await send(adminBaseUrl, config);
  }, [authState.token, send]);

  const create = useCallback(
    async (params) => {
      const config = {
        method: "post",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
        body: JSON.stringify(params),
      };

      return await send(publicBaseUrl, config);
    },
    [authState.token, send]
  );

  const update = useCallback(
    async (id, params) => {
      const config = {
        method: "post",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
        body: JSON.stringify(params),
      };

      return await send(adminBaseUrl + "/" + id, config);
    },
    [authState.token, send]
  );

  //   const uploadAvatar = useCallback(
  //     async (params) => {
  //       const url = apiBaseUrl + "/account/uploadAvatar";

  //       const formData = new FormData();
  //       formData.append("avatar", params[0]);

  //       const config = {
  //         method: "post",
  //         headers: {
  //           Authorization: "Bearer " + authCtx.token,
  //           FpId: (await fp).visitorId,
  //         },
  //         body: formData,
  //       };
  //       return await send(url, config);
  //     },
  //     [authCtx]
  //   );

  //   const updateProfile = useCallback(
  //     async (params) => {
  //       const url = apiBaseUrl + "/account/updateProfile";
  //       const config = {
  //         method: "post",
  //         headers: {
  //           Authorization: "Bearer " + authCtx.token,
  //           "Content-Type": "application/json",
  //           FpId: (await fp).visitorId,
  //         },
  //         body: JSON.stringify(params),
  //       };
  //       return await send(url, config);
  //     },
  //     [authCtx]
  //   );

  //   const uploadLogo = useCallback(
  //     async (params) => {
  //       const url = apiBaseUrl + "/account/uploadLogo";

  //       const formData = new FormData();
  //       formData.append("businessLogo", params[0]);

  //       const config = {
  //         method: "post",
  //         headers: {
  //           Authorization: "Bearer " + authCtx.token,
  //           FpId: (await fp).visitorId,
  //         },
  //         body: formData,
  //       };
  //       return await send(url, config);
  //     },
  //     [authCtx]
  //   );

  //   const uploadCert = useCallback(
  //     async (params) => {
  //       const url = apiBaseUrl + "/account/uploadCert";

  //       const formData = new FormData();
  //       formData.append("businessCert", params[0]);

  //       const config = {
  //         method: "post",
  //         headers: {
  //           Authorization: "Bearer " + authCtx.token,
  //           FpId: (await fp).visitorId,
  //         },
  //         body: formData,
  //       };
  //       return await send(url, config);
  //     },
  //     [authCtx]
  //   );

  //   const updatePlanData = useCallback(
  //     async (params) => {
  //       const url = BaseAPIUrl + "/account/updatePlanData";
  //       const config = {
  //         method: "post",
  //         headers: {
  //           Authorization: "Bearer " + authCtx.token,
  //           "Content-Type": "application/json",
  //           FpId: (await fp).visitorId,
  //         },
  //         body: JSON.stringify(params),
  //       };
  //       return await fetch(url, config)
  //         .then((res) => res.json())
  //         .catch((err) => err);
  //     },
  //     [authCtx]
  //   );

  return {
    findAccountInfo,
    create,
    update,
    // uploadAvatar,
    // updateProfile,
    // uploadLogo,
    // uploadCert,
    // updatePlanData,
  };
};

export { useAccount };
