import React from "react";
import { Modal } from "antd";
import { useTranslation } from "react-i18next";

const ConfirmDeleteModal = (props) => {
  const { visible, setVisible, onOk } = props;

  const { t } = useTranslation();

  return (
    <Modal visible={visible} onOk={onOk} onCancel={() => setVisible(false)}>
      <p>{t("message.areYouSureToDeleteRecord")}</p>
    </Modal>
  );
};

export default ConfirmDeleteModal;
