import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import en from "./en.json";
import cn from "./cn.json";


i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      cn: {
        common: cn
      },
      en: {
        common: en
      }
    },
    lng: "en",
    fallbackLng: "en",
    ns: ["common"],
    defaultNS: "common",
    interpolation: {
      escapeValue: false // react already safes from xss
    },
    react: {
      wait: false,
      bindI18n: "languageChanged loaded",
      bindStore: "added removed",
      nsMode: "default"
    }
  });

export default i18n;


