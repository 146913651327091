import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Typography, Space } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { red } from "@ant-design/colors";
import TableLoader from "../../../components/common/TableLoader";

const { Link } = Typography;

const CollaboratorTable = (props) => {
  const { t } = useTranslation();

  const { dataSource, loading, error, acceptInvitation, declineInvitation, removeRow, viewDetail } = props;

  const columns = [
    {
      title: t("common.id"),
      dataIndex: "id",
    },
    {
      title: t("common.name"),
      render: (text, row) => (
        <Link onClick={() => viewDetail(row.CollaboratorCompany.id)}>{row.CollaboratorCompany.name}</Link>
      ),
    },
    {
      title: t("common.contact_no"),
      render: (text, row) => (row.CollaboratorCompany.contactNo ? row.CollaboratorCompany.contactNo : "-"),
    },
    {
      title: t("common.address"),
      render: (text, row) => (row.CollaboratorCompany.address ? row.CollaboratorCompany.address : "-"),
    },
    {
      title: t("common.city"),
      render: (text, row) => (row.CollaboratorCompany.city ? row.CollaboratorCompany.city : "-"),
    },
    {
      title: t("common.region"),
      render: (text, row) => (row.CollaboratorCompany.region ? row.CollaboratorCompany.region : "-"),
    },
    {
      title: t("common.country"),
      render: (text, row) => (row.CollaboratorCompany.country ? row.CollaboratorCompany.country : "-"),
    },
    {
      title: t("common.status"),
      dataIndex: "status",
    },
    {
      title: t("common.action"),
      key: "action",
      render: (text, row) => renderAction(row),
    },
  ];

  const renderAction = (row) => {
    // If status is Pending,
    if (row.status === "Pending") {
      // invitee can accept/decline
      if (row.companyId === row.inviteeCompanyId) {
        return (
          <Space size="small">
            <Button type="link" onClick={() => acceptInvitation(row)}>
              {t("action.accept")}
            </Button>
            <Button type="link" onClick={() => declineInvitation(row)}>
              {t("action.decline")}
            </Button>
          </Space>
        );
      }
      // inviter can remove invitation
      else {
        return (
          <Button type="text" icon={<DeleteOutlined style={{ color: red.primary }} />} onClick={() => removeRow(row)} />
        );
      }
    }
    // If status is Accepted, then both parties can remove
    else if (row.status === "Accept") {
      return (
        <Button type="text" icon={<DeleteOutlined style={{ color: red.primary }} />} onClick={() => removeRow(row)} />
      );
    }
  };

  return (
    <TableLoader error={error} loading={loading} dataSource={dataSource} columns={columns} style={{ marginTop: 24 }} />
  );
};

export default CollaboratorTable;
