import React, { useEffect, useReducer, useState } from "react";
import { ConfigProvider, Alert, Button } from "antd";
import "./App.css";
import "antd/dist/antd.css";

import { I18nextProvider } from "react-i18next";
import i18n from "./locales/i18n";

import BaseLayout from "./components/layouts/BaseLayout";
import PageLoader from "./components/common/PageLoader";
// import { auth } from './services/AuthService';
import { useAuth } from "./hooks/useAuth";

import FingerprintJS from "@fingerprintjs/fingerprintjs";
import RolePermission from "./utils/RolePermission";
import UserRole from "./utils/UserRole";

import enUS from "antd/es/locale/en_US";
import zhCN from "antd/es/locale/zh_CN";
import moment from "moment";
import "moment/locale/zh-cn";

import { useSelector } from "react-redux";

moment.locale("en");

export const fp = (async () => {
  const fp = await FingerprintJS.load();
  return await fp.get();
})();

export const LocaleContext = React.createContext();

const localeReducer = (state, action) => {
  if (action === "en") {
    return enUS;
  } else if (action === "cn") {
    return zhCN;
  }
  return enUS;
};

export const AuthContext = React.createContext();
export const ResponsiveContext = React.createContext();

const initialState = {
  token: null,
  authUser: null,
  isAuthenticated: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        isAuthenticated: true,
        authUser: action.payload.authUser,
        token: action.payload.token,
      };
    case "LOGOUT":
      return {
        ...state,
        isAuthenticated: false,
        authUser: null,
        token: null,
      };
    case "UPDATE":
      return {
        ...state,
        authUser: action.payload.authUser,
      };
    default:
      return state;
  }
};

const App = () => {
  const isServiceWorkerUpdated = useSelector((state) => state.serviceWorkerUpdated);
  const serviceWorkerRegistration = useSelector((state) => state.serviceWorkerRegistration);

  // SEM: localeContext for Config Provider is used for AntD component.
  //      Not to mix up with I18Next (application)
  const [locale, setLocaleContext] = useReducer(localeReducer, enUS);

  const [authState, dispatch] = useReducer(reducer, initialState);

  const [isMobile, setIsMobile] = useState();

  const [loading, setLoading] = useState(true);

  const { auth } = useAuth();
  // const { httpPost } = useFetch('security');

  useEffect(() => {
    const fetchData = async () => {
      const res = await auth();

      if (res.status === "success") {
        dispatch({
          type: "LOGIN",
          payload: {
            authUser: res.data.auth,
            token: res.data.token,
          },
        });
      }
      setLoading(false);
    };
    fetchData();
  }, [auth]);

  useEffect(() => {
    if (isServiceWorkerUpdated) {
      updateServiceWorker();
    }
  }, [isServiceWorkerUpdated]);

  const can = (route) => {
    const email = authState.authUser ? authState.authUser.email : "";

    if (UserRole[email] && UserRole[email].length) {
      const roles = UserRole[email];

      for (const role of roles) {
        if (role === "SUPERUSER") {
          return true;
        }
        const [entity, r] = role.split(".");

        const permissions = RolePermission[entity][r];
        if (permissions.indexOf(route) > -1) {
          return true;
        }
        return false;
      }
    } else {
      return false;
    }
  };

  const updateServiceWorker = () => {
    const registrationWaiting = serviceWorkerRegistration.waiting;

    if (registrationWaiting) {
      registrationWaiting.postMessage({ type: "SKIP_WAITING" });

      registrationWaiting.addEventListener("statechange", (e) => {
        if (e.target.state === "activated") {
          window.location.reload();
        }
      });
    }
  };

  return (
    <LocaleContext.Provider
      value={{
        setLocaleContext,
      }}
    >
      <ConfigProvider locale={locale}>
        <I18nextProvider i18n={i18n}>
          <AuthContext.Provider
            value={{
              authState,
              dispatch,
              can,
            }}
          >
            <ResponsiveContext.Provider
              value={{
                isMobile,
                setIsMobile,
              }}
            >
              {process.env.REACT_APP_ENV === "staging" && (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Alert
                    style={{ position: "absolute", zIndex: 99 }}
                    banner
                    message="Test Site"
                    type="error"
                    closable={false}
                    showIcon={false}
                  />
                </div>
              )}
              {loading ? <PageLoader /> : <BaseLayout />}
            </ResponsiveContext.Provider>
          </AuthContext.Provider>
        </I18nextProvider>
      </ConfigProvider>
    </LocaleContext.Provider>
  );
};

export default App;
