import React from "react";
import { Input, InputNumber, Table } from "antd";
import { useTranslation } from "react-i18next";
import TableLoader from "../common/TableLoader";

const ShipmentOrderDetailTable = (props) => {
  const { t } = useTranslation();

  const { dataSource, loading, error, updateProduct } = props;

  const columns = [
    {
      title: t("common.product"),
      render: (text, row) => row.Product.productName,
    },
    {
      title: t("common.price"),
      dataIndex: "uomPrice",
      render: (text, row) =>
        Number(text).toLocaleString(undefined, {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }) +
        " / " +
        row.Uom.shortCode,
    },
    {
      title: t("common.quantity"),
      dataIndex: "newQuantityInUom",
      render: (text, row) => (
        <>
          <InputNumber
            defaultValue={row.maxQuantity}
            min={0}
            max={row.maxQuantity}
            onChange={(value) => onQuantityChanged(value, row)}
          />{" "}
          / {row.maxQuantity} {row.Uom.shortCode} <sub>{t("common.remain")}</sub>
        </>
      ),
    },
    {
      title: t("common.remarks"),
      dataIndex: "remarks",
      render: (text, row) => <Input value={row.remarks} onChange={(e) => onRemarksChanged(e, row)} />,
    },
  ];

  const onQuantityChanged = (value, row) => {
    updateProduct({
      ...row,
      newQuantityInUom: value,
    });
  };

  const onRemarksChanged = (e, row) => {
    updateProduct({
      ...row,
      remarks: e.target.value,
    });
  };

  return (
    <TableLoader error={error} dataSource={dataSource} loading={loading}>
      <Table rowKey="id" size="small" error={error} loading={loading} dataSource={dataSource} columns={columns} />
    </TableLoader>
  );
};

export default ShipmentOrderDetailTable;
