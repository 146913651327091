import React from "react";
import { Form, Steps, Divider } from "antd";
import SignUpFormAccountInfo from "./SignUpFormAccountInfo";
import SignUpFormContactInfo from "./SignUpFormContactInfo";
import SignUpFormSubscription from "./SignUpFormSubscription";
import SignUpFormBillingInfo from "./SignUpFormBillingInfo";
import SignUpFormComplete from "./SignUpFormComplete";

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

const { Step } = Steps;

const SignUpForm = (props) => {
  const { form, currentStep, planOption, selectedPlan, setSelectedPlan, loading } = props;

  return (
    <Form {...layout} form={form} name="signup-form" autoComplete="true" labelAlign="left">
      <Steps current={currentStep} size="small">
        <Step title="Plan" />
        <Step title="Account" />
        <Step title="Company" />
        <Step title="Billing" />
        <Step title="Complete" />
      </Steps>

      <Divider />

      <SignUpFormSubscription
        form={form}
        hidden={!(currentStep === 0)}
        planOption={planOption}
        selectedPlan={selectedPlan}
        setSelectedPlan={setSelectedPlan}
        loading={loading}
      />

      <SignUpFormAccountInfo hidden={!(currentStep === 1)} />

      <SignUpFormContactInfo hidden={!(currentStep === 2)} />

      <SignUpFormBillingInfo hidden={!(currentStep === 3)} />

      {currentStep === 4 && <SignUpFormComplete />}
    </Form>
  );
};

export default SignUpForm;
