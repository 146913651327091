import React, { useState, useEffect, useReducer, useContext } from "react";
import { Typography, Button, Row, Col, Space } from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { fetchDataReducer } from "../../reducers/fetchDataReducer";

import CollaboratorTable from "./tables/CollaboratorTable";
import AddModal from "./modals/AddModal";
// import EditModal from "./modals/EditModal";
// import DetailDrawer from "./drawers/DetailDrawer";
import { useCollaborator } from "../../hooks/useCollaborator";
import { AccountContext } from "../../components/layouts/BaseLayout";
import ConfirmDeleteModal from "../../components/common/ConfirmDeleteModal";
import CompanyDetailDrawer from "../../components/company/CompanyDetailDrawer";
import { useInvitation } from "../../hooks/useInvitation";
import PendingInviteModal from "./modals/PendingInviteModal";

const { Title } = Typography;

// Use reducer here because these 3 states are all inter-connected.
const initialState = {
  refresh: true,
  loading: true,
  error: null,
};

const Collaborator = () => {
  const { account } = useContext(AccountContext);

  const { t } = useTranslation();
  const [state, dispatch] = useReducer(fetchDataReducer, initialState);

  const { findAll, attendInvitation, removeCollaborator } = useCollaborator();
  const { findAll: findPendingInvitation } = useInvitation();
  const [pendingInviteData, setPendingInviteData] = useState([]);
  const [data, setData] = useState([]);
  const [editItem, setEditItem] = useState({});
  const [companyId, setCompanyId] = useState();

  const [addModal, setAddModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [pendingInviteModal, setPendingInviteModal] = useState(false);
  const [detailDrawer, setDetailDrawer] = useState(false);

  useEffect(() => {
    if (state.refresh && account.Companies) {
      findAll({
        companyId: account.Companies[0].id,
      }).then((res) => {
        if (res.status === "success") {
          setData(res.data);
          dispatch({ type: "complete" });
        } else {
          setData([]);
          dispatch({ type: "error", error: t("error.connectionError") });
        }
      });

      findPendingInvitation({ companyId: account.Companies[0].id, status: "Pending" }).then((res) => {
        if (res.status === "success") {
          console.log(res.data);
          setPendingInviteData(res.data);
        }
      });
    }
  }, [state.refresh, findAll, t, account.Companies]);

  useEffect(() => {
    if (editItem.id) {
      const index = data.map((item) => item.id).indexOf(editItem.id);

      if (index > -1) {
        setEditItem(data[index]);
      }
    }
  }, [editItem, data]);

  const acceptInvitation = (item) => {
    attendInvitation(item.id, { action: "Accept" }).then((res) => {
      if (res.status === "success") {
        dispatch({ type: "refresh" });
      }
    });
  };

  const declineInvitation = (item) => {
    attendInvitation(item.id, { action: "Decline" }).then((res) => {
      if (res.status === "success") {
        dispatch({ type: "refresh" });
      }
    });
  };

  const removeRow = (item) => {
    setEditItem(item);
    setDeleteModal(true);
  };

  const viewDetail = (id) => {
    setCompanyId(id);
    setDetailDrawer(true);
  };

  const confirmDelete = () => {
    removeCollaborator(editItem.id).then((res) => {
      if (res.status === "success") {
        dispatch({ type: "refresh" });
        setDeleteModal(false);
      }
    });
  };

  return (
    <div className="site-layout-background" style={{ padding: 24, marginTop: 16 }}>
      <Title level={2}>{t("collaborator.title")}</Title>

      <Row justify="space-between">
        <Col>
          <Space>
            {account.cbSubscription && account.cbSubscription.id ? (
              <Button disabled={state.loading} type="primary" onClick={() => setAddModal(true)}>
                {t("collaborator.invite")}
              </Button>
            ) : null}

            {pendingInviteData.length ? (
              <Button type="link" onClick={() => setPendingInviteModal(true)}>
                {pendingInviteData.length} pending invitation
              </Button>
            ) : null}
          </Space>
        </Col>
        <Col>
          <Button icon={<ReloadOutlined />} disabled={state.loading} onClick={() => dispatch({ type: "refresh" })} />
        </Col>
      </Row>

      <CollaboratorTable
        error={state.error}
        loading={state.loading}
        dataSource={data}
        acceptInvitation={acceptInvitation}
        declineInvitation={declineInvitation}
        removeRow={removeRow}
        viewDetail={viewDetail}
      />

      <AddModal visible={addModal} setVisible={setAddModal} dispatch={dispatch} />
      <PendingInviteModal
        visible={pendingInviteModal}
        setVisible={setPendingInviteModal}
        dispatch={dispatch}
        data={pendingInviteData}
      />
      <ConfirmDeleteModal visible={deleteModal} setVisible={setDeleteModal} onOk={confirmDelete} />

      <CompanyDetailDrawer
        id={companyId}
        visible={detailDrawer}
        setVisible={setDetailDrawer}
        state={state}
        dispatch={dispatch}
      />
    </div>
  );
};

export default Collaborator;
