import React, { useState, useEffect } from "react";
import { List, Row, Col, Input, Typography, Space, Button } from "antd";
import { useTranslation } from "react-i18next";
import { ReloadOutlined } from "@ant-design/icons";
// import DocumentAssetsDrawer from "../drawers/DocumentAssetsDrawer";
import { useDocumentUpload } from "../../../hooks/useDocumentUpload";

const moment = require("moment");
const { Search } = Input;
const { Link } = Typography;

const DocumentList = (props) => {
  const { t } = useTranslation();

  const {
    docType,
    foreignId,
    // canUpload
  } = props;

  const { findAll, download } = useDocumentUpload();

  const [refresh, setRefresh] = useState(true);
  const [data, setData] = useState([]);
  // const [documentUploadItem, setDocumentUploadItem] = useState({});
  // const [showDocumentAssetsDrawer, setShowDocumentAssetsDrawer] = useState(false);
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    if ((docType && foreignId) || refresh) {
      findAll({
        docType: docType,
        foreignId: foreignId,
      }).then((res) => {
        if (res.status === "success") {
          setData(res.data);
        }
        setRefresh(false);
      });
    }
  }, [docType, foreignId, refresh]);

  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  const onSearchChanged = (e) => {
    const value = e.target.value;
    if (value && value.length) {
      let array = data.filter((item) => {
        return item.name.toLowerCase().includes(value.toLowerCase()) || item.description.toLowerCase().includes(value.toLowerCase());
      });
      setFilteredData(array);
    } else {
      setFilteredData(data);
    }
  };

  const viewDoc = (item) => {
    download({ key: item.filePath }).then(async (res) => {
      const stream = await res.body.getReader().read();

      const file = new Blob([stream.value], {
        type: item.fileType,
      });

      const fileURL = URL.createObjectURL(file);

      window.open(fileURL);
    });
  };

  return (
    <div>
      <Row justify="end">
        <Col span={24} style={{ textAlign: "right" }}>
          <Space>
            <Search
              placeholder={t("documentUpload.searchDocument")}
              // style={{ width: 200 }}
              onChange={onSearchChanged}
            />
            <Button icon={<ReloadOutlined />} onClick={() => setRefresh(true)} />
          </Space>
        </Col>
      </Row>

      <div style={{ height: 16 }}></div>

      <List
        itemLayout="vertical"
        size="small"
        pagination={{
          onChange: (page) => {
            console.log(page);
          },
          pageSize: 10,
        }}
        dataSource={filteredData}
        renderItem={(item, index) => (
          <List.Item style={{ background: "#fafafa", paddingLeft: 8, paddingRight: 8, marginBottom: 8 }} key={item.id}>
            <List.Item.Meta title={index + 1 + ". " + item.name} description={item.description !== "undefined" ? item.description : null} />
            <Row>
              <Col>
                <h4>{moment(item.createdAt).format("lll")}</h4>
                <ul>
                  {item.assets.fileList.map((item) => (
                    <li key={item.fileName}>
                      <Link onClick={() => viewDoc(item)}>{item.fileName}</Link>
                    </li>
                  ))}
                </ul>
              </Col>
            </Row>
          </List.Item>
        )}
      />
      {/* 
      <DocumentAssetsDrawer
        canUpload={canUpload}
        documentUploadItem={documentUploadItem}
        visible={showDocumentAssetsDrawer}
        onClose={() => setShowDocumentAssetsDrawer(false)}
      /> */}
    </div>
  );
};

export default DocumentList;
