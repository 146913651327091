import React, { useState, useEffect, useContext } from "react";
import { Layout, Menu, Row, Button } from "antd";
import { useTranslation } from "react-i18next";
import BasicInfo from "./components/BasicInfo";
import SecuritySettings from "./components/SecuritySettings";
import Preferences from "./components/Preferences";
import Subscription from "./components/Subscription";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { ResponsiveContext } from "../../App";
import CompanyInfo from "./components/CompanyInfo";
import { AccountContext } from "../../components/layouts/BaseLayout";
import Invitation from "./components/Invitation";

const { Content, Sider } = Layout;

const Account = () => {
  const { t } = useTranslation();

  const { isMobile } = useContext(ResponsiveContext);

  const { account, setAccount } = useContext(AccountContext);

  const [collapsed, setCollapsed] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState("basic");

  useEffect(() => {
    if (isMobile) {
      setCollapsed(true);
    } else {
      setCollapsed(false);
    }
  }, [isMobile]);

  const onMenuSelected = (menu) => {
    setSelectedMenu(menu.key);
  };

  const renderContent = () => {
    switch (selectedMenu) {
      case "basic":
        return <BasicInfo account={account} setAccount={setAccount} />;

      case "company":
        return <CompanyInfo account={account} setAccount={setAccount} />;

      case "security":
        return <SecuritySettings />;

      case "preference":
        return <Preferences />;

      case "subscription":
        return <Subscription account={account} setAccount={setAccount} />;

      case "invitation":
        return <Invitation account={account} />;

      default:
        return <BasicInfo account={account} setAccount={setAccount} />;
    }
  };

  const toggle = () => {
    setCollapsed(!collapsed);
  };

  return (
    <div className="site-layout-background" style={{ padding: 24, marginTop: 16 }}>
      <Layout>
        <Sider width={200} collapsedWidth="0" breakpoint="sm" trigger={null} collapsed={collapsed}>
          <Menu mode="inline" style={{ height: "100%" }} defaultSelectedKeys={["basic"]} onClick={onMenuSelected}>
            <Menu.Item key="basic">{t("account.setting.account")}</Menu.Item>
            <Menu.Item key="company">{t("account.setting.company")}</Menu.Item>
            <Menu.Item key="subscription">{t("account.setting.subscription")}</Menu.Item>
            <Menu.Item key="security">{t("account.setting.security")}</Menu.Item>
            <Menu.Item key="invitation">{t("account.setting.invitation")}</Menu.Item>
            {/* 
                        <Menu.Item key="preference">
                            {t('account.setting.preference')}
                        </Menu.Item> */}
          </Menu>
        </Sider>

        <Content style={{ padding: "0 24px", background: "#fff" }}>
          {isMobile && (
            <Row justify="end">
              <Button onClick={toggle} icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />} />
            </Row>
          )}

          {renderContent()}
        </Content>
      </Layout>
    </div>
  );
};

export default Account;
