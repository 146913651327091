import React, { useEffect, useState } from "react";
import { Form, InputNumber, Modal, Input } from "antd";
import { useTranslation } from "react-i18next";
import { useShipmentOrderDetail } from "../../hooks/useShipmentOrderDetail";

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

const EditOrderItemModal = (props) => {
  const { editItem, visible, setVisible, dispatch } = props;

  const { t } = useTranslation();

  const [form] = Form.useForm();

  const { update } = useShipmentOrderDetail();

  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        quantityInUom: editItem.quantityInUom,
        remarks: editItem.remarks,
      });
    }
  }, [visible, form, editItem]);

  const handleOk = (e) => {
    form.validateFields().then((values, err) => {
      if (!err) {
        const params = {
          quantityInUom: values.quantityInUom,
          remarks: values.remarks,
        };
        setSubmitting(true);
        update(editItem.id, params).then((res) => {
          if (res.status === "success") {
            dispatch({ type: "refresh" });
            setVisible(false);
            form.resetFields();
          }
          setSubmitting(false);
        });
      }
    });
  };

  const handleCancel = () => setVisible(false);

  return (
    <Modal
      title={editItem.Product?.productName}
      confirmLoading={submitting}
      centered
      visible={visible}
      destroyOnClose
      onCancel={handleCancel}
      onOk={handleOk}
      okText={t("action.submit")}
      cancelText={t("action.cancel")}
    >
      <Form form={form} {...layout} name="order-item-form">
        <Form.Item
          name="quantityInUom"
          label={t("common.quantity")}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <InputNumber min={0} allowClear />
        </Form.Item>

        <Form.Item label={t("common.price")}>{editItem.uomPrice ? editItem.uomPrice : "-"}</Form.Item>

        <Form.Item label={t("common.uom")}>{editItem.Uom ? editItem.Uom.shortCode : "-"}</Form.Item>

        <Form.Item name="remarks" label={t("common.remarks")}>
          <Input.TextArea rows={5} allowClear />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditOrderItemModal;
