import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Layout, Menu } from "antd";
import { BarChartOutlined, FileDoneOutlined, TeamOutlined } from "@ant-design/icons";
import "./SideMenu.css";
import logomini from "../../../assets/logomini.png";
import logo from "../../../assets/logoheader.png";
import { AuthContext, ResponsiveContext } from "../../../App";

const { Sider } = Layout;

const { SubMenu } = Menu;

const SideMenu = (props) => {
  const { collapsed, setCollapsed } = props;

  const { can } = useContext(AuthContext);
  const { isMobile, setIsMobile } = useContext(ResponsiveContext);

  const [collapsedWidth, setCollapsedWidth] = useState();

  useEffect(() => {
    if (isMobile) {
      setCollapsedWidth(0);
    } else {
      setCollapsedWidth(80);
    }
  }, [isMobile]);

  const onCollapse = (collapsed) => {
    setCollapsed(collapsed);
  };

  const onBreakpoint = (broken) => {
    setIsMobile(broken);
  };

  const onSelect = (e) => {
    if (isMobile) {
      setCollapsed(true);
    }
  };

  return (
    <Sider
      trigger={null}
      collapsible
      collapsed={collapsed}
      collapsedWidth={collapsedWidth}
      onCollapse={onCollapse}
      width={210}
      breakpoint="sm"
      onBreakpoint={onBreakpoint}
    >
      <div style={{ padding: 10 }} align="center">
        <img alt="logo" src={collapsed ? logomini : logo} width={120} />
      </div>

      <Menu theme="dark" defaultSelectedKeys={["1"]} mode="inline" onSelect={onSelect}>
        <Menu.Item key="dashboard" icon={<BarChartOutlined />}>
          <Link to="/dashboard">Dashboard</Link>
        </Menu.Item>
        <Menu.Item key="salesOrder" icon={<FileDoneOutlined />}>
          <Link to="/salesOrder">Sales Order</Link>
        </Menu.Item>

        <Menu.Item key="purchaseOrder" icon={<FileDoneOutlined />}>
          <Link to="/purchaseOrder">Purchase Order</Link>
        </Menu.Item>

        <Menu.Item key="product" icon={<FileDoneOutlined />}>
          <Link to="/product">Product</Link>
        </Menu.Item>
        <Menu.Item key="uom" icon={<FileDoneOutlined />}>
          <Link to="/uom">UOM</Link>
        </Menu.Item>
        <Menu.Item key="collaborator" icon={<TeamOutlined />}>
          <Link to="/collaborator">Collaborator</Link>
        </Menu.Item>

        {/* <SubMenu key="incoming" title="Incoming" icon={<FileDoneOutlined />}>
          <Menu.Item key="blanketOrder?m=in">
            <Link to="/blanketOrder?m=in">Blanket Order</Link>
          </Menu.Item>
          <Menu.Item key="shipmentOrder?m=in">
            <Link to="/shipmentOrder?m=in">Shipment Order</Link>
          </Menu.Item>
        </SubMenu> */}

        {/* <SubMenu key="outgoing" title="Outgoing" icon={<FileDoneOutlined />}>
          <Menu.Item key="blanketOrder?m=out">
            <Link to="/blanketOrder?m=out">Blanket Order</Link>
          </Menu.Item>
          <Menu.Item key="shipmentOrder?m=out">
            <Link to="/shipmentOrder?m=out">Shipment Order</Link>
          </Menu.Item>
        </SubMenu> */}

        {/* {can("Hatchery") && (
          <SubMenu key="hatchery" title="Hatchery" icon={<FileDoneOutlined />}>
            {can("HatcheryDashboard") && (
              <Menu.Item key="hatcheryDashboard">
                <Link to="/hatcheryDashboard">Dashboard</Link>
              </Menu.Item>
            )}

            {can("HatcheryReceipt") && (
              <Menu.Item key="hatcheryReceipts">
                <Link to="/hatcheryReceipts">Broodstock</Link>
              </Menu.Item>
            )}

            {can("IncubationRecord") && (
              <Menu.Item key="incubationRecord">
                <Link to="/incubationRecords">Incubation Record</Link>
              </Menu.Item>
            )}

            {can("TankTransfer") && (
              <Menu.Item key="tankUsages">
                <Link to="/tankUsages">Larval Tank Transfer</Link>
              </Menu.Item>
            )}

            {can("LarvalDailyRecord") && (
              <Menu.Item key="dailyLarvalRecord">
                <Link to="/dailyLarvalRecords">Daily Larval Record</Link>
              </Menu.Item>
            )}

            {can("LarvalHistoryRecord") && (
              <Menu.Item key="larvalHistoryRecords">
                <Link to="/larvalHistoryRecords">Larval Record History</Link>
              </Menu.Item>
            )}

            {can("HatcheryProduction") && (
              <Menu.Item key="hatcheryProduction">
                <Link to="/hatcheryProductions">Hatchery Harvest</Link>
              </Menu.Item>
            )}

            {can("HatcheryTransfer") && (
              <Menu.Item key="hatcheryTransfers">
                <Link to="/hatcheryTransfers">Hatchery Transfer</Link>
              </Menu.Item>
            )}

            {can("HatcheryReport") && (
              <Menu.Item key="larvalDataRpt">
                <Link to="/larvalDataRpt">Report</Link>
              </Menu.Item>
            )}
          </SubMenu>
        )}

        {can("Farm") && (
          <SubMenu key="farm" title="Farm" icon={<FileDoneOutlined />}>
            {can("FarmDashboard") && (
              <Menu.Item key="farmDashboard">
                <Link to="/farmDashboard">Dashboard</Link>
              </Menu.Item>
            )}

            {can("PondPreparationRecord") && (
              <Menu.Item key="pondPreparationRecords">
                <Link to="/pondPreparationRecords">Pond Preparation</Link>
              </Menu.Item>
            )}

            {can("FarmReceipt") && (
              <Menu.Item key="farmReceipts">
                <Link to="/farmReceipts">Hatchery to Farm</Link>
              </Menu.Item>
            )}

            {can("HapaRecord") && (
              <Menu.Item key="hapaRecords">
                <Link to="/hapaRecords">Nursery Record</Link>
              </Menu.Item>
            )}

            {can("GrowOutRecord") && (
              <Menu.Item key="growOutRecords">
                <Link to="/growOutRecords">Grow Out Record</Link>
              </Menu.Item>
            )}

            {can("GrowOutRecord") && (
              <Menu.Item key="growOutRecord">
                <Link to="/growOutRecord">(BETA) Grow Out Record</Link>
              </Menu.Item>
            )}

            {can("PondDailyRecord") && (
              <Menu.Item key="pondDailyRecord">
                <Link to="/pondDailyRecords">Feeding & Treatment</Link>
              </Menu.Item>
            )}

            {can("PondDailyRecord") && (
              <Menu.Item key="feedTreatmentRecords">
                <Link to="/feedTreatmentRecords">(BETA) Feeding & Treatment</Link>
              </Menu.Item>
            )}
            {can("SSCDailyMonitor") && (
              <Menu.Item key="sscDailyMonitor">
                <Link to="/sscDailyMonitor">SSC Daily Monitor</Link>
              </Menu.Item>
            )}
            {can("FarmProduction") && (
              <Menu.Item key="farmProduction">
                <Link to="/farmProductions">Farm Production</Link>
              </Menu.Item>
            )}
            {can("DeliveryOrder") && (
              <Menu.Item key="deliveryOrder">
                <Link to="/deliveryOrders">Delivery Order</Link>
              </Menu.Item>
            )}
          </SubMenu>
        )}

      

        {can("Settings") && (
          <SubMenu key="settings" icon={<SettingOutlined />} title="Settings">
            {can("HatcherySettings") && (
              <>
                <Menu.Item key="rawMaterials">
                  <Link to="/rawMaterials">Raw Material</Link>
                </Menu.Item>

                <Menu.Item key="option">
                  <Link to="/options">Options</Link>
                </Menu.Item>

                <Menu.Item key="tanks">
                  <Link to="/tanks">Larval Tank</Link>
                </Menu.Item>
                <Menu.Item key="farms">
                  <Link to="/farms">Farm</Link>
                </Menu.Item>
              </>
            )}

            {can("FarmSettings") && (
              <>
                <Menu.Item key="ponds">
                  <Link to="/ponds">Pond</Link>
                </Menu.Item>
                <Menu.Item key="hapa">
                  <Link to="/hapas">HAPA</Link>
                </Menu.Item>
                <Menu.Item key="growOut">
                  <Link to="/growOuts">Grow Out</Link>
                </Menu.Item>
                <Menu.Item key="pontoon">
                  <Link to="/pontoons">Pontoon</Link>
                </Menu.Item>
                <Menu.Item key="processing">
                  <Link to="/processings">Processing</Link>
                </Menu.Item>
              </>
            )}

            {can("ProcessingSettings") && (
              <>
                <Menu.Item key="qrCodes">
                  <Link to="/qrCodes">QR Code</Link>
                </Menu.Item>
              </>
            )}
          </SubMenu>
        )} */}

        {/* <SubMenu key="antChain" icon={<BlockOutlined />} title="AntChain">
          <Menu.Item key="antChainProducts">
            <Link to="/antChainProducts">Product</Link>
          </Menu.Item>
          <Menu.Item key="tracePhases">
            <Link to="/tracePhases">Trace Phase</Link>
          </Menu.Item>
          <Menu.Item key="codeRules">
            <Link to="/codeRules">Code Rule</Link>
          </Menu.Item>
        </SubMenu> */}
      </Menu>
    </Sider>
  );
};

export default SideMenu;
