import React, { useEffect, useState } from "react";
import { Form, Modal } from "antd";
import { useTranslation } from "react-i18next";
import ProductForm from "./ProductForm";
import { useProduct } from "../../hooks/useProduct";

const EditProductModal = (props) => {
  const { editItem, visible, setVisible, dispatch } = props;

  const { t } = useTranslation();

  const [form] = Form.useForm();

  const { update } = useProduct();

  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        productName: editItem.productName,
        internalName: editItem.internalName,
        shortName: editItem.shortName,
        weightInGram: editItem.weightInGram,
      });
    }
  }, [visible, form, editItem]);

  const handleOk = (e) => {
    form.validateFields().then((values, err) => {
      if (!err) {
        const params = {
          productName: values.productName,
          internalName: values.internalName,
          shortName: values.shortName,
          weightInGram: values.weightInGram,
        };
        update(editItem.id, params).then((res) => {
          if (res.status === "success") {
            dispatch({ type: "refresh" });
            setVisible(false);
            form.resetFields();
          }
          setSubmitting(false);
        });
      }
    });
  };

  const handleCancel = () => setVisible(false);

  return (
    <Modal
      title={t("product.editTitle")}
      confirmLoading={submitting}
      centered
      visible={visible}
      destroyOnClose
      onCancel={handleCancel}
      onOk={handleOk}
      okText={t("action.submit")}
      cancelText={t("action.cancel")}
    >
      <ProductForm form={form} />
    </Modal>
  );
};

export default EditProductModal;
