import React from 'react';
import { Form, Input, notification, Modal } from 'antd';
import { useTranslation } from "react-i18next";
import { useAuth } from '../../../hooks/useAuth';

const EditPinModal = (props) => {

    const { t } = useTranslation();

    const { updatePin } = useAuth()

    const [form] = Form.useForm();

    const { visible, setVisible, state, dispatch } = props;

    const closeModal = () => setVisible(false);

    const handleSubmit = e => {
        form.validateFields().then(async (values, err) => {
            if (!err) {
                const params = {
                    'pin': values.pin,
                }

                const res = await updatePin(params);

                if (res.status === 'success') {
                    notification.success({
                        message: t("notification.success"),
                        top: 72
                    });

                    dispatch({
                        type: 'UPDATE',
                        payload: {
                            authUser: {
                                ...state.authUser,
                                pin: values.pin
                            }
                        }
                    })
                    closeModal();
                }
                else {
                    notification.error({
                        message: t("notification.fail"),
                        top: 72
                    });
                }
            }
        });
    };

    return (
        <Modal title={t('action.edit') + " " + t('account.setting.pin')}
            centered
            visible={visible}
            destroyOnClose
            maskClosable={true}
            onOk={handleSubmit}
            onCancel={closeModal}
            okText={t("action.submit")}
            cancelText={t("action.cancel")}>

            <Form
                form={form}
                name='edit-security-pin-form'
                layout="vertical">

                <Form.Item
                    name="pin"
                    label={t('account.setting.pin')}
                    rules={[
                        {
                            required: true
                        }, {
                            pattern: "^[0-9]{6,6}$",
                            message: t('message.securityPinPattern')
                        }]}>
                    <Input maxLength={6} />
                </Form.Item>
            </Form>

        </Modal>
    );

};

export default EditPinModal;