/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from "react";

import { enquireScreen } from "enquire-js";

import { Drawer } from "antd";
import Nav0 from "./Nav0";
import Banner5 from "./Banner5";
import Content0 from "./Content0";
import Content1 from "./Content1";
import Feature2 from "./Feature2";
import Pricing1 from "./Pricing1";
import Footer0 from "./Footer0";

import {
  Nav00DataSource,
  Banner50DataSource,
  Content00DataSource,
  Content10DataSource,
  Feature20DataSource,
  Pricing10DataSource,
  Footer00DataSource,
} from "./data.source";
import "./less/antMotionStyle.less";
import SignUpDrawer from "../../components/signUp/SignUpDrawer";
import AcceptInvitationDrawer from "../../components/collaborator/AcceptInvitationDrawer";

import Login from "../../components/login/Login";
let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

const { location = {} } = typeof window !== "undefined" ? window : {};

export default class Home extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isMobile,
      show: !location.port, // 如果不是 dva 2.0 请删除
      signUpDrawer: false,
      acceptInvitationDrawer: false,
      inviteCode: null,
      subscriptionPlan: null,
    };
  }

  componentDidMount() {
    // 适配手机屏幕;
    enquireScreen((b) => {
      this.setState({ isMobile: !!b });
    });
    // dva 2.0 样式在组件渲染之后动态加载，导致滚动组件不生效；线上不影响；
    /* 如果不是 dva 2.0 请删除 start */
    if (location.port) {
      // 样式 build 时间在 200-300ms 之间;
      setTimeout(() => {
        this.setState({
          show: true,
        });
      }, 500);
    }
    /* 如果不是 dva 2.0 请删除 end */

    if (location.search) {
      const query = new URLSearchParams(location.search);
      const inviteCode = query.get("invitation");
      setTimeout(() => {
        this.setState({
          inviteCode: inviteCode,
        });
      }, 1000);
    }
  }

  componentWillMount() {
    const link = document.createElement("link");
    link.href = "https://assets.calendly.com/assets/external/widget.css";
    link.rel = "stylesheet";
    document.body.appendChild(link);

    const jsScript = document.createElement("script");
    jsScript.src = "https://assets.calendly.com/assets/external/widget.js";
    jsScript.type = "text/javascript";
    jsScript.async = true;
    // jsScript.addEventListener("load", () => {
    //   Calendly.initBadgeWidget({
    //     url: "https://calendly.com/sinisana/experiment1et?hide_gdpr_banner=1",
    //     text: "Schedule a demo",
    //     color: "#006bff",
    //     textColor: "#ffffff",
    //     branding: false,
    //   });
    // });
    document.body.appendChild(jsScript);
  }

  showSignUp = (plan) => {
    // Calendly.destroyBadgeWidget();
    this.setState({ signUpDrawer: true, subscriptionPlan: plan });
  };
  showLogin = (plan) => {
    // Calendly.destroyBadgeWidget();
    this.setState({ loginDrawer: true });
  };
  showDemoButton = () => {
    Calendly.initBadgeWidget({
      url: "https://calendly.com/sinisana/experiment1et?hide_gdpr_banner=1",
      text: "Schedule a demo",
      color: "#006bff",
      textColor: "#ffffff",
      branding: false,
    });
  };

  render() {
    let navDataSource = { ...Nav00DataSource };
    navDataSource.Menu.children[2].onClick = () => this.showSignUp();
    navDataSource.Menu.children[4].onClick = () => this.showLogin();

    let bannerDataSource = { ...Banner50DataSource };
    bannerDataSource.childWrapper.children[2].children.onClick = () => this.showSignUp();

    let pricingDataSource = { ...Pricing10DataSource };
    pricingDataSource.block.children[0].children.buttonWrapper.children.a.onClick = () => this.showSignUp("trade-5");
    pricingDataSource.block.children[1].children.buttonWrapper.children.a.onClick = () => this.showSignUp("trade-10");
    pricingDataSource.block.children[2].children.buttonWrapper.children.a.onClick = () => this.showSignUp("trade-20");

    const children = [
      <Nav0 id="Nav0_0" key="Nav0_0" dataSource={navDataSource} isMobile={this.state.isMobile} />,
      <Banner5 id="Banner5_0" key="Banner5_0" dataSource={bannerDataSource} isMobile={this.state.isMobile} />,
      <Content0 id="Content0_0" key="Content0_0" dataSource={Content00DataSource} isMobile={this.state.isMobile} />,
      <Content1 id="Content1_0" key="Content1_0" dataSource={Content10DataSource} isMobile={this.state.isMobile} />,
      <Feature2 id="Feature2_0" key="Feature2_0" dataSource={Feature20DataSource} isMobile={this.state.isMobile} />,
      <Pricing1 id="Pricing1_0" key="Pricing1_0" dataSource={pricingDataSource} isMobile={this.state.isMobile} />,
      <Footer0 id="Footer0_0" key="Footer0_0" dataSource={Footer00DataSource} isMobile={this.state.isMobile} />,
    ];
    return (
      <div
        className="templates-wrapper"
        ref={(d) => {
          this.dom = d;
        }}
      >
        {/* 如果不是 dva 2.0 替换成 {children} start */}
        {this.state.show && children}
        {/* 如果不是 dva 2.0 替换成 {children} end */}

        <SignUpDrawer
          defaultPlanId={this.state.subscriptionPlan}
          visible={this.state.signUpDrawer}
          setVisible={(visible) => this.setState({ signUpDrawer: visible })}
          onCloseDrawer={this.showDemoButton}
          isMobile={isMobile}
        />

        <AcceptInvitationDrawer
          inviteCode={this.state.inviteCode}
          visible={this.state.acceptInvitationDrawer}
          setVisible={(visible) => this.setState({ acceptInvitationDrawer: visible })}
          isMobile={isMobile}
        />

        <Drawer
          title="Login"
          visible={this.state.loginDrawer}
          destroyOnClose={true}
          onClose={() => {
            this.setState({ loginDrawer: false });
            this.showDemoButton();
          }}
          width={isMobile ? "100%" : "50%"}
        >
          <Login />
        </Drawer>
      </div>
    );
  }
}
