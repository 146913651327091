import React, { useState, useEffect } from "react";
import { Form, Drawer, Row, Col, Spin, Alert, Space, Button } from "antd";
import { useAuth } from "../../hooks/useAuth";
import { useAccount } from "../../hooks/useAccount";
import AcceptInvitationForm from "./AcceptInvitationForm";
import { useInvitation } from "../../hooks/useInvitation";

const AcceptInvitationDrawer = (props) => {
  const { inviteCode, visible, setVisible, isMobile } = props;

  const { register } = useAuth();
  const { create } = useAccount();
  const { findPendingInvite, acceptInvitation } = useInvitation();
  const [form] = Form.useForm();

  const [inviteData, setInviteData] = useState();
  const [currentStep, setCurrentStep] = useState(0);
  const [submitting, setSubmitting] = useState(false);

  const [error, setError] = useState();

  useEffect(() => {
    if (inviteCode) {
      findPendingInvite(inviteCode).then((res) => {
        if (res.status === "success" && res.data) {
          setInviteData(res.data);
          setVisible(true);
        }
      });
    }
  }, [inviteCode]);

  useEffect(() => {
    if (inviteData) {
      form.setFieldsValue({
        email: inviteData.inviteeEmail,
      });
    }
  }, [inviteData]);

  const handleSubmit = () => {
    form.validateFields().then(async (values, err) => {
      if (!err) {
        setSubmitting(true);

        register({
          name: values.name,
          email: values.email,
          password: "P@ssword1",
        }).then((res) => {
          if (res.data.user) {
            // User successfully created. Proceed to register account
            create({
              id: res.data.user.id,
              name: res.data.user.name,
              email: res.data.user.email,
              businessName: values.businessName,
              businessRegNo: values.businessRegNo,
              businessCountry: values.businessCountry,
              businessAddress: values.businessAddress,
              businessCity: values.businessCity,
              businessRegion: values.businessRegion,
              businessPostalCode: values.businessPostalCode,
              businessContactNo: values.businessContactNo,
            }).then((res) => {
              if (res.status === "success") {
                acceptInvitation(inviteData.id, {
                  status: "Accept",
                  identifier: inviteData.identifier,
                  inviteeEmail: values.email,
                });
                next();
              } else {
                setError(
                  "Unfortunately, there is an error creating your account. Please contact our technical support with your registration details and payment info."
                );
              }
            });
          }
          setSubmitting(false);
        });
      }
    });
  };

  const next = () => {
    let fields = null;

    if (currentStep === 0) {
      fields = ["name", "email"];
    } else if (currentStep === 1) {
      fields = [
        "businessName",
        "businessRegNo",
        "businessCountry",
        "businessAddress",
        "businessCity",
        "businessRegion",
        "businessPostalCode",
        "businessContactNo",
      ];
    }

    form.validateFields(fields).then(async (values, err) => {
      if (!err) {
        setCurrentStep((currentStep) => currentStep + 1);
      }
    });
  };

  const previous = () => {
    setCurrentStep((currentStep) => currentStep - 1);
  };

  return (
    <Drawer
      title="Accept Invitation"
      visible={visible}
      destroyOnClose={true}
      onClose={() => setVisible(false)}
      width={isMobile ? "100%" : "50%"}
      footer={
        <div style={{ textAlign: "left" }}>
          <Space>
            {currentStep > 0 && currentStep < 1 && (
              <Button onClick={previous} disabled={submitting}>
                Previous
              </Button>
            )}

            {currentStep < 1 && (
              <Button type="primary" onClick={next}>
                Next
              </Button>
            )}

            {currentStep === 1 && (
              <Button type="primary" onClick={handleSubmit} disabled={submitting}>
                Submit
              </Button>
            )}
          </Space>
        </div>
      }
    >
      <div style={{ paddingTop: 16 }}>
        <AcceptInvitationForm form={form} currentStep={currentStep} />

        {submitting && (
          <Row justify="center" style={{ marginBottom: 24 }}>
            <Col span={24} style={{ textAlign: "center" }}>
              <Spin size="small" />
            </Col>
          </Row>
        )}

        {error && error !== "" ? (
          <Row justify="center" style={{ marginBottom: 24 }}>
            <Col span={24} style={{ textAlign: "center" }}>
              <Alert message={error} type="error" />
            </Col>
          </Row>
        ) : null}
      </div>
    </Drawer>
  );
};

export default AcceptInvitationDrawer;
