import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Drawer, Tabs, Row, Col, Button } from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import ProductUomTable from "../productUom/ProductUomTable";
import { useProductUom } from "../../hooks/useProductUom";
import ProductInfo from "./ProductInfo";
import NewProductUomModal from "../productUom/NewProductUomModal";
import EditProductUomModal from "../productUom/EditProductUomModal";

const { TabPane } = Tabs;

const ProductDetailDrawer = (props) => {
  const { t } = useTranslation();

  const { editItem, state, visible, setVisible, dispatch } = props;

  const { destroy } = useProductUom();

  const [addUomModal, setAddUomModal] = useState(false);
  const [editUomModal, setEditUomModal] = useState(false);

  const [editUom, setEditUom] = useState({});

  useEffect(() => {
    if (visible && editUom.id) {
      const index = editItem.ProductUoms.map((item) => item.id).indexOf(editUom.id);

      if (index > -1) {
        setEditUom(editItem.ProductUoms[index]);
      }
    }
  }, [visible, editItem, editUom]);

  const onClose = () => {
    setVisible(false);
  };

  const editRow = (item) => {
    setEditUom(item);
    setEditUomModal(true);
  };

  const deleteRow = (item) => {
    destroy(item.id).then((res) => {
      if (res.status === "success") {
        dispatch({ type: "refresh" });
      }
    });
  };

  return (
    <Drawer
      // title={t("product.detailTitle")}
      placement="right"
      onClose={onClose}
      visible={visible}
      width={"75%"}
    >
      <ProductInfo item={editItem} />

      <Tabs defaultActiveKey="1">
        <TabPane tab={t("product.productUom")} key="productUom">
          <Row justify="space-between">
            <Col>
              <Button disabled={state.loading} type="primary" onClick={() => setAddUomModal(true)}>
                {t("action.add")}
              </Button>
            </Col>
            <Col>
              <Button
                icon={<ReloadOutlined />}
                disabled={state.loading}
                onClick={() => dispatch({ type: "refresh" })}
              />
            </Col>
          </Row>

          <ProductUomTable
            dataSource={editItem.ProductUoms}
            loading={state.loading}
            error={state.error}
            editRow={editRow}
            deleteRow={deleteRow}
          />
        </TabPane>
      </Tabs>

      <NewProductUomModal item={editItem} visible={addUomModal} setVisible={setAddUomModal} dispatch={dispatch} />

      <EditProductUomModal editItem={editUom} visible={editUomModal} setVisible={setEditUomModal} dispatch={dispatch} />
    </Drawer>
  );
};

export default ProductDetailDrawer;
