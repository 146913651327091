import React, { useState, useContext } from "react";
import { Button, Typography, Menu, Dropdown } from "antd";
import { useTranslation } from "react-i18next";
import { EditOutlined, UploadOutlined, PlusOutlined, EllipsisOutlined } from "@ant-design/icons";
import TableLoader from "../common/TableLoader";
import moment from "moment";
import { AccountContext } from "../layouts/BaseLayout";
import ShipmentOrderTable from "./ShipmentOrderTable";
import CompanyDetailDrawer from "../company/CompanyDetailDrawer";

const { Link } = Typography;

const BlanketOrderTable = (props) => {
  const { account } = useContext(AccountContext);

  const { t } = useTranslation();

  const {
    type,
    dataSource,
    loading,
    error,
    upload,
    viewDetail,
    editRow,
    createShipmentOrder,
    viewShipmentDetail,
    editShipmentRow,
    dispatch,
  } = props;

  const [companyId, setCompanyId] = useState();
  const [companyDetailDrawer, setCompanyDetailDrawer] = useState(false);

  const columns = [
    {
      title: t("common.orderNo"),
      dataIndex: "orderNo",
      render: (text, row) => <Link onClick={() => viewDetail(row)}>{text}</Link>,
    },
    {
      title: type === "in" ? t("common.company") : t("common.supplier"),
      render: (text, row) =>
        type === "in" ? (
          <Link onClick={() => viewCompanyDetail(row.Requestor.id)}>{row.Requestor.name}</Link>
        ) : (
          <Link onClick={() => viewCompanyDetail(row.Supplier.id)}>{row.Supplier.name}</Link>
        ),
    },
    {
      title: t("common.date"),
      dataIndex: "orderDate",
      render: (text) => (text ? moment(text).format("YYYY-MM-DD") : "-"),
    },
    // {
    //   title: t("blanketOrder.shipTo"),
    //   dataIndex: "shipTo",
    //   render: (text) => (text ? text : "-"),
    // },
    {
      title: t("blanketOrder.effectiveDate"),
      align: "center",
      render: (text, row) =>
        row.effectiveUntilCancel ? (
          t("blanketOrder.effectiveUntilCancel")
        ) : (
          <>
            {moment(row.effectiveStartDt).format("YYYY-MM-DD")} ~ {moment(row.effectiveEndDt).format("YYYY-MM-DD")}
          </>
        ),
    },
    // {
    //   title: t("common.supplier"),
    //   render: (text, row) => row.Supplier.name,
    // },
    {
      title: t("common.amount"),
      dataIndex: "totalAmount",
      align: "right",
      render: (text) =>
        Number(text).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
    },
    {
      title: t("common.status"),
      dataIndex: "status",
    },
    {
      title: t("action.title"),
      align: "center",
      key: "action",
      render: (text, row) => (
        <Dropdown
          overlay={
            <Menu>
              {row.companyId === account.Companies[0].id && (
                <Menu.Item key="edit" icon={<EditOutlined />} onClick={() => editRow(row)}>
                  {t("action.edit")}
                </Menu.Item>
              )}
              <Menu.Item key="upload" icon={<UploadOutlined />} onClick={() => upload(row)}>
                {t("action.upload")}
              </Menu.Item>
              {type === "in" && (
                <Menu.Item key="shipment" icon={<PlusOutlined />} onClick={() => createShipmentOrder(row)}>
                  Shipment Order
                </Menu.Item>
              )}
            </Menu>
          }
        >
          <Button type="link" icon={<EllipsisOutlined />} />
        </Dropdown>
      ),
    },
  ];

  const expandedRowRender = (item) => {
    return (
      <ShipmentOrderTable
        error={error}
        loading={loading}
        dataSource={item.ShipmentOrders}
        view={viewShipmentDetail}
        dispatch={dispatch}
        editRow={editShipmentRow}
      />
    );
  };

  const viewCompanyDetail = (id) => {
    setCompanyId(id);
    setCompanyDetailDrawer(true);
  };

  return (
    <>
      <TableLoader
        error={error}
        loading={loading}
        dataSource={dataSource}
        columns={columns}
        style={{ marginTop: 24 }}
        expandable={{ expandedRowRender, rowExpandable: (row) => row.ShipmentOrders.length }}
      />

      <CompanyDetailDrawer id={companyId} visible={companyDetailDrawer} setVisible={setCompanyDetailDrawer} />
    </>
  );
};

export default BlanketOrderTable;
