import React from "react";
import { Row, Col, Typography } from "antd";
import { CheckCircleFilled } from "@ant-design/icons";
import { green } from "@ant-design/colors";

const { Title, Text, Link } = Typography;

const UpgradeFormComplete = () => {
  return (
    <Row justify="center">
      <Col span={18} style={{ textAlign: "center" }}>
        <CheckCircleFilled style={{ color: green.primary, fontSize: 48, marginBottom: 16, marginTop: 16 }} />

        <Title level={2}>Success</Title>

        <Text>Please refresh the page if subscription status is not reflected.</Text>

        {/* <p style={{ marginTop: 24 }}>
          <Link href="/">Back to Home</Link>
        </p> */}
      </Col>
    </Row>
  );
};

export default UpgradeFormComplete;
