import React from "react";
import TweenOne from "rc-tween-one";
import { Menu, Drawer , Button} from "antd";
import { getChildrenToRender } from "./utils";
import Login from "../../components/login/Login";
import { MenuFoldOutlined } from "@ant-design/icons";
const { Item, SubMenu } = Menu;

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      phoneOpen: undefined,
      loginDrawer: false,
    };
  }

  phoneClick = () => {
    const phoneOpen = !this.state.phoneOpen;
    this.setState({
      phoneOpen,
    });
  };

  navbarItemClick = (item) => {
    if (item.name === "login") {
      this.setState({
        loginDrawer: !this.state.loginDrawer,
      });
    }
  };

  render() {
    const { dataSource, isMobile, ...props } = this.props;
    const { phoneOpen } = this.state;
    const navData = dataSource.Menu.children;
    const navChildren = navData.map((item) => {
      const { children: a, subItem, ...itemProps } = item;
      if (subItem) {
        return (
          <SubMenu
            key={item.name}
            {...itemProps}
            title={
              <div {...a} className={`header0-item-block ${a.className}`.trim()}>
                {a.children.map(getChildrenToRender)}
              </div>
            }
            popupClassName="header0-item-child"
          >
            {subItem.map(($item, ii) => {
              const { children: childItem } = $item;
              const child = childItem.href ? (
                <a {...childItem}>{childItem.children.map(getChildrenToRender)}</a>
              ) : (
                <div {...childItem}>{childItem.children.map(getChildrenToRender)}</div>
              );
              return (
                <Item key={$item.name || ii.toString()} {...$item}>
                  {child}
                </Item>
              );
            })}
          </SubMenu>
        );
      }
      return (
        <Item key={item.name} {...itemProps}>
          <a {...a} className={`header0-item-block ${a.className}`.trim()} onClick={() => this.navbarItemClick(item)}>
            {a.children.map(getChildrenToRender)}
          </a>
        </Item>
      );
    });
    const moment = phoneOpen === undefined ? 300 : null;
    return (
      <>
        <TweenOne component="header" animation={{ opacity: 0, type: "from" }} {...dataSource.wrapper} {...props}>
          <div {...dataSource.page} className={`${dataSource.page.className}${phoneOpen ? " open" : ""}`}>
            <TweenOne animation={{ x: -30, type: "from", ease: "easeOutQuad" }} {...dataSource.logo}>

            {isMobile ? 
              <img style={{maxWidth:"50%",height:"auto"}} src={dataSource.logo.children} alt="img" />
            : 
              <img style={{maxWidth:"100%",height:"auto"}} src={dataSource.logo.children} alt="img" />
            }
            </TweenOne>
            {isMobile && (
              //<Button icon={<MenuFoldOutlined/>} {...dataSource.mobileMenu} onClick={() => {
              //  this.phoneClick();
              //}}/>
              <MenuFoldOutlined {...dataSource.mobileMenu}
                onClick={() => {
                  this.phoneClick();
                }}
              />
            )}
            <TweenOne
              {...dataSource.Menu}
              animation={
                isMobile
                  ? {
                      height: 0,
                      duration: 300,
                      onComplete: (e) => {
                        if (this.state.phoneOpen) {
                          e.target.style.height = "auto";
                        }
                      },
                      ease: "easeInOutQuad",
                    }
                  : null
              }
              moment={moment}
              reverse={!!phoneOpen}
            >
              <Menu mode={isMobile ? "inline" : "horizontal"} defaultSelectedKeys={["sub0"]}>
                {navChildren}
              </Menu>
            </TweenOne>
          </div>
        </TweenOne>

      </>
    );
  }
}

export default Header;
