import React, { useState, useContext } from "react";
import { Button, Space, Tag, Typography, Menu, Dropdown } from "antd";
import { useTranslation } from "react-i18next";
import { EditOutlined, UploadOutlined, PlusOutlined, EllipsisOutlined } from "@ant-design/icons";
import TableLoader from "../common/TableLoader";
import AddDocumentModal from "../document_upload/modals/AddDocumentModal";
import moment from "moment";
import { AccountContext } from "../layouts/BaseLayout";

const { Link, Title } = Typography;

const ShipmentOrderTable = (props) => {
  const { account } = useContext(AccountContext);

  const { t } = useTranslation();

  const { dataSource, loading, error, view, editRow } = props;

  const [addDocumentModal, setAddDocumentModal] = useState(false);
  const [editItem, setEditItem] = useState();

  const columns = [
    {
      title: t("common.orderNo"),
      dataIndex: "orderNo",
      render: (text, row) => <Link onClick={() => view(row)}>{text}</Link>,
    },
    {
      title: t("common.date"),
      dataIndex: "orderDate",
      render: (text) => (text ? moment(text).format("YYYY-MM-DD") : "-"),
    },
    {
      title: t("blanketOrder.shipTo"),
      dataIndex: "shipTo",
      render: (text) => (text ? text : "-"),
    },
    {
      title: t("shipmentOrder.expectedShipDate"),
      dataIndex: "expectedShipDate",
      render: (text) => <Tag>{moment(text).format("YYYY-MM-DD")}</Tag>,
    },
    {
      title: t("common.amount"),
      dataIndex: "totalAmount",
      align: "right",
      render: (text) =>
        Number(text).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
    },
    {
      title: t("common.status"),
      dataIndex: "status",
    },
    {
      title: t("action.title"),
      align: "center",
      key: "action",
      render: (text, row) => (
        <Dropdown
          overlay={
            <Menu>
              {row.companyId === account.Companies[0].id && (
                <Menu.Item key="edit" icon={<EditOutlined />} onClick={() => editRow(row)}>
                  {t("action.edit")}
                </Menu.Item>
              )}
              <Menu.Item key="upload" icon={<UploadOutlined />} onClick={() => upload(row)}>
                {t("action.upload")}
              </Menu.Item>
            </Menu>
          }
        >
          <Button type="link" icon={<EllipsisOutlined />} />
        </Dropdown>
      ),
    },
  ];

  const upload = (record) => {
    setEditItem(record);
    setAddDocumentModal(true);
  };

  return (
    <>
      <TableLoader
        title={() => <Title level={5}>{t("shipmentOrder.title")}</Title>}
        error={error}
        loading={loading}
        dataSource={dataSource}
        columns={columns}
        style={{ marginTop: 16 }}
        size="small"
      />

      <AddDocumentModal
        item={editItem}
        docType="ShipmentOrder"
        visible={addDocumentModal}
        setVisible={setAddDocumentModal}
      />
    </>
  );
};

export default ShipmentOrderTable;
