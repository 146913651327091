import React, { useEffect, useState } from "react";
import { Form, InputNumber, Modal, Input, Select } from "antd";
import { useTranslation } from "react-i18next";
import { useBlanketOrderDetail } from "../../hooks/useBlanketOrderDetail";
import { useShipmentOrderDetail } from "../../hooks/useShipmentOrderDetail";

const { Option } = Select;

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

const AddOrderItemModal = (props) => {
  const { order, visible, setVisible, dispatch } = props;

  const { t } = useTranslation();

  const [form] = Form.useForm();

  const { findAll } = useBlanketOrderDetail();

  const { create } = useShipmentOrderDetail();

  const [submitting, setSubmitting] = useState(false);
  const [productOptions, setProductOptions] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState();

  useEffect(() => {
    if (visible) {
      findAll({ blanketOrderId: order.blanketOrderId }).then((res) => {
        if (res.status === "success") {
          setProductOptions(res.data);
        }
      });
    }
  }, [visible, order, findAll]);

  const handleOk = (e) => {
    form.validateFields().then((values, err) => {
      if (!err) {
        const params = {
          shipmentOrderId: order.id,
          blanketOrderDetailId: selectedProduct.id,
          productId: values.productId,
          quantityInUom: values.quantityInUom,
          uomPrice: selectedProduct.uomPrice,
          uomId: selectedProduct.uomId,
          remarks: values.remarks,
        };
        setSubmitting(true);
        create(params).then((res) => {
          if (res.status === "success") {
            dispatch({ type: "refresh" });
            setSelectedProduct();
            setVisible(false);
            form.resetFields();
          }
          setSubmitting(false);
        });
      }
    });
  };

  const handleCancel = () => setVisible(false);

  const onChange = (value) => {
    const index = productOptions.map((item) => item.productId).indexOf(value);

    if (index > -1) {
      setSelectedProduct(productOptions[index]);
      form.setFieldsValue({
        remarks: productOptions[index].remarks,
      });
    } else {
      setSelectedProduct();
    }
  };

  return (
    <Modal
      title={t("product.addNewTitle")}
      confirmLoading={submitting}
      centered
      visible={visible}
      destroyOnClose
      onCancel={handleCancel}
      onOk={handleOk}
      okText={t("action.submit")}
      okButtonProps={{ disabled: !selectedProduct }}
      cancelText={t("action.cancel")}
    >
      <Form form={form} {...layout} name="order-item-form">
        <Form.Item
          name="productId"
          label={t("common.product")}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            showSearch
            style={{ width: "100%" }}
            placeholder="Search to Select"
            optionFilterProp="children"
            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            filterSort={(optionA, optionB) =>
              optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
            }
            onChange={onChange}
          >
            {productOptions.map((item) => (
              <Option key={item.id} value={item.productId}>
                {item.Product.productName}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="quantityInUom"
          label={t("common.quantity")}
          rules={[
            {
              required: true,
            },
          ]}
          extra={
            <sub>
              {selectedProduct?.balanceInUom} {selectedProduct?.Uom.shortCode} {t("common.remain")}
            </sub>
          }
        >
          <InputNumber min={0} max={selectedProduct ? selectedProduct.quantityInUom : null} allowClear />
        </Form.Item>

        <Form.Item label={t("common.price")}>{selectedProduct?.uomPrice}</Form.Item>

        <Form.Item label={t("common.uom")}>
          {selectedProduct && selectedProduct.Uom ? selectedProduct.Uom.shortCode : "-"}
        </Form.Item>

        <Form.Item name="remarks" label={t("common.remarks")}>
          <Input.TextArea rows={5} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddOrderItemModal;
