import React, { useState, useEffect } from "react";
import { Typography, Form, notification } from "antd";
import { useTranslation } from "react-i18next";
import CompanyInfoForm from "../forms/CompanyInfoForm";
import { useAccount } from "../../../hooks/useAccount";

const { Title } = Typography;

const CompanyInfo = (props) => {
  const { account, setAccount } = props;

  const { t } = useTranslation();

  const { update } = useAccount();

  const [form] = Form.useForm();

  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (account.id && account.Companies.length) {
      const company = account.Companies[0];

      form.setFieldsValue({
        name: company.name,
        regNo: company.regNo,
        contactNo: company.contactNo,
        address: company.address,
        city: company.city,
        region: company.region,
        postalCode: company.postalCode,
        country: company.country,
      });
    }
  }, [account, form]);

  const handleSubmit = () => {
    form.validateFields().then(async (values, err) => {
      if (!err) {
        setSubmitting(true);

        const params = {
          companyId: account.Companies[0].id,
          companyName: values.name,
          regNo: values.regNo,
          contactNo: values.contactNo,
          address: values.address,
          city: values.city,
          region: values.region,
          postalCode: values.postalCode,
          country: values.country,
        };

        const res = await update(account.id, params);

        if (res.status === "success") {
          notification.success({
            message: t("notification.success"),
            top: 72,
          });

          setAccount(res.data);
        } else {
          notification.error({
            message: t("notification.fail"),
            top: 72,
          });
        }
        setSubmitting(false);
      }
    });
  };

  return (
    <>
      <Title level={4}>{t("account.setting.company")}</Title>

      <CompanyInfoForm form={form} submitting={submitting} handleSubmit={handleSubmit} />
    </>
  );
};

export default CompanyInfo;
