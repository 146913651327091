import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Typography } from "antd";
import TableLoader from "../common/TableLoader";

const { Link } = Typography;

const ProductTable = (props) => {
  const { t } = useTranslation();

  const { dataSource, loading, error, editRow, viewDetail } = props;

  const columns = [
    {
      title: t("common.id"),
      dataIndex: "id",
    },
    {
      title: t("product.productName"),
      dataIndex: "productName",
      render: (text, row) => <Link onClick={() => viewDetail(row)}>{text}</Link>,
    },
    {
      title: t("product.shortName"),
      dataIndex: "shortName",
      render: (text) => (text ? text : "-"),
    },
    {
      title: t("product.internalName"),
      dataIndex: "internalName",
      render: (text) => (text ? text : "-"),
    },
    {
      title: t("common.weightInGram"),
      dataIndex: "weightInGram",
      render: (text) => (text ? text : "-"),
    },
    {
      title: t("common.action"),
      key: "action",
      render: (text, row) => (
        <Button type="link" onClick={() => editRow(row)}>
          {t("action.edit")}
        </Button>
      ),
    },
  ];

  return (
    <TableLoader error={error} loading={loading} dataSource={dataSource} columns={columns} style={{ marginTop: 24 }} />
  );
};

export default ProductTable;
