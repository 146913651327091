import React, { useState, useEffect } from "react";
import { Card, Select, Form, Row, Col, List } from "antd";
import { BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Bar, ResponsiveContainer } from "recharts";
import moment from "moment";
import { useDashboard } from "../../hooks/useDashboard";
import { useContext } from "react";
import { AccountContext } from "../../components/layouts/BaseLayout";

const { Option } = Select;

const initialData = [
  {
    month: 1,
    name: "Jan",
    totalAmount: null,
  },
  {
    month: 2,
    name: "Feb",
    totalAmount: null,
  },
  {
    month: 3,
    name: "Mar",
    totalAmount: null,
  },
  {
    month: 4,
    name: "Apr",
    totalAmount: null,
  },
  {
    month: 5,
    name: "May",
    totalAmount: null,
  },
  {
    month: 6,
    name: "Jun",
    totalAmount: null,
  },
  {
    month: 7,
    name: "Jul",
    totalAmount: null,
  },
  {
    month: 8,
    name: "Aug",
    totalAmount: null,
  },
  {
    month: 9,
    name: "Sep",
    totalAmount: null,
  },
  {
    month: 10,
    name: "Oct",
    totalAmount: null,
  },
  {
    month: 11,
    name: "Nov",
    totalAmount: null,
  },
  {
    month: 12,
    name: "Dec",
    totalAmount: null,
  },
];

const Dashboard = (props) => {
  const [filterYear, setFilterYear] = useState(moment().format("YYYY"));

  const { account } = useContext(AccountContext);

  const { findTotalSales, findInventory } = useDashboard();

  const [refresh, setRefresh] = useState(false);
  const [data, setData] = useState(initialData);
  const [inventoryData, setInventoryData] = useState([]);

  useEffect(() => {
    if (refresh && account["Companies"]) {
      findTotalSales({ year: filterYear, companyId: account["Companies"][0]["id"] }).then((res) => {
        if (res.status === "success") {
          let array = [...initialData];
          for (const item of res.data) {
            const index = initialData.map((item) => item.month).indexOf(item.month);

            if (index > -1) {
              array[index] = {
                month: item.month,
                name: array[index].name,
                totalAmount: item.totalAmount,
              };
            }
          }
          setData(array);
        }
        setRefresh(false);
      });

      findInventory({
        companyId: account["Companies"][0]["id"],
      }).then((res) => {
        if (res.status === "success") {
          setInventoryData(res.data);
        }
      });
    }
  }, [refresh, filterYear, findTotalSales, account]);

  useEffect(() => {
    if (filterYear) {
      setRefresh(true);
    }
  }, [filterYear]);

  const onFilterYearChanged = (value) => setFilterYear(value);

  return (
    <>
      <Row gutter={[16, 16]} style={{ marginTop: 16 }}>
        <Col span={24}>
          <Card title="Inventory">
            <Row>
              {inventoryData.map((item) => (
                <Col key={item.productName} span={8}>
                  <Card title={item.productName} bordered={false}>
                    <h1>{isNaN(item.balance) ? 0 : Number(item.balance) <= 0 ? 0 : item.balance}</h1>
                  </Card>
                </Col>
              ))}
            </Row>
          </Card>
        </Col>
        {/* <Col span={16}>
          <Card title="[Placeholder for Action Required]">
            <List dataSource={["Message 1", "Message 2", "Message 3"]} />
          </Card>
        </Col> */}
        {/* <Col span={8}>
          <Card title="[Placeholder for Unread Messages]">
            <List
              dataSource={["Message 1", "Message 2", "Message 3"]}
              renderItem={(item, index) => (
                <List.Item
                  style={{ background: "#fafafa", paddingLeft: 8, paddingRight: 8, marginBottom: 8 }}
                  key={item.id}
                >
                  <List.Item.Meta
                    title={index + 1 + ". " + item.name}
                    description={item.description !== "undefined" ? item.description : null}
                  />
                   <Row>
                  <Col>
                    <h4>{moment(item.createdAt).format("lll")}</h4>
                    <ul>
                      {item.assets.fileList.map((item) => (
                        <li key={item.fileName}>
                          <Link onClick={() => viewDoc(item)}>{item.fileName}</Link>
                        </li>
                      ))}
                    </ul>
                  </Col>
                </Row> 
                </List.Item>
              )}
            />
          </Card>
        </Col> */}
      </Row>
      <div className="site-layout-background" style={{ padding: 24, marginTop: 16 }}>
        <Card
          title="Total Sales"
          extra={
            <Form.Item label="Year" style={{ marginBottom: 0 }}>
              <Select value={filterYear} onChange={onFilterYearChanged}>
                <Option value="2020">2020</Option>
                <Option value="2021">2021</Option>
              </Select>
            </Form.Item>
          }
          size="small"
        >
          <ResponsiveContainer width="100%" height={350}>
            <BarChart width={"100%"} data={data}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis
                type="number"
                label={{ value: "Total $", angle: -90, position: "insideLeft" }}
                tickFormatter={(tick) => {
                  return tick.toLocaleString();
                }}
              />
              <Tooltip />
              <Legend />
              <Bar dataKey="totalAmount" name={filterYear} fill="#82ca9d" />
            </BarChart>
          </ResponsiveContainer>
        </Card>
      </div>
    </>
  );
};

export default Dashboard;
