import React, { useState, useEffect } from "react";
import { DatePicker, Form, Modal, Row, Col } from "antd";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useShipmentOrder } from "../../hooks/useShipmentOrder";

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

const ActualShipDateModal = (props) => {
  const { order, visible, setVisible, dispatch } = props;

  const { t } = useTranslation();

  const [form] = Form.useForm();

  const { update } = useShipmentOrder();

  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (visible && order) {
      form.setFieldsValue({
        actualShipDate: moment(),
      });
    }
  }, [visible, order]);

  const handleOk = (e) => {
    form.validateFields().then((values, err) => {
      if (!err) {
        const params = {
          actualShipDate: moment(values.actualShipDate).format(),
          status: "Shipped",
        };

        setSubmitting(true);

        update(order.id, params).then((res) => {
          if (res.status === "success") {
            dispatch({ type: "refresh" });
            form.resetFields();
            setVisible(false);
          }
          setSubmitting(false);
        });
      }
    });
  };

  const handleCancel = () => setVisible(false);

  return (
    <Modal
      title={t("shipmentOrder.actualShipDate")}
      confirmLoading={submitting}
      centered
      visible={visible}
      destroyOnClose
      onCancel={handleCancel}
      onOk={handleOk}
      okText={t("action.submit")}
      cancelText={t("action.cancel")}
      width={"80%"}
    >
      <Form form={form} {...layout} name="shipment-order-form">
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="actualShipDate"
              label={t("shipmentOrder.actualShipDate")}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default ActualShipDateModal;
