import { useCallback, useContext } from 'react';
import { AuthContext } from '../App';
import { useAuth } from '../hooks/useAuth';

const useFetch = () => {

    const { dispatch } = useContext(AuthContext);

    const { auth } = useAuth();

    const send = useCallback(async (url, config) => {

        const res = await fetch(url, config)
            .then(res => res.json())
            .catch(err => err);


        if (res.status === 'fail') 
        {
            const response = await auth();

            if (response.status === 'success') 
            {
                dispatch({
                    type: "LOGIN",
                    payload: {
                        authUser: response.data.auth,
                        token: response.data.token
                    }
                });

                config.headers['Authorization'] = 'Bearer ' + response.data.token;

                return await fetch(url, config)
                    .then(res => res.json())
                    .catch(err => err);
            }
            else {
                dispatch({
                    type: "LOGOUT",
                })

                return response;
            }
        }
        else {
            return res;
        }
    }, [auth, dispatch])

    return { send }
}

export { useFetch };