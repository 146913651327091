import React, { useEffect, useState } from "react";
import { Form, Modal } from "antd";
import { useTranslation } from "react-i18next";
import { useUom } from '../../../hooks/useUom';
import UomForm from "../forms/UomForm";

const EditModal = props => {

	const { editItem, visible, setVisible, dispatch } = props;

	const { t } = useTranslation();

	const [form] = Form.useForm()

	const { update } = useUom();

	const [submitting, setSubmitting] = useState(false);

	useEffect(() => {
		if (visible) {
			form.setFieldsValue({
				name: editItem.name,
				shortCode: editItem.shortCode,
				description: editItem.description
			})
		}

	}, [visible, form, editItem])

	const handleOk = e => {
		form.validateFields().then((values, err) => {
			if (!err) {
				const params = {
					name: values.name.toUpperCase(),
					shortCode: values.shortCode.toUpperCase(),
					description: values.description,
				}
				update(editItem.id, params).then((res) => {
					if (res.status === "success") {
						dispatch({ type: 'refresh' })
						setVisible(false);
						form.resetFields();
					}
					setSubmitting(false);
				});
			}
		});
	};

	const handleCancel = () => setVisible(false)

	return (
		<Modal
			title={t("uom.editTitle")}
			confirmLoading={submitting}
			centered
			visible={visible}
			destroyOnClose
			onCancel={handleCancel}
			onOk={handleOk}
			okText={t("action.submit")}
			cancelText={t('action.cancel')}
		>
			<UomForm form={form} />
		</Modal>
	);
};

export default EditModal;
