import React, { useState, useEffect, useReducer, useContext } from "react";
import { Typography, Button, Row, Col } from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { fetchDataReducer } from "../../reducers/fetchDataReducer";
import { useProduct } from "../../hooks/useProduct";

import ProductTable from "../../components/product/ProductTable";
// import EditModal from "./modals/EditModal";
import { AccountContext } from "../../components/layouts/BaseLayout";
import ProductDetailDrawer from "../../components/product/ProductDetailDrawer";
import NewProductModal from "../../components/product/NewProductModal";
import EditProductModal from "../../components/product/EditProductModal";

const { Title } = Typography;

// Use reducer here because these 3 states are all inter-connected.
const initialState = {
  refresh: true,
  loading: true,
  error: null,
};

const ProductList = () => {
  const { account } = useContext(AccountContext);

  const { t } = useTranslation();
  const [state, dispatch] = useReducer(fetchDataReducer, initialState);

  const { findAll } = useProduct();
  const [data, setData] = useState([]);
  const [editItem, setEditItem] = useState([]);

  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [detailDrawer, setDetailDrawer] = useState(false);

  useEffect(() => {
    if (state.refresh && account.Companies) {
      findAll({
        companyId: account.Companies[0].id,
      }).then((res) => {
        if (res.status === "success") {
          setData(res.data);
          dispatch({ type: "complete" });
        } else {
          setData([]);
          dispatch({ type: "error", error: t("error.connectionError") });
        }
      });
    }
  }, [state.refresh, findAll, t, account.Companies]);

  useEffect(() => {
    if (editItem.id) {
      const index = data.map((item) => item.id).indexOf(editItem.id);

      if (index > -1) {
        setEditItem(data[index]);
      }
    }
  }, [editItem, data]);

  const editRow = (item) => {
    setEditItem(item);
    setEditModal(true);
  };

  const viewDetail = (item) => {
    setEditItem(item);
    setDetailDrawer(true);
  };

  return (
    <div className="site-layout-background" style={{ padding: 24, marginTop: 16 }}>
      <Title level={2}>{t("product.title")}</Title>

      <Row justify="space-between">
        <Col>
          <Button disabled={state.loading} type="primary" onClick={() => setAddModal(true)}>
            {t("action.add")}
          </Button>
        </Col>
        <Col>
          <Button icon={<ReloadOutlined />} disabled={state.loading} onClick={() => dispatch({ type: "refresh" })} />
        </Col>
      </Row>

      <ProductTable
        error={state.error}
        loading={state.loading}
        dataSource={data}
        editRow={editRow}
        viewDetail={viewDetail}
      />

      <NewProductModal account={account} visible={addModal} setVisible={setAddModal} dispatch={dispatch} />

      <EditProductModal editItem={editItem} visible={editModal} setVisible={setEditModal} dispatch={dispatch} />

      <ProductDetailDrawer
        editItem={editItem}
        visible={detailDrawer}
        setVisible={setDetailDrawer}
        state={state}
        dispatch={dispatch}
      />
    </div>
  );
};

export default ProductList;
