import React, { useState, useContext } from "react";
import { Form, Input, Button, notification } from "antd";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../App";
import { useAuth } from "../../../hooks/useAuth";

const layout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 18,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 6,
    span: 18,
  },
};

const LoginForm = () => {
  const { t } = useTranslation();

  const { dispatch } = useContext(AuthContext);

  const { login } = useAuth();

  const [form] = Form.useForm();

  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = () => {
    form.validateFields().then(async (values, err) => {
      if (!err) {
        setSubmitting(true);

        const params = {
          email: values.email,
          password: values.password,
        };

        const res = await login(params);

        if (res.status === "success") {
          dispatch({
            type: "LOGIN",
            payload: {
              authUser: res.data.auth,
              token: res.data.token,
            },
          });

          notification.success({
            message: t("notification.success"),
            top: 72,
          });
        } else {
          notification.error({
            message: t("notification.fail"),
            top: 72,
          });
          setSubmitting(false);
        }
      }
    });
  };

  return (
    <Form {...layout} form={form} name="login-form" autoComplete="true" onFinish={handleSubmit}>
      <Form.Item name="email" label="Email" rules={[{ type: "email" }, { required: true }]}>
        <Input allowClear />
      </Form.Item>

      <Form.Item name="password" label="Password" rules={[{ required: true }]}>
        <Input.Password />
      </Form.Item>

      <Form.Item {...tailLayout}>
        <Button type="primary" htmlType="submit" disabled={submitting}>
          Login
        </Button>
      </Form.Item>
    </Form>
  );
};

export default LoginForm;
