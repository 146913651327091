import React, { useEffect, useState } from "react";
import { Form, Modal } from "antd";
import { useTranslation } from "react-i18next";
import { useBlanketOrder } from "../../hooks/useBlanketOrder";
import BlanketOrderForm from "./BlanketOrderForm";
import moment from "moment";

const EditModal = (props) => {
  const { editItem, visible, setVisible, dispatch } = props;

  const { t } = useTranslation();

  const [form] = Form.useForm();

  const { update } = useBlanketOrder();

  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        companyName: editItem.Requestor.name,
        shipTo: editItem.shipTo,
        effectiveUntilCancel: editItem.effectiveUntilCancel,
        effectiveDate:
          editItem.effectiveStartDt && editItem.effectiveEndDt
            ? [moment(editItem.effectiveStartDt), moment(editItem.effectiveEndDt)]
            : null,
        carrier: editItem.carrier,
        commercialTerm: editItem.commercialTerm,
        comment: editItem.comment,
        deliveryInstruction: editItem.deliveryInstruction,
      });
    }
  }, [visible, form, editItem]);

  const handleOk = (e) => {
    form.validateFields().then((values, err) => {
      if (!err) {
        const params = {
          shipTo: values.shipTo,
          effectiveUntilCancel: values.effectiveUntilCancel,
          effectiveStartDt: values.effectiveDate ? moment(values.effectiveDate[0]).format() : null,
          effectiveEndDt: values.effectiveDate ? moment(values.effectiveDate[1]).format() : null,
          carrier: values.carrier,
          commercialTerm: values.commercialTerm,
          comment: values.comment,
          deliveryInstruction: values.deliveryInstruction,
        };
        update(editItem.id, params).then((res) => {
          if (res.status === "success") {
            dispatch({ type: "refresh" });
            setVisible(false);
            form.resetFields();
          }
          setSubmitting(false);
        });
      }
    });
  };

  const handleCancel = () => setVisible(false);

  return (
    <Modal
      title={t("blanketOrder.editTitle")}
      confirmLoading={submitting}
      centered
      visible={visible}
      destroyOnClose
      onCancel={handleCancel}
      onOk={handleOk}
      okText={t("action.submit")}
      cancelText={t("action.cancel")}
      width={"80%"}
    >
      <BlanketOrderForm form={form} mode="edit" />
    </Modal>
  );
};

export default EditModal;
