import React, { useState, useEffect, useContext } from "react";
import { Divider, Form, Modal } from "antd";
import { useTranslation } from "react-i18next";
import BlanketOrderForm from "./BlanketOrderForm";
import { useBlanketOrder } from "../../hooks/useBlanketOrder";
import moment from "moment";
import { useProduct } from "../../hooks/useProduct";
import { useCollaborator } from "../../hooks/useCollaborator";
import { AccountContext } from "../layouts/BaseLayout";
import BlanketOrderDetailTable from "./BlanketOrderDetailTable";

const NewBlanketOrderModal = (props) => {
  const { account } = useContext(AccountContext);
  const { type, visible, setVisible, dispatch } = props;

  const { t } = useTranslation();

  const [form] = Form.useForm();

  const { findAll: findCollaborator } = useCollaborator();
  const { findAll: findProduct } = useProduct();
  const { create } = useBlanketOrder();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const [submitting, setSubmitting] = useState(false);
  const [companyOptions, setCompanyOptions] = useState([]);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (visible && account.Companies) {
      findCollaborator({ companyId: account.Companies[0].id }).then((res) => {
        if (res.status === "success") {
          setCompanyOptions(res.data);
        }
      });

      // If this is an incoming order, current user is supplier, so load own product.
      if (type === "in") {
        findProduct({ companyId: account.Companies[0].id }).then((res) => {
          if (res.status === "success") {
            setData(res.data);
          }
        });
      }
    }
  }, [visible, account.Companies]);

  const handleOk = (e) => {
    form.validateFields().then((values, err) => {
      if (!err) {
        const productArray = data.filter((item) => item.quantity > 0);

        if (productArray.length <= 0) {
          setError(t("error.productQuantityCannotBeZero"));
          return;
        } else {
          setError();
        }

        const params = {
          companyId: account.Companies[0].id, // creator company id
          requestorId: type === "in" ? values.companyId : account.Companies[0].id, // requestor company id
          supplierId: type === "in" ? account.Companies[0].id : values.companyId, // supplier company id
          orderDate: moment().format(),
          shipTo: values.shipTo,
          effectiveUntilCancel: values.effectiveUntilCancel,
          effectiveStartDt: values.effectiveDate ? moment(values.effectiveDate[0]).format() : null,
          effectiveEndDt: values.effectiveDate ? moment(values.effectiveDate[1]).format() : null,
          carrier: values.carrier,
          commercialTerm: values.commercialTerm,
          comment: values.comment,
          deliveryInstruction: values.deliveryInstruction,
          productArray: productArray,
        };

        setSubmitting(true);
        create(params).then((res) => {
          if (res.status === "success") {
            dispatch({ type: "refresh" });
            setVisible(false);
            form.resetFields();
            setData([]);
          }
          setSubmitting(false);
        });
      }
    });
  };

  const handleCancel = () => setVisible(false);

  const updateProduct = (row) => {
    let newData = [...data];

    const index = data.map((item) => item.id).indexOf(row.id);

    if (index > -1) {
      newData[index] = row;
      setData(newData);
    }
  };

  const onCompanyChanged = (value) => {
    const index = companyOptions.map((item) => item.CollaboratorCompany.id).indexOf(value);

    if (index > -1) {
      const x = companyOptions[index].CollaboratorCompany;
      form.setFieldsValue({
        shipTo: x.address + " " + x.city + ", " + x.postalCode + " " + x.region + ", " + x.country,
      });

      if (type === "out") {
        setLoading(true);
        findProduct({ companyId: x.id }).then((res) => {
          if (res.status === "success") {
            setData(res.data);
          }
          setLoading(false);
        });
      }
    }
  };

  return (
    <Modal
      title={t("blanketOrder.addNewTitle")}
      confirmLoading={submitting}
      centered
      visible={visible}
      destroyOnClose
      onCancel={handleCancel}
      onOk={handleOk}
      okText={t("action.submit")}
      cancelText={t("action.cancel")}
      width={"80%"}
    >
      <BlanketOrderForm form={form} companyOptions={companyOptions} onCompanyChanged={onCompanyChanged} />

      <Divider orientation="left">{t("common.productList")}</Divider>

      {error ? <p style={{ color: "#FF4D4F" }}>{error}</p> : null}

      <BlanketOrderDetailTable loading={loading} dataSource={data} updateProduct={updateProduct} />
    </Modal>
  );
};

export default NewBlanketOrderModal;
