import React from "react";
import { Form, Input, Select } from "antd";
import countryList from "country-list";

const { Option } = Select;

const SignUpFormContactInfo = (props) => {
  const { hidden } = props;
  return (
    <>
      <h4 style={{ marginBottom: 24 }} hidden={hidden}>
        Please enter company information:
      </h4>

      <Form.Item name="businessName" label="Company Name" rules={[{ required: true }]} hidden={hidden}>
        <Input allowClear />
      </Form.Item>

      <Form.Item name="businessRegNo" label="Business Registration No." rules={[{ required: true }]} hidden={hidden}>
        <Input allowClear />
      </Form.Item>

      <Form.Item name="businessCountry" label="Country" rules={[{ required: true }]} hidden={hidden}>
        <Select
          showSearch
          filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >
          {countryList.getData().map((item) => (
            <Option key={item.name} value={item.name}>
              {item.name}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item name="businessAddress" label="Address" rules={[{ required: true }]} hidden={hidden}>
        <Input.TextArea rows={2} allowClear />
      </Form.Item>

      <Form.Item name="businessCity" label="City" rules={[{ required: true }]} hidden={hidden}>
        <Input.TextArea rows={2} allowClear />
      </Form.Item>

      <Form.Item name="businessRegion" label="Region" rules={[{ required: true }]} hidden={hidden}>
        <Input allowClear />
      </Form.Item>

      <Form.Item name="businessPostalCode" label="Postal Code" rules={[{ required: true }]} hidden={hidden}>
        <Input allowClear />
      </Form.Item>

      <Form.Item name="businessContactNo" label="Phone Number" rules={[{ required: true }]} hidden={hidden}>
        <Input allowClear />
      </Form.Item>
    </>
  );
};

export default SignUpFormContactInfo;
