import React, { useState, useEffect, useContext } from "react";
import { Form, List, Select, Typography, notification, Button } from 'antd';
import { useTranslation } from "react-i18next";
import { useAuth } from '../../../hooks/useAuth';
import { AuthContext } from '../../../App';

const { Title } = Typography;

const { Option } = Select;

const Preferences = (props) => {

    const { t } = useTranslation();

    const { authState } = useContext(AuthContext);

    const { edit, updatePreference } = useAuth();
    
    const [form] = Form.useForm();

    const [languageList, setLanguageList] = useState({});
    const [blanketOrderStatusList, setBlanketOrderStatusList] = useState({});
    const [shipmentOrderStatusList, setShipmentOrderStatusList] = useState({});

    useEffect(() => {

        const fetchData = async () => {

            const res = await edit();

            if (res.status === 'success') {

                const language = res.data.profileSettings
                    .filter(item => item.key === 'language')[0];
                const blanketOrderStatus = res.data.profileSettings
                    .filter(item => item.key === 'blanketOrderStatus')[0];
                const shipmentOrderStatus = res.data.profileSettings
                    .filter(item => item.key === 'shipmentOrderStatus')[0];


                setLanguageList(language);
                setBlanketOrderStatusList(blanketOrderStatus);
                setShipmentOrderStatusList(shipmentOrderStatus);

            }
        }
        fetchData();

    }, [edit]);


    const handleSubmit = () => {

        form.validateFields().then(async (values, err) => {
            
            if (!err) {

                const params = {
                    settings: values.settings
                }

                const res = await updatePreference(params);

                if (res.status === 'success') {
                    notification.success({
                        message: t("notification.success"),
                        top: 72
                    });
                }
                else {
                    notification.error({
                        message: t("notification.fail"),
                        top: 72
                    });
                }
            }
        });
    }

    return (
        <>
            <Title level={4}>
                {t('account.setting.preference')}
            </Title>

            <Form form={form}>
                <List>
                    <List.Item>
                        <List.Item.Meta
                            title={t('account.setting.language.title')}
                            description={t('account.setting.language.description')} />

                        <div>
                            <Form.Item
                                name={['settings', 'language']}
                                initialValue={authState.authUser.settings.language}
                                noStyle>
                                <Select style={{ width: 180 }}>
                                    {languageList.value?.map(option => (
                                        <Option key={option} value={option}>{option}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </div>

                    </List.Item>

                    <List.Item>
                        <List.Item.Meta
                            title={t('account.setting.blanketOrderStatus.title')}
                            description={t('account.setting.blanketOrderStatus.description')} />
                        <div>
                            <Form.Item
                                name={['settings', 'blanketOrderStatus']}
                                initialValue={authState.authUser.settings.blanketOrderStatus}
                                noStyle>
                                <Select style={{ width: 180 }}>
                                    {blanketOrderStatusList.value?.map(option => (
                                        <Option key={option} value={option}>{option}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </div>
                    </List.Item>

                    <List.Item>
                        <List.Item.Meta
                            title={t('account.setting.shipmentOrderStatus.title')}
                            description={t('account.setting.shipmentOrderStatus.description')} />
                        <div>
                            <Form.Item
                                name={['settings', 'shipmentOrderStatus']}
                                initialValue={authState.authUser.settings.shipmentOrderStatus}
                                noStyle>
                                <Select style={{ width: 180 }}>
                                    {shipmentOrderStatusList.value?.map(option => (
                                        <Option key={option} value={option}>{option}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </div>
                    </List.Item>

                </List>

                <Button type="primary"
                    onClick={() => handleSubmit()}>
                    {t('action.submit')}
                </Button>
            </Form>
        </>
    )
}

export default Preferences;