import React, { useContext } from "react";
import { Row, Col, Card, Typography, Alert, Button } from "antd";
import { Redirect, useLocation } from "react-router-dom";
import LoginForm from "./forms/LoginForm";
import { AuthContext } from "../../App";

const { Title, Text } = Typography;

const Login = () => {
  const { authState } = useContext(AuthContext);

  const location = useLocation();

  return authState.isAuthenticated ? (
    <Redirect to="/dashboard" />
  ) : (
    <div style={{ paddingTop: 32 }}>
      <Row justify="center">
        <Col
          span={18}
          // xs={{ span: 24 }}
          // sm={{ span: 18, offset: 3 }}
          //  md={{ span: 12, offset: 6 }}
        >
          {location.search === "?activation=success" && (
            <Alert type="success" message="Account successfully activated." style={{ marginBottom: 16 }} />
          )}
          {/* <Card> */}
          {/* <Title>Login</Title> */}
          <LoginForm />

          <div style={{ textAlign: "center", marginTop: 40 }}>
            <Text>
              Don't have an account?{" "}
              <Button type="link" href="/signUp">
                Sign Up
              </Button>
            </Text>
          </div>
          {/* </Card> */}
        </Col>
      </Row>
    </div>
  );
};

export default Login;
