import React from 'react';
import { Form, Input, InputNumber } from "antd";
import { useTranslation } from "react-i18next";

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

const ProductForm = props => {

    const { form } = props;

    const { t } = useTranslation();

    return (
        <Form form={form} {...layout} name="product-form">
            <Form.Item name="productName" label={t("product.productName")} rules={[{ required: true }]}>
                <Input allowClear />
            </Form.Item>
            <Form.Item name="shortName" label={t("product.shortName")}>
                <Input allowClear />
            </Form.Item>
            <Form.Item name="internalName" label={t("product.internalName")} rules={[{ required: true }]}>
                <Input allowClear />
            </Form.Item>
            <Form.Item name="weightInGram" label={t("common.weightInGram")}>
                <InputNumber min={0} allowClear />
            </Form.Item>
            {/* <Form.Item name="baseUomId" label={t("product.baseUom")}
rules={[{ required: true }]}
>
    <Select allowClear>
        {uomOptions.map(item => (
            <Option key={item.id} value={item.id}>{item.name} ({item.shortCode})</Option>
        ))}
    </Select>
</Form.Item>
<Form.Item name="saleUomId" label={t("product.saleUom")}
rules={[{ required: true }]}
>
            <Select allowClear>
        {uomOptions.map(item => (
            <Option key={item.id} value={item.id}>{item.name} ({item.shortCode})</Option>
        ))}
    </Select>
</Form.Item> */}

        </Form>
    )

}

export default ProductForm;