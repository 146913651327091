import { useCallback, useContext } from "react";
import { AuthContext, fp } from "../App";
import Constants from "../utils/Constants";
import { useFetch } from "../hooks/useFetch";

const apiBaseUrl = Constants.SERVER_URL;

const useProduct = () => {
  const { authState } = useContext(AuthContext);

  const { send } = useFetch();

  const findAll = useCallback(
    async (query) => {
      const config = {
        method: "get",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
      };

      return await send(apiBaseUrl + "/wapi/products?companyId=" + query.companyId, config);
    },
    [authState.token, send]
  );

  const create = useCallback(
    async (params) => {
      const config = {
        method: "post",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
        body: JSON.stringify(params),
      };

      return await send(apiBaseUrl + "/wapi/products", config);
    },
    [authState.token, send]
  );

  const update = useCallback(
    async (id, params) => {
      const config = {
        method: "post",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
        body: JSON.stringify(params),
      };

      return await send(apiBaseUrl + "/wapi/products/" + id, config);
    },
    [authState.token, send]
  );

  const assignManufacturer = useCallback(
    async (id, params) => {
      const config = {
        method: "post",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
        body: JSON.stringify(params),
      };

      return await send(apiBaseUrl + "/wapi/products/" + id + "/assignManufacturer", config);
    },
    [authState.token, send]
  );

  const assignCustomer = useCallback(
    async (id, params) => {
      const config = {
        method: "post",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
        body: JSON.stringify(params),
      };

      return await send(apiBaseUrl + "/wapi/products/" + id + "/assignCustomer", config);
    },
    [authState.token, send]
  );

  return {
    findAll,
    create,
    update,
    assignManufacturer,
    assignCustomer,
  };
};

export { useProduct };
