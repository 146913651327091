import React, { useState, useEffect, useContext, useReducer } from "react";
import { Empty, List, Row, Col, Space, Button } from "antd";
import { useTranslation } from "react-i18next";
import { BellOutlined } from "@ant-design/icons";
import { AuthContext } from "../../App";
import { useNotification } from "../../hooks/useNotification";
import { AccountContext } from "../../components/layouts/BaseLayout";
import BlanketOrderDetailDrawer from "../../components/order/BlanketOrderDetailDrawer";
import ShipmentOrderDetailDrawer from "../../components/order/ShipmentOrderDetailDrawer";
import { fetchDataReducer } from "../../reducers/fetchDataReducer";

const moment = require("moment");

// Use reducer here because these 3 states are all inter-connected.
const initialState = {
  refresh: true,
  loading: true,
  error: null,
};

const Notification = (props) => {
  const { t } = useTranslation();

  const { authState } = useContext(AuthContext);

  const { account } = useContext(AccountContext);

  const { getNotificationList, markNotificationAsRead, clearAllNotification, markAllAsRead } = useNotification();
  const [state, dispatch] = useReducer(fetchDataReducer, initialState);
  const [data, setData] = useState([]);

  const [blanketOrderDetailDrawer, setBlanketOrderDetailDrawer] = useState(false);
  const [shipmentOrderDetailDrawer, setShipmentOrderDetailDrawer] = useState(false);
  const [viewItem, setViewItem] = useState({});

  useEffect(() => {
    if (account && account.id)
      getNotificationList({
        accountId: account.id,
      }).then((res) => {
        if (res.status === "success") {
          setData(res.data);
        }
      });
  }, [getNotificationList, account]);

  const markAsRead = (item) => {
    let array = [...data];
    const index = array.map((item) => item.id).indexOf(item.id);
    array[index].hasRead = true;
    setData(array);

    markNotificationAsRead(item.id, { userId: account.id });

    const url = item.url;

    if (url.includes("blanketOrder")) {
      setBlanketOrderDetailDrawer(true);
      const regex = /boid=(\d+)/;
      const match = url.match(regex);
      setViewItem({ id: match[1] });
    }
    if (url.includes("shipmentOrder")) {
      setShipmentOrderDetailDrawer(true);
      const regex = /soid=(\d+)/;
      const match = url.match(regex);
      setViewItem({ id: match[1] });
    }
  };

  const markAll = () => {
    let array = data.map((item) => {
      return { ...item, hasRead: true };
    });
    setData(array);
    markAllAsRead({ userId: authState.authUser.id });
  };

  const clearAll = () => {
    setData([]);
    clearAllNotification({ userId: authState.authUser.id });
  };

  return (
    <div className="site-layout-background" style={{ padding: 24, marginTop: 16 }}>
      <Row justify="end">
        <Col>
          <Space>
            <Button type="primary" onClick={() => markAll()}>
              {t("action.markAllAsRead")}
            </Button>
            <Button type="ghost" danger onClick={() => clearAll()}>
              {t("action.clearAll")}
            </Button>
          </Space>
        </Col>
      </Row>

      <div style={{ height: 16 }}></div>

      {data.length > 0 ? (
        <List
          bordered
          pagination={{
            onChange: (page) => {
              console.log(page);
            },
            pageSize: 10,
            // size:"small"
          }}
          header={t("notification.title")}
          itemLayout="horizontal"
          dataSource={data}
          renderItem={(item) => (
            <List.Item
              style={{
                padding: "12px 24px",
                borderBottom: "1px solid #ececec",
                backgroundColor: item.hasRead ? null : "#f0f8ff",
              }}
              onClick={() => markAsRead(item)}
            >
              <List.Item.Meta
                avatar={<BellOutlined />}
                title={<span style={{ fontWeight: "normal" }}>{item.content}</span>}
                description={<span style={{ fontSize: 11 }}>{moment(item.createdAt).fromNow()}</span>}
              />
            </List.Item>
          )}
        />
      ) : (
        <div style={{ paddingTop: 50, paddingBottom: 50 }}>
          <Empty description="No new notification" image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </div>
      )}

      <BlanketOrderDetailDrawer
        id={viewItem.id}
        defaultTabKey={"orderNote"}
        visible={blanketOrderDetailDrawer}
        setVisible={setBlanketOrderDetailDrawer}
        dispatch={dispatch}
      />

      <ShipmentOrderDetailDrawer
        id={viewItem.id}
        defaultTabKey={"orderNote"}
        visible={shipmentOrderDetailDrawer}
        setVisible={setShipmentOrderDetailDrawer}
        dispatch={dispatch}
      />
    </div>
  );
};

export default Notification;
