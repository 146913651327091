import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Space } from "antd";
import { DeleteOutlined, CheckOutlined } from "@ant-design/icons";
import TableLoader from "../common/TableLoader";

const ProductUomTable = (props) => {
  const { t } = useTranslation();

  const { dataSource, loading, error, editRow, deleteRow } = props;

  const columns = [
    {
      title: t("common.id"),
      dataIndex: "id",
    },
    {
      title: t("product.productUom"),
      render: (text, row) => (row.Uom ? row.Uom.shortCode : "-"),
    },
    {
      title: t("product.conversionFactor"),
      dataIndex: "conversionFactor",
      render: (text) => (text ? text : "-"),
    },
    {
      title: t("product.baseUom"),
      dataIndex: "isBaseUom",
      render: (text) => (text ? <CheckOutlined /> : "-"),
    },
    {
      title: t("product.saleUom"),
      dataIndex: "isSaleUom",
      render: (text) => (text ? <CheckOutlined /> : "-"),
    },
    {
      title: t("common.action"),
      key: "action",
      render: (text, row) => (
        <Space>
          <Button type="link" onClick={() => editRow(row)}>
            {t("action.edit")}
          </Button>
          <Button icon={<DeleteOutlined />} type="link" style={{ color: "#FF4D4F" }} onClick={() => deleteRow(row)} />
        </Space>
      ),
    },
  ];

  return (
    <TableLoader error={error} loading={loading} dataSource={dataSource} columns={columns} style={{ marginTop: 24 }} />
  );
};

export default ProductUomTable;
