import React, { useEffect, useState, useReducer, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Drawer, Tabs, Row, Col, Button } from "antd";
import { AccountContext } from "../layouts/BaseLayout";
import NewShipmentOrderDetailModal from "./NewShipmentOrderDetailModal";
import EditShipmentOrderDetailModal from "./EditShipmentOrderDetailModal";
import ConfirmDeleteModal from "../common/ConfirmDeleteModal";
import { useShipmentOrderDetail } from "../../hooks/useShipmentOrderDetail";
import { fetchDataReducer } from "../../reducers/fetchDataReducer";
import DocumentList from "../document_upload/components/DocumentList";
import OrderNote from "../order_note/OrderNote";
import ShipmentOrderInfo from "./ShipmentOrderInfo";
import ShipmentOrderDetailTable2 from "./ShipmentOrderDetailTable2";
import { useShipmentOrder } from "../../hooks/useShipmentOrder";
import PageLoader from "../common/PageLoader";
import ActualShipDateModal from "./ActualShipDateModal";

// Use reducer here because these 3 states are all inter-connected.
const initialState = {
  refresh: true,
  loading: true,
  error: null,
};

const { TabPane } = Tabs;

const ShipmentOrderDetailDrawer = (props) => {
  const { account } = useContext(AccountContext);

  const { t } = useTranslation();

  const { type, defaultTabKey, id, visible, setVisible } = props;

  const { findOne, update } = useShipmentOrder();

  const { destroy } = useShipmentOrderDetail();

  const [state, dispatch] = useReducer(fetchDataReducer, initialState);

  const [order, setOrder] = useState({});
  const [orderItem, setOrderItem] = useState({});

  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [actualShipDateModal, setSetActualShipDateModal] = useState(false);
  const [canEdit, setCanEdit] = useState(false);

  useEffect(() => {
    if (visible && id) {
      findOne(id).then((res) => {
        if (res.status === "success") {
          setOrder(res.data);
        }
        dispatch({ type: "complete" });
      });
    }
  }, [visible, id, state.refresh]);

  useEffect(() => {
    if (order.id && account.Companies) {
      setCanEdit(order.companyId === account.Companies[0].id);
    }
  }, [order, account.Companies]);

  const onClose = () => {
    setVisible(false);
  };

  const editOrderItem = (item) => {
    setOrderItem(item);
    setEditModal(true);
  };

  const deleteOrderItem = (item) => {
    setOrderItem(item);
    setDeleteModal(true);
  };

  const confirmDelete = () => {
    destroy(orderItem.id, { shipmentOrderId: orderItem.shipmentOrderId }).then((res) => {
      if (res.status === "success") {
        dispatch({ type: "refresh" });
        setDeleteModal(false);
      }
    });
  };

  return (
    <Drawer
      //  title={t("shipmentOrder.detailTitle")}
      placement="right"
      onClose={onClose}
      visible={visible}
      width={"75%"}
    >
      {state.loading ? (
        <PageLoader />
      ) : (
        <>
          <ShipmentOrderInfo item={order} type={type} />

          {
            // Only allow to change status if the order is not completed.
            order.status === "New" && account["Companies"] && order.supplierId === account["Companies"][0]["id"] ? (
              <Button
                type="primary"
                onClick={() => {
                  setSetActualShipDateModal(true);
                }}
              >
                {t("action.markAsShip")}
              </Button>
            ) : null
          }

          {
            // Only allow to mark as received if this order is Shipped and this order's requestor is the current user.
            order.status === "Shipped" &&
            account["Companies"] &&
            order.requestorId === account["Companies"][0]["id"] ? (
              <Button
                type="primary"
                onClick={() => {
                  update(order.id, { status: "Received" }).then((res) => {
                    if (res.status === "success") {
                      dispatch({ type: "refresh" });
                    }
                  });
                }}
              >
                {t("action.markAsReceived")}
              </Button>
            ) : null
          }

          <Tabs defaultActiveKey={defaultTabKey}>
            <TabPane tab={t("common.productList")} key="productList">
              {canEdit && (
                <Row style={{ marginBottom: 16 }}>
                  <Col>
                    <Button type="primary" onClick={() => setAddModal(true)}>
                      {t("action.add")}
                    </Button>
                  </Col>
                </Row>
              )}

              <ShipmentOrderDetailTable2
                loading={false}
                error={false}
                dataSource={order.ShipmentOrderDetails}
                editRow={editOrderItem}
                deleteRow={deleteOrderItem}
                shipmentOrder={order}
              />
            </TabPane>

            <TabPane tab={t("common.document")} key="documentList">
              <DocumentList docType="ShipmentOrder" foreignId={id} />
            </TabPane>

            <TabPane tab={t("orderNote.title")} key="orderNote">
              <OrderNote orderId={id} orderType="ShipmentOrder" />
            </TabPane>
          </Tabs>
        </>
      )}

      <NewShipmentOrderDetailModal order={order} visible={addModal} setVisible={setAddModal} dispatch={dispatch} />

      <EditShipmentOrderDetailModal
        editItem={orderItem}
        visible={editModal}
        setVisible={setEditModal}
        dispatch={dispatch}
      />

      <ConfirmDeleteModal visible={deleteModal} setVisible={setDeleteModal} onOk={confirmDelete} />

      <ActualShipDateModal
        visible={actualShipDateModal}
        setVisible={setSetActualShipDateModal}
        order={order}
        dispatch={dispatch}
      />
    </Drawer>
  );
};

export default ShipmentOrderDetailDrawer;
