import React, { useState, useEffect } from "react";
import { Typography, Form, notification } from "antd";
import { useTranslation } from "react-i18next";
import BasicInfoForm from "../forms/BasicInfoForm";
import { useAccount } from "../../../hooks/useAccount";

const { Title } = Typography;

const BasicInfo = (props) => {
  const { account, setAccount } = props;

  const { t } = useTranslation();

  const { update } = useAccount();

  const [form] = Form.useForm();

  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (account.id) {
      form.setFieldsValue({
        name: account.name,
        email: account.email,
        refereeCode: account.refereeCode,
      });
    }
  }, [account, form]);

  const handleSubmit = () => {
    form.validateFields().then(async (values, err) => {
      if (!err) {
        setSubmitting(true);

        const params = {
          name: values.name,
        };

        const res = await update(account.id, params);

        if (res.status === "success") {
          notification.success({
            message: t("notification.success"),
            top: 72,
          });

          setAccount(res.data);
        } else {
          notification.error({
            message: t("notification.fail"),
            top: 72,
          });
        }
        setSubmitting(false);
      }
    });
  };

  return (
    <>
      <Title level={4}>{t("account.setting.account")}</Title>

      <BasicInfoForm form={form} submitting={submitting} handleSubmit={handleSubmit} />
    </>
  );
};

export default BasicInfo;
