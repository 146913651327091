import React, { useEffect, useContext, useState } from "react";
import { List, Form, Button, Input } from "antd";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../App";
import { useOrderNote } from "../../hooks/useOrderNote";
import { AccountContext } from "../layouts/BaseLayout";

const moment = require("moment");

const styles = {
  left: {
    background: "#efefef",
    borderRadius: 4,
    marginBottom: 10,
    marginRight: "70%",
    padding: 6,
    position: "relative",

    arrow: {
      borderLeft: "13px solid transparent",
      borderTop: "13px solid #efefef",
      top: 0,
      position: "absolute",
      left: -10,
    },
  },
  right: {
    background: "#dcf8c6",
    borderRadius: 4,
    marginBottom: 10,
    marginLeft: "70%",
    padding: 6,
    position: "relative",
    arrow: {
      borderLeft: "13px solid transparent",
      borderTop: "13px solid #dcf8c6",
      top: 0,
      position: "absolute",
      right: -10,
      transform: "rotate(-90deg)",
    },
  },
};

const OrderNote = (props) => {
  const { t } = useTranslation();

  const { authState } = useContext(AuthContext);

  const { account } = useContext(AccountContext);

  const { findAll, add } = useOrderNote();

  const { orderId, orderType } = props;

  const [form] = Form.useForm();

  const [refresh, setRefresh] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    if ((orderType && orderId) || refresh) {
      findAll({ orderType: orderType, orderId: orderId }).then((res) => {
        if (res.status === "success") {
          setData(res.data);
        }
        setRefresh(false);
      });
    }
  }, [orderType, orderId, refresh, findAll]);

  const submit = () => {
    form.validateFields().then(async (values, err) => {
      if (!err) {
        const params = {
          accountId: account.id,
          orderType: orderType,
          foreignId: orderId,
          content: values.content,
          ownerCompanyId: account.Companies[0].id,
        };
        const res = await add(params);

        if (res.status === "success") {
          setRefresh(true);
          form.resetFields();
        }
      }
    });
  };

  const isOwnNote = (item) => item.accountId && item.accountId.toString() === authState.authUser.id.toString();

  return (
    <>
      <Form form={form} name="add-order-note-form" onFinish={submit}>
        <Form.Item name="content" rules={[{ required: true, message: t("orderNote.required") }]}>
          <Input.TextArea placeholder="Enter here" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            {t("action.add")}
          </Button>
        </Form.Item>
      </Form>
      <List
        itemLayout="horizontal"
        size="small"
        pagination={{
          onChange: (page) => {
            console.log(page);
          },
          pageSize: 5,
          size: "small",
        }}
        dataSource={data}
        renderItem={(item) => (
          <div style={isOwnNote(item) ? styles.right : styles.left}>
            <div style={{ width: "100%" }}>
              {item.content}
              <div
                style={{
                  color: "rgba(0,0,0,0.5)",
                  fontSize: 11,
                  textAlign: "right",
                }}
              >
                {moment(item.createdAt).fromNow()}
                <br />
                <span style={{ fontSize: 9 }}>{item.author}</span>
              </div>
            </div>
            <div style={isOwnNote(item) ? styles.right.arrow : styles.left.arrow}></div>
          </div>
        )}
      />
    </>
  );
};

export default OrderNote;
