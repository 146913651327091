import React from "react";
import { Form, Input, Checkbox, Select } from "antd";
import { useTranslation } from "react-i18next";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const { Option } = Select;

const ProductUomForm = (props) => {
  const { form, uomOptions, mode } = props;

  const { t } = useTranslation();

  return (
    <Form form={form} {...layout} name="product-uom-form">
      {mode !== "edit" ? (
        <Form.Item name="uomId" label={t("uom.shortCode")} rules={[{ required: true }]}>
          <Select allowClear>
            {uomOptions.map((item) => (
              <Option key={item.id} value={item.id}>
                {item.name} - {item.shortCode}
              </Option>
            ))}
          </Select>
        </Form.Item>
      ) : null}
      <Form.Item
        name="conversionFactor"
        label={t("product.conversionFactor")}
        rules={[{ required: true }]}
        extra={t("product.productUomForm.extraMessage.conversionFactor")}
      >
        <Input allowClear />
      </Form.Item>
      <Form.Item
        name="isBaseUom"
        valuePropName="checked"
        label={t("product.baseUom")}
        extra={t("product.productUomForm.extraMessage.isBaseUom")}
      >
        <Checkbox />
      </Form.Item>
      <Form.Item
        name="isSaleUom"
        valuePropName="checked"
        label={t("product.saleUom")}
        extra={t("product.productUomForm.extraMessage.isSaleUom")}
      >
        <Checkbox />
      </Form.Item>
    </Form>
  );
};

export default ProductUomForm;
