import React, { useState, useEffect, useReducer, useContext } from "react";
import { Typography, Button, Row, Col } from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useBlanketOrder } from "../../hooks/useBlanketOrder";
import { fetchDataReducer } from "../../reducers/fetchDataReducer";

import BlanketOrderTable from "../../components/order/BlanketOrderTable";
import AddDocumentModal from "../../components/document_upload/modals/AddDocumentModal";
import { AccountContext } from "../../components/layouts/BaseLayout";
import NewBlanketOrderModal from "../../components/order/NewBlanketOrderModal";
import EditBlanketOrderModal from "../../components/order/EditBlanketOrderModal";
import NewShipmentOrderModal from "../../components/order/NewShipmentOrderModal";
import EditShipmentOrderModal from "../../components/order/EditShipmentOrderModal";
import BlanketOrderDetailDrawer from "../../components/order/BlanketOrderDetailDrawer";
import ShipmentOrderDetailDrawer from "../../components/order/ShipmentOrderDetailDrawer";
import { useLocation } from "react-router-dom";

const { Title } = Typography;

// Use reducer here because these 3 states are all inter-connected.
const initialState = {
  refresh: true,
  loading: true,
  error: null,
};

const SalesOrder = () => {
  const { account } = useContext(AccountContext);

  const { t } = useTranslation();

  // URL query params to trigger actions
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const boid = query.get("boid");
  const soid = query.get("soid");
  const tab = query.get("tab");

  const { findAll } = useBlanketOrder();

  const [state, dispatch] = useReducer(fetchDataReducer, initialState);

  const [data, setData] = useState([]);
  const [editItem, setEditItem] = useState({});
  const [shipmentItem, setShipmentItem] = useState({});
  const [defaultTabKey, setDefaultTabKey] = useState();

  const [newBlanketOrderModal, setNewBlanketOrderModal] = useState(false);
  const [editBlanketOrderModal, setEditBlanketOrderModal] = useState(false);
  const [newShipmentOrderModal, setNewShipmentOrderModal] = useState(false);
  const [editShipmentOrderModal, setEditShipmentOrderModal] = useState(false);
  const [addDocumentModal, setAddDocumentModal] = useState(false);

  const [blanketDetailDrawer, setBlanketDetailDrawer] = useState(false);
  const [shipmentDetailDrawer, setShipmentDetailDrawer] = useState(false);

  useEffect(() => {
    if (state.refresh && account.Companies) {
      findAll({
        companyId: account.Companies[0].id,
        type: "in",
      }).then((res) => {
        if (res.status === "success") {
          setData(res.data);
          dispatch({ type: "complete" });
        } else {
          setData([]);
          dispatch({ type: "error", error: t("error.connectionError") });
        }
      });
    }
  }, [state.refresh, account.Companies, findAll, t]);

  // Update Detail item, whenever there is changes
  useEffect(() => {
    if (editItem.id) {
      const index = data.map((item) => item.id).indexOf(editItem.id);

      if (index > -1) {
        setEditItem(data[index]);
      }
    }
  }, [editItem, data]);

  // Only check for url query params after data is fetch
  useEffect(() => {
    if (data.length) {
      if (boid) {
        const index = data.map((item) => item.id).indexOf(Number(boid));
        if (index > -1) {
          viewDetail(data[index]);

          if (tab) {
            setDefaultTabKey(tab);
          }
        }
      } else if (soid) {
        let array = [];

        for (const item of data) {
          for (const row of item.ShipmentOrders) {
            array.push(row);
          }
        }
        const index = array.map((item) => item.id).indexOf(Number(soid));
        if (index > -1) {
          viewShipmentDetail(array[index]);

          if (tab) {
            setDefaultTabKey(tab);
          }
        }
      }
    }
  }, [data]);

  const editRow = (item) => {
    setEditItem(item);
    setEditBlanketOrderModal(true);
  };

  const viewDetail = (item) => {
    setEditItem(item);
    setBlanketDetailDrawer(true);
  };

  const upload = (record) => {
    setEditItem(record);
    setAddDocumentModal(true);
  };

  const createShipmentOrder = (item) => {
    setEditItem(item);
    setNewShipmentOrderModal(true);
  };

  const viewShipmentDetail = (item) => {
    setShipmentItem(item);
    setShipmentDetailDrawer(true);
  };

  const editShipmentRow = (item) => {
    setEditItem(item);
    setEditShipmentOrderModal(true);
  };

  return (
    <div className="site-layout-background" style={{ padding: 24, marginTop: 16 }}>
      <Title level={2}>{t("salesOrder.title")}</Title>

      <Row justify="space-between">
        <Col>
          <Button disabled={state.loading} type="primary" onClick={() => setNewBlanketOrderModal(true)}>
            {t("action.add")}
          </Button>
        </Col>
        <Col>
          <Button icon={<ReloadOutlined />} disabled={state.loading} onClick={() => dispatch({ type: "refresh" })} />
        </Col>
      </Row>

      <BlanketOrderTable
        type="in"
        error={state.error}
        loading={state.loading}
        dataSource={data}
        editRow={editRow}
        viewDetail={viewDetail}
        upload={upload}
        viewShipmentDetail={viewShipmentDetail}
        createShipmentOrder={createShipmentOrder}
        editShipmentRow={editShipmentRow}
      />

      <NewBlanketOrderModal
        type="in"
        blanketOrder={editItem}
        visible={newBlanketOrderModal}
        setVisible={setNewBlanketOrderModal}
        dispatch={dispatch}
      />

      <EditBlanketOrderModal
        editItem={editItem}
        visible={editBlanketOrderModal}
        setVisible={setEditBlanketOrderModal}
        dispatch={dispatch}
      />

      <NewShipmentOrderModal
        blanketOrder={editItem}
        visible={newShipmentOrderModal}
        setVisible={setNewShipmentOrderModal}
        dispatch={dispatch}
      />

      <EditShipmentOrderModal
        shipmentOrder={editItem}
        visible={editShipmentOrderModal}
        setVisible={setEditShipmentOrderModal}
        dispatch={dispatch}
      />

      <BlanketOrderDetailDrawer
        type={"in"}
        id={editItem.id}
        defaultTabKey={defaultTabKey}
        visible={blanketDetailDrawer}
        setVisible={setBlanketDetailDrawer}
        dispatch={dispatch}
      />

      <ShipmentOrderDetailDrawer
        type={"in"}
        id={shipmentItem.id}
        defaultTabKey={defaultTabKey}
        visible={shipmentDetailDrawer}
        setVisible={setShipmentDetailDrawer}
        dispatch={dispatch}
      />

      <AddDocumentModal
        item={editItem}
        docType="BlanketOrder"
        visible={addDocumentModal}
        setVisible={setAddDocumentModal}
        dispatch={dispatch}
      />
    </div>
  );
};

export default SalesOrder;
