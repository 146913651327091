import React from "react";
import { Form, Input, Button } from "antd";
import { useTranslation } from "react-i18next";

const CompanyInfoForm = (props) => {
  const { t } = useTranslation();

  const { form, submitting, handleSubmit } = props;

  return (
    <Form form={form} name="company-info-form" layout="vertical">
      <Form.Item name="name" label={t("account.businessName")} rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item name="regNo" label={t("account.businessRegNo")} rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item name="contactNo" label={t("common.contact_no")} rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item name="address" label={t("common.address")} rules={[{ required: true }]}>
        <Input.TextArea rows={2} />
      </Form.Item>
      <Form.Item name="city" label={t("common.city")} rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item name="region" label={t("common.region")} rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item name="postalCode" label={t("common.postcode")} rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item name="country" label={t("common.country")} rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" disabled={submitting} onClick={handleSubmit}>
          {t("action.submit")}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CompanyInfoForm;
