import React, { useState, useEffect, useReducer } from "react";
import { useTranslation } from "react-i18next";
import { Drawer, Tabs, Row, Col, Button } from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import CompanyProductTable from "./CompanyProductTable";
import { fetchDataReducer } from "../../reducers/fetchDataReducer";
import PageLoader from "../common/PageLoader";
import { useCompany } from "../../hooks/useCompany";
import CompanyInfo from "./CompanyInfo";

const { TabPane } = Tabs;

// Use reducer here because these 3 states are all inter-connected.
const initialState = {
  refresh: true,
  loading: true,
  error: null,
};

const CompanyDetailDrawer = (props) => {
  const { t } = useTranslation();

  const { id, visible, setVisible } = props;

  const [state, dispatch] = useReducer(fetchDataReducer, initialState);

  const { findOne } = useCompany();

  const [item, setItem] = useState({});

  useEffect(() => {
    if (visible && id) {
      findOne(id).then((res) => {
        if (res.status === "success") {
          setItem(res.data);
        }
        dispatch({ type: "complete" });
      });
    }
  }, [visible, id]);

  // useEffect(() => {
  //   if (visible && id && state.refresh) {
  //     findOne(id).then((res) => {
  //       if (res.status === "success") {
  //         setOrder(res.data);
  //       }
  //       dispatch({ type: "complete" });
  //     });
  //   }
  // }, [visible, id, state.refresh]);

  const onClose = () => {
    setVisible(false);
  };

  return (
    <Drawer
      // title={t("collaborator.detailTitle")}
      placement="right"
      onClose={onClose}
      visible={visible}
      width={"75%"}
    >
      {state.loading ? (
        <PageLoader />
      ) : (
        <>
          <CompanyInfo item={item} />

          <Tabs defaultActiveKey="1">
            <TabPane tab={t("product.title")} key="product">
              {/* <Row justify="end">
                <Col>
                  <Button
                    icon={<ReloadOutlined />}
                    disabled={state.loading}
                    onClick={() => dispatch({ type: "refresh" })}
                  />
                </Col>
              </Row> */}

              <CompanyProductTable dataSource={item.Products} loading={state.loading} error={state.error} />
            </TabPane>
          </Tabs>
        </>
      )}
    </Drawer>
  );
};

export default CompanyDetailDrawer;
