import React from "react";
import { Descriptions, Row, Col, Typography } from "antd";
import { useTranslation } from "react-i18next";

const { Title } = Typography;

const ProductInfo = (props) => {
  const { item } = props;

  const { t } = useTranslation();

  return (
    <>
      <Title level={5}>
        {t("product.productName").toUpperCase()} : {item.productName}
      </Title>
      <Row justify="space-between">
        <Col span={16}>
          <Descriptions column={3}>
            <Descriptions.Item label={t("product.shortName")}>
              {item.shortName ? item.shortName : "-"}
            </Descriptions.Item>

            <Descriptions.Item label={t("product.internalName")}>
              {item.internalName ? item.internalName : "-"}
            </Descriptions.Item>

            <Descriptions.Item label={t("common.weightInGram")}>
              {item.weightInGram ? item.weightInGram : "-"}
            </Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>
    </>
  );
};

export default ProductInfo;
