import React, { useState, useContext } from 'react';
import { Dropdown, Menu, Button } from 'antd';
import { useTranslation } from "react-i18next";
import {
    DownOutlined,
    GlobalOutlined,
    CheckOutlined
} from '@ant-design/icons';

import { LocaleContext } from '../../../App';

const LocaleMenu = () => {

    const { i18n } = useTranslation();

    const { setLocaleContext } = useContext(LocaleContext);

    const [locale, setLocale] = useState(i18n.language); 

    const changeLanguage = (value) => {
        i18n.changeLanguage(value.key);
        setLocale(value.key);
        setLocaleContext(value.key)
    }

    const menu = () => (
        <Menu onClick={changeLanguage}>
            <Menu.Item key="en">
                English {locale === "en" ? <CheckOutlined /> : null}
            </Menu.Item>
            <Menu.Item key="cn">
                简体中文 {locale === "cn" ? <CheckOutlined /> : null}
            </Menu.Item>
        </Menu>
    );

    return (
        <Dropdown overlay={menu}>
            <Button type="link" icon={<GlobalOutlined />}>
                <DownOutlined />
            </Button>
        </Dropdown>


    )
}

export default LocaleMenu;