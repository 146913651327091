import React, { useState, useEffect } from "react";
import { Divider, Form, Modal } from "antd";
import { useTranslation } from "react-i18next";
import ShipmentOrderForm from "./ShipmentOrderForm";
import moment from "moment";
import ShipmentOrderDetailTable from "./ShipmentOrderDetailTable";
import { useShipmentOrder } from "../../hooks/useShipmentOrder";
import { useBlanketOrderDetail } from "../../hooks/useBlanketOrderDetail";

const NewShipmentOrderModal = (props) => {
  const { blanketOrder, visible, setVisible, dispatch } = props;

  const { t } = useTranslation();

  const [form] = Form.useForm();

  const { findAll } = useBlanketOrderDetail();

  const { create } = useShipmentOrder();

  const [error, setError] = useState();

  const [submitting, setSubmitting] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (visible && blanketOrder) {
      form.setFieldsValue({
        companyName: blanketOrder.Requestor.name,
        shipTo: blanketOrder.shipTo,
        deliveryInstruction: blanketOrder.deliveryInstruction,
      });

      findAll({ blanketOrderId: blanketOrder.id }).then((res) => {
        if (res.status === "success") {
          setData(
            res.data.map((item) => {
              return {
                ...item,
                maxQuantity: item.balanceInUom,
                newQuantityInUom: item.balanceInUom,
              };
            })
          );
        }
      });
    }
  }, [visible, blanketOrder]);

  const handleOk = (e) => {
    form.validateFields().then((values, err) => {
      if (!err) {
        const productArray = data.filter((item) => item.newQuantityInUom > 0);

        if (productArray.length <= 0) {
          setError(t("error.productQuantityCannotBeZero"));
          return;
        } else {
          setError();
        }

        const params = {
          companyId: blanketOrder.companyId,
          requestorId: blanketOrder.requestorId,
          supplierId: blanketOrder.supplierId,
          orderDate: moment().format(),
          blanketOrderId: blanketOrder.id,
          expectedShipDate: values.expectedShipDate,
          shipTo: values.shipTo,
          deliveryInstruction: values.deliveryInstruction,
          productArray: productArray,
        };

        setSubmitting(true);

        create(params).then((res) => {
          if (res.status === "success") {
            dispatch({ type: "refresh" });
            form.resetFields();
            setData([]);
            setVisible(false);
          }
          setSubmitting(false);
        });
      }
    });
  };

  const handleCancel = () => setVisible(false);

  const updateProduct = (row) => {
    let newData = [...data];

    const index = data.map((item) => item.id).indexOf(row.id);

    if (index > -1) {
      newData[index] = row;
      setData(newData);
    }
  };

  return (
    <Modal
      title={t("shipmentOrder.addNewTitle")}
      confirmLoading={submitting}
      centered
      visible={visible}
      destroyOnClose
      onCancel={handleCancel}
      onOk={handleOk}
      okText={t("action.submit")}
      cancelText={t("action.cancel")}
      width={"80%"}
    >
      <ShipmentOrderForm form={form} />

      <Divider orientation="left">{t("common.productList")}</Divider>

      {error ? <p style={{ color: "#FF4D4F" }}>{error}</p> : null}

      <ShipmentOrderDetailTable loading={false} dataSource={data} updateProduct={updateProduct} />
    </Modal>
  );
};

export default NewShipmentOrderModal;
