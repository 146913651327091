import React from "react";
import { Form, DatePicker, Input, Row, Col } from "antd";
import { useTranslation } from "react-i18next";

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

const ShipmentOrderForm = (props) => {
  const { t } = useTranslation();

  const { form } = props;

  return (
    <Form form={form} {...layout} name="shipment-order-form">
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name="companyName" label={t("common.company")}>
            <Input disabled />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name="expectedShipDate"
            label={t("shipmentOrder.expectedShipDate")}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <DatePicker style={{ width: "100%" }} />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name="shipTo"
            label={t("blanketOrder.shipTo")}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input.TextArea rows={3} />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item name="deliveryInstruction" label={t("blanketOrder.deliveryInstruction")}>
            <Input.TextArea rows={3} />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default ShipmentOrderForm;
