import React from "react";
import { DatePicker, Input, InputNumber, Table } from "antd";
import { useTranslation } from "react-i18next";
import TableLoader from "../common/TableLoader";

const moment = require("moment");

const BlanketOrderDetailTable = (props) => {
  const { t } = useTranslation();

  const { dataSource, loading, error, updateProduct } = props;

  const columns = [
    {
      title: t("common.product"),
      dataIndex: "productName",
    },
    {
      title: t("blanketOrder.requestShipDate"),
      dataIndex: "shipDate",
      render: (text, record) => (
        <DatePicker defaultValue={text} onChange={(value) => onShipDateChanged(value, record)} />
      ),
    },
    {
      title: t("common.price"),
      dataIndex: "price",
      render: (text, record) => (
        <InputNumber defaultValue={text} min={0} onChange={(value) => onPriceChanged(value, record)} />
      ),
    },
    {
      title: t("common.quantity"),
      dataIndex: "quantity",
      render: (text, row) => (
        <InputNumber
          disabled={!row.SaleUom}
          defaultValue={text}
          min={0}
          onChange={(value) => onQuantityChanged(value, row)}
        />
      ),
    },
    {
      title: t("common.uom"),
      render: (text, row) => (row.SaleUom ? row.SaleUom.Uom.shortCode : "-"),
    },
    {
      title: t("common.remarks"),
      dataIndex: "remarks",
      render: (text, record) => <Input value={record.remarks} onChange={(e) => onRemarksChanged(e, record)} />,
    },
  ];

  const onPriceChanged = (value, row) => {
    updateProduct({
      ...row,
      price: value,
    });
  };

  const onQuantityChanged = (value, row) => {
    updateProduct({
      ...row,
      quantity: value,
    });
  };

  const onRemarksChanged = (e, row) => {
    updateProduct({
      ...row,
      remarks: e.target.value,
    });
  };

  const onShipDateChanged = (value, row) => {
    updateProduct({
      ...row,
      shipDate: moment(value).format(),
    });
  };

  return (
    <TableLoader error={error} dataSource={dataSource} loading={loading}>
      <Table rowKey="id" size="small" error={error} loading={loading} dataSource={dataSource} columns={columns} />
    </TableLoader>
  );
};

export default BlanketOrderDetailTable;
