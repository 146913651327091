import React from "react";
import { Descriptions, Row, Col, Typography } from "antd";
import { useTranslation } from "react-i18next";

const { Title } = Typography;

const CompanyInfo = (props) => {
  const { item } = props;

  const { t } = useTranslation();

  return (
    <>
      <Title level={5}>
        {t("common.company").toUpperCase()} : {item.name}
      </Title>
      <Row justify="space-between">
        <Col span={16}>
          <Descriptions column={3}>
            <Descriptions.Item label={t("common.contact_no")}>
              {item.contactNo ? item.contactNo : "-"}
            </Descriptions.Item>

            <Descriptions.Item label={t("common.address")} span={2}>
              {item.address ? item.address : "-"}
            </Descriptions.Item>

            <Descriptions.Item label={t("common.city")}>{item.city ? item.city : "-"}</Descriptions.Item>

            <Descriptions.Item label={t("common.region")}>{item.region ? item.region : "-"}</Descriptions.Item>

            <Descriptions.Item label={t("common.country")}>{item.country ? item.country : "-"}</Descriptions.Item>
          </Descriptions>
        </Col>

        {/* <Col span={8}>
          <Row justify="space-between" align="middle">
            <Col span={12}>
              <div style={{ color: "grey", textAlign: "right" }}>{t("common.status")}</div>
              <div style={{ fontSize: 24, textAlign: "right" }}>{item.status ? item.status : "-"}</div>
            </Col>
          </Row>
        </Col> */}
      </Row>
    </>
  );
};

export default CompanyInfo;
