import React, { useState, useEffect, useContext } from "react";
import { Form, Modal } from "antd";
import { useTranslation } from "react-i18next";
import { useProductUom } from "../../hooks/useProductUom";
import { useUom } from "../../hooks/useUom";
import ProductUomForm from "./ProductUomForm";
import { AccountContext } from "../layouts/BaseLayout";

const EditProductUomModal = (props) => {
  const { account } = useContext(AccountContext);

  const { editItem, visible, setVisible, dispatch } = props;

  const { t } = useTranslation();

  const [form] = Form.useForm();

  const { findAll } = useUom();

  const { update } = useProductUom();

  const [submitting, setSubmitting] = useState(false);

  const [uomOptions, setUomOptions] = useState([]);

  useEffect(() => {
    if (visible) {
      findAll({
        companyId: account.Companies[0].id,
      }).then((res) => {
        if (res.status === "success") {
          setUomOptions(res.data);
        }
      });
      form.setFieldsValue({
        conversionFactor: editItem.conversionFactor,
        isBaseUom: editItem.isBaseUom,
        isSaleUom: editItem.isSaleUom,
      });
    }
  }, [visible, form, editItem, account.Companies, findAll]);

  const handleOk = (e) => {
    form.validateFields().then((values, err) => {
      if (!err) {
        const params = {
          conversionFactor: values.conversionFactor,
          isBaseUom: values.isBaseUom,
          isSaleUom: values.isSaleUom,
        };
        update(editItem.id, params).then((res) => {
          if (res.status === "success") {
            dispatch({ type: "refresh" });
            setVisible(false);
            form.resetFields();
          }
          setSubmitting(false);
        });
      }
    });
  };

  const handleCancel = () => setVisible(false);

  return (
    <Modal
      title={t("uom.editTitle")}
      confirmLoading={submitting}
      centered
      visible={visible}
      destroyOnClose
      onCancel={handleCancel}
      onOk={handleOk}
      okText={t("action.submit")}
      cancelText={t("action.cancel")}
    >
      <ProductUomForm form={form} uomOptions={uomOptions} mode={"edit"} />
    </Modal>
  );
};

export default EditProductUomModal;
