import React from 'react';
import { Spin } from 'antd';

const PageLoader = () => {

    return (
        <div style={{
            display: "flex",
            height: '100%',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'absolute',
            background: 'rgba(255,255,255,0.1)'
        }}>
            <Spin />
        </div>
    );
}

export default PageLoader