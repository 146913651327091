import React, { useState } from "react";
import { Form, Modal } from "antd";
import { useTranslation } from "react-i18next";
import { useUom } from "../../../hooks/useUom";
import UomForm from "../forms/UomForm";

const AddModal = (props) => {
  const { account, visible, setVisible, dispatch } = props;

  const { t } = useTranslation();

  const [form] = Form.useForm();

  const { create } = useUom();

  const [submitting, setSubmitting] = useState(false);

  const handleOk = (e) => {
    form.validateFields().then((values, err) => {
      if (!err) {
        const params = {
          companyId: account.Companies[0].id,
          name: values.name.toUpperCase(),
          shortCode: values.shortCode.toUpperCase(),
          description: values.description,
        };
        create(params).then((res) => {
          if (res.status === "success") {
            dispatch({ type: "refresh" });
            setVisible(false);
            form.resetFields();
          }
          setSubmitting(false);
        });
      }
    });
  };

  const handleCancel = () => setVisible(false);

  return (
    <Modal
      title={t("uom.addNewTitle")}
      confirmLoading={submitting}
      centered
      visible={visible}
      destroyOnClose
      onCancel={handleCancel}
      onOk={handleOk}
      okText={t("action.submit")}
      cancelText={t("action.cancel")}
    >
      <UomForm form={form} />
    </Modal>
  );
};

export default AddModal;
