import React from "react";
import { Form, Steps, Divider } from "antd";
import SignUpFormSubscription from "./SignUpFormSubscription";
import SignUpFormBillingInfo from "./SignUpFormBillingInfo";
import UpgradeFormComplete from "./UpgradeFormComplete";

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

const { Step } = Steps;

const UpgradeForm = (props) => {
  const { form, currentStep, planOption, selectedPlan, setSelectedPlan } = props;

  return (
    <Form {...layout} form={form} name="signup-form" autoComplete="true" labelAlign="left">
      <Steps current={currentStep} size="small">
        <Step title="Plan" />
        <Step title="Billing" />
        <Step title="Complete" />
      </Steps>

      <Divider />

      <SignUpFormSubscription
        form={form}
        hidden={!(currentStep === 0)}
        planOption={planOption}
        selectedPlan={selectedPlan}
        setSelectedPlan={setSelectedPlan}
      />

      <SignUpFormBillingInfo hidden={!(currentStep === 1)} />

      {currentStep === 2 && <UpgradeFormComplete />}
    </Form>
  );
};

export default UpgradeForm;
