import React, { useState, useEffect } from "react";
import { Form, Drawer, Row, Col, Spin, Alert, Space, Button } from "antd";
import SignUpForm from "./SignUpForm";
import { useChargebee } from "../../hooks/useChargebee";
import { useAuth } from "../../hooks/useAuth";
import { useAccount } from "../../hooks/useAccount";

const SignUpDrawer = (props) => {
  const { defaultPlanId, visible, setVisible, onCloseDrawer, isMobile } = props;

  const { findPlan, createSubscription } = useChargebee();
  const { register } = useAuth();
  const { create } = useAccount();
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState({});
  const [planOption, setPlanOption] = useState([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [submitting, setSubmitting] = useState(false);

  const [error, setError] = useState();

  useEffect(() => {
    if (visible) {
      setLoading(true);
      findPlan().then((res) => {
        if (res.status === "success") {
          let array = res.data.filter((item) => item.id !== "trade-free");
          setPlanOption(array);
          form.setFieldsValue({
            planId: defaultPlanId,
          });

          const index = array.map((item) => item.id).indexOf(defaultPlanId);

          if (index > -1) {
            setSelectedPlan(array[index]);
          } else {
            setSelectedPlan({});
          }
        }

        setLoading(false);
      });
    }
  }, [form, visible, findPlan]);

  const handleSubmit = () => {
    form.validateFields().then(async (values, err) => {
      if (!err) {
        setSubmitting(true);
        const params = {
          name: values.name,
          email: values.email,
          businessName: values.businessName,
          businessRegNo: values.businessRegNo,
          businessCountry: values.businessCountry,
          businessAddress: values.businessAddress,
          businessCity: values.businessCity,
          businessRegion: values.businessRegion,
          businessPostalCode: values.businessPostalCode,
          businessContactNo: values.businessContactNo,
          planId: values.planId,
          billingAddress: values.billingAddress,
          billingCity: values.billingCity,
          billingCountry: values.billingCountry,
          billingFirstName: values.billingFirstName,
          billingLastName: values.billingLastName,
          billingPostcode: values.billingPostcode,
          billingState: values.billingState,
        };

        createSubscription(params).then((res) => {
          if (res.status === "success") {
            const subscriptionResult = res.data;

            register({
              name: values.name,
              email: values.email,
              password: "P@ssword1",
            }).then((res) => {
              if (res.data.user) {
                // User successfully created. Proceed to register account
                create({
                  id: res.data.user.id,
                  name: res.data.user.name,
                  email: res.data.user.email,
                  refereeCode: values.refereeCode,
                  businessName: values.businessName,
                  businessRegNo: values.businessRegNo,
                  businessCountry: values.businessCountry,
                  businessAddress: values.businessAddress,
                  businessCity: values.businessCity,
                  businessRegion: values.businessRegion,
                  businessPostalCode: values.businessPostalCode,
                  businessContactNo: values.businessContactNo,
                  cbCustomer: subscriptionResult.customer,
                  cbInvoice: subscriptionResult.invoice,
                  cbSubscription: subscriptionResult.subscription,
                  // planPackage: data.planId.split('-')[0]
                }).then((res) => {
                  if (res.status === "success") {
                    next();
                  } else {
                    setError(
                      "Unfortunately, there is an error creating your account. Please contact our technical support with your registration details and payment info."
                    );
                  }
                });
              }
            });
          }
          setSubmitting(false);
        });
      }
    });
  };

  const next = () => {
    let fields = null;

    if (currentStep === 0) {
      fields = ["planId"];
    } else if (currentStep === 1) {
      fields = ["name", "email"];
    } else if (currentStep === 2) {
      fields = [
        "businessName",
        "businessRegNo",
        "businessCountry",
        "businessAddress",
        "businessCity",
        "businessRegion",
        "businessPostalCode",
        "businessContactNo",
      ];
    } else if (currentStep === 3) {
      fields = ["billingFirstName", "billingLastName", "billingAddress", "billingPostcode", "billingCountry"];
    }

    form.validateFields(fields).then(async (values, err) => {
      if (!err) {
        setCurrentStep((currentStep) => currentStep + 1);
      }
    });
  };

  const previous = () => {
    setCurrentStep((currentStep) => currentStep - 1);
  };

  return (
    <Drawer
      title="Sign Up"
      visible={visible}
      destroyOnClose={true}
      onClose={() => {
        setVisible(false);
        onCloseDrawer();
      }}
      width={isMobile ? "100%" : "50%"}
      footer={
        <div style={{ textAlign: "right" }}>
          <Space>
            {currentStep > 0 && currentStep < 4 && (
              <Button onClick={previous} disabled={submitting}>
                Previous
              </Button>
            )}

            {currentStep < 3 && (
              <Button type="primary" onClick={next}>
                Next
              </Button>
            )}

            {currentStep === 3 && (
              <Button type="primary" onClick={handleSubmit} disabled={submitting}>
                Submit
              </Button>
            )}
          </Space>
        </div>
      }
    >
      <div style={{ paddingTop: 16 }}>
        <SignUpForm
          form={form}
          currentStep={currentStep}
          planOption={planOption}
          selectedPlan={selectedPlan}
          setSelectedPlan={setSelectedPlan}
          loading={loading}
        />

        {submitting && (
          <Row justify="center" style={{ marginBottom: 24 }}>
            <Col span={24} style={{ textAlign: "center" }}>
              <Spin size="small" />
            </Col>
          </Row>
        )}

        {error && error !== "" ? (
          <Row justify="center" style={{ marginBottom: 24 }}>
            <Col span={24} style={{ textAlign: "center" }}>
              <Alert message={error} type="error" />
            </Col>
          </Row>
        ) : null}
      </div>
    </Drawer>
  );
};

export default SignUpDrawer;
