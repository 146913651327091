import React, { useState } from 'react';
import { Form, Input, notification, Modal } from 'antd';
import { useTranslation } from "react-i18next";
import { useAuth } from '../../../hooks/useAuth';

const EditPasswordModal = (props) => {

    const { t } = useTranslation();

    const [form] = Form.useForm();
    const { updatePassword } = useAuth()

    const { visible, setVisible } = props;

    const closeModal = () => setVisible(false);

    const [confirmDirty, setConfirmDirty] = useState(false);

    const compareToFirstPassword = (rule, value, callback) => {
        
        if (value && value !== form.getFieldValue('password')) {
            callback(t('message.passwordNotMatch'));
        } else {
            callback();
        }
    };

    const handleConfirmBlur = e => {
        const { value } = e.target;
        setConfirmDirty(confirmDirty || !!value);
    };


    const handleSubmit = e => {

        form.validateFields().then(async (values, err) => {
            if (!err) {
                const params = {
                    'password': values.password,
                }

                const res = await updatePassword(params);

                if (res.status === 'success') {
                    notification.success({
                        message: t("notification.success"),
                        top: 72
                    });

                    closeModal();
                }
                else {
                    notification.error({
                        message: t("notification.fail"),
                        top: 72
                    });
                }
            }
        });
    };

    return (
        <Modal title={t('action.edit') + " " + t('common.password')}
            centered
            visible={visible}
            destroyOnClose
            maskClosable={true}
            onOk={handleSubmit}
            onCancel={closeModal}
            okText={t("action.submit")}
            cancelText={t("action.cancel")}>

            <Form
                form={form}
                name='edit-password-form'
                layout="vertical">

                <Form.Item
                    name="password"
                    label={t('common.password')}
                    rules={[{
                        required: true,
                    }, {
                        pattern: "^(?=.*([A-Z]){1,})(?=.*[!@#$&*]{1,})(?=.*[0-9]{1,})(?=.*[a-z]{1,}).{8,100}$",
                        message: t('message.passwordPattern')
                    }]}>
                    <Input.Password />
                </Form.Item>

                <Form.Item
                    name='confirm'
                    label={t('action.confirm') + " " + t('common.password')}
                    rules={[
                        {
                            message: t('message.confirmPassword'),
                        },
                        {
                            validator: compareToFirstPassword,
                        },
                    ]}
                    hasFeedback>

                    <Input.Password onBlur={handleConfirmBlur} />
                </Form.Item>
            </Form>


        </Modal>
    );

};

export default EditPasswordModal;