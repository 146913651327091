import React, { useState } from "react";
import { Modal, Button, List } from "antd";
import { useTranslation } from "react-i18next";
import { DeleteOutlined } from "@ant-design/icons";
import { red } from "@ant-design/colors";
import { useInvitation } from "../../../hooks/useInvitation";
import ConfirmDeleteModal from "../../../components/common/ConfirmDeleteModal";

const PendingInviteModal = (props) => {
  const { visible, setVisible, dispatch, data } = props;

  const { t } = useTranslation();

  const { destroy } = useInvitation();

  const [editItem, setEditItem] = useState();
  const [deleteModal, setDeleteModal] = useState(false);

  const handleCancel = () => setVisible(false);

  const remove = (item) => {
    setEditItem(item);
    setDeleteModal(true);
  };

  const confirmDelete = () => {
    destroy(editItem.id).then((res) => {
      if (res.status === "success") {
        dispatch({ type: "refresh" });
        setDeleteModal(false);
      }
    });
  };

  return (
    <>
      <Modal
        title={"Pending Invitation"}
        centered
        visible={visible}
        destroyOnClose
        onCancel={handleCancel}
        cancelText={t("action.cancel")}
        footer={[<Button onClick={handleCancel}>{t("action.close")}</Button>]}
      >
        <List
          dataSource={data}
          renderItem={(item) => (
            <List.Item
              actions={[
                <Button
                  type="text"
                  icon={<DeleteOutlined style={{ color: red.primary }} />}
                  onClick={() => remove(item)}
                />,
              ]}
            >
              <List.Item.Meta title={item.inviteeEmail} description={item.url} />
            </List.Item>
          )}
        />
        <ConfirmDeleteModal visible={deleteModal} setVisible={setDeleteModal} onOk={confirmDelete} />
      </Modal>
    </>
  );
};

export default PendingInviteModal;
