import React, { useEffect, useState } from "react";
import { Form, Modal } from "antd";
import { useTranslation } from "react-i18next";
import ShipmentOrderForm from "./ShipmentOrderForm";
import moment from "moment";
import { useShipmentOrder } from "../../hooks/useShipmentOrder";

const EditModal = (props) => {
  const { shipmentOrder, visible, setVisible, dispatch } = props;

  const { t } = useTranslation();

  const [form] = Form.useForm();

  const { update } = useShipmentOrder();

  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        companyName: shipmentOrder.Requestor.name,
        shipTo: shipmentOrder.shipTo,
        expectedShipDate: moment(shipmentOrder.expectedShipDate),
        deliveryInstruction: shipmentOrder.deliveryInstruction,
      });
    }
  }, [visible, form, shipmentOrder]);

  const handleOk = (e) => {
    form.validateFields().then((values, err) => {
      if (!err) {
        const params = {
          shipTo: values.shipTo,
          expectedShipDate: moment(values.expectedShipDate).format(),
          deliveryInstruction: values.deliveryInstruction,
        };
        update(shipmentOrder.id, params).then((res) => {
          if (res.status === "success") {
            dispatch({ type: "refresh" });
            setVisible(false);
            form.resetFields();
          }
          setSubmitting(false);
        });
      }
    });
  };

  const handleCancel = () => setVisible(false);

  return (
    <Modal
      title={t("shipmentOrder.editTitle")}
      confirmLoading={submitting}
      centered
      visible={visible}
      destroyOnClose
      onCancel={handleCancel}
      onOk={handleOk}
      okText={t("action.submit")}
      cancelText={t("action.cancel")}
      width={"80%"}
    >
      <ShipmentOrderForm form={form} mode="edit" />
    </Modal>
  );
};

export default EditModal;
