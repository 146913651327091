import React from 'react';
import { Form, Input } from "antd";
import { useTranslation } from "react-i18next";

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

const UomForm = (props) => {

    const { form } = props;

    const { t } = useTranslation();

    return (
        <Form form={form} {...layout} name="uom-form">
            <Form.Item name="name" label={t("common.name")} rules={[{ required: true }]}>
                <Input allowClear />
            </Form.Item>
            <Form.Item name="shortCode" label={t("uom.shortCode")} rules={[{ required: true }]}>
                <Input allowClear />
            </Form.Item>
            <Form.Item name="description" label={t("common.description")} >
                <Input allowClear />
            </Form.Item>
        </Form>
    )
}

export default UomForm;