import React from "react";
import { Form, Input } from "antd";

const SignUpFormAccountInfo = (props) => {
  const { hidden } = props;
  return (
    <>
      <h4 style={{ marginBottom: 24 }} hidden={hidden}>
        Please enter account information:
      </h4>

      <Form.Item name="name" label="Account Name" rules={[{ required: true }]} hidden={hidden}>
        <Input allowClear />
      </Form.Item>

      <Form.Item name="email" label="Email" rules={[{ type: "email" }, { required: true }]} hidden={hidden}>
        <Input allowClear />
      </Form.Item>

      <Form.Item name="refereeCode" label="Referral Code" hidden={hidden}>
        <Input allowClear />
      </Form.Item>
    </>
  );
};

export default SignUpFormAccountInfo;
