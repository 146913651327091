import React, { useState } from "react";
import { Form, List, Typography, Descriptions, Button } from "antd";
import { useTranslation } from "react-i18next";
import moment from "moment";
import startCase from "lodash/startCase";
import UpgradeDrawer from "../../../components/signUp/UpgradeDrawer";

const { Title } = Typography;

const Subscription = (props) => {
  const { t } = useTranslation();

  const { account } = props;

  const [form] = Form.useForm();

  const [upgradeDrawer, setUpgradeDrawer] = useState(false);

  return (
    <>
      <Title level={4}>{t("account.setting.subscription")}</Title>

      <Form form={form}>
        <List>
          {account.cbSubscription ? (
            <>
              <List.Item>
                <Descriptions title="Subscription Info">
                  <Descriptions.Item label={t("subscription.plan")}>
                    {startCase(account.cbSubscription.plan_id)}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("common.status")}>
                    {startCase(account.cbSubscription.status)}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("common.amount")}>
                    {account.cbSubscription.currency_code +
                      " " +
                      (account.cbSubscription.plan_amount / 100).toLocaleString() +
                      " / " +
                      account.cbSubscription.billing_period_unit +
                      " x" +
                      account.cbSubscription.plan_quantity}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("subscription.nextBillingDate")}>
                    {account.cbSubscription.next_billing_at
                      ? moment.unix(account.cbSubscription.next_billing_at).format("YYYY-MM-DD")
                      : "-"}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("subscription.nextBillingAmount")}>
                    {account.cbSubscription.currency_code +
                      " " +
                      (account.cbSubscription.plan_amount / 100).toLocaleString()}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("subscription.autoCollection")}>
                    {startCase(account.cbSubscription.auto_collection)}
                  </Descriptions.Item>
                </Descriptions>
              </List.Item>

              <List.Item>
                <Descriptions title="Billing Info">
                  <Descriptions.Item label={t("common.email")}>{account.cbCustomer.email}</Descriptions.Item>
                  <Descriptions.Item label={t("common.firstName")}>
                    {account.cbCustomer.billing_address.first_name}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("common.lastName")}>
                    {account.cbCustomer.billing_address.last_name}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("subscription.billingAddress")} span={3}>
                    {account.cbCustomer.billing_address.line1}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("common.city")}>
                    {account.cbCustomer.billing_address.city}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("common.postcode")}>
                    {account.cbCustomer.billing_address.zip}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("common.country")}>
                    {account.cbCustomer.billing_address.country}
                  </Descriptions.Item>
                </Descriptions>
              </List.Item>

              <List.Item>
                <Descriptions title="Invoice">
                  <Descriptions.Item label={t("subscription.amountDue")}>
                    {account.cbInvoice.currency_code + " " + (account.cbInvoice.amount_due / 100).toLocaleString()}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("subscription.amountPaid")}>
                    {account.cbInvoice.currency_code + " " + (account.cbInvoice.amount_paid / 100).toLocaleString()}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("common.status")}>
                    {startCase(account.cbInvoice.status)}
                  </Descriptions.Item>
                </Descriptions>
              </List.Item>
            </>
          ) : (
            <Descriptions title="Subscription Info" column={1}>
              <Descriptions.Item label={t("subscription.plan")}>Free Plan</Descriptions.Item>
              <Descriptions.Item>
                <Button type="primary" onClick={() => setUpgradeDrawer(true)}>
                  Upgrade
                </Button>
              </Descriptions.Item>
            </Descriptions>
          )}
        </List>
      </Form>

      <UpgradeDrawer visible={upgradeDrawer} setVisible={setUpgradeDrawer} />
    </>
  );
};

export default Subscription;
