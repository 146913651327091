import { useCallback, useContext } from "react";
import { AuthContext, fp } from "../App";
import Constants from "../utils/Constants";
import { useFetch } from "../hooks/useFetch";

const apiBaseUrl = Constants.SERVER_URL;
const publicBaseUrl = Constants.SERVER_URL + "/wapi-501/invitations";

const useInvitation = () => {
  const { authState } = useContext(AuthContext);

  const { send } = useFetch();

  const findAll = useCallback(
    async (query) => {
      const config = {
        method: "get",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
      };

      return await send(
        apiBaseUrl +
          "/wapi/invitations?companyId=" +
          query.companyId +
          "&status=" +
          query.status +
          "&inviteeEmail=" +
          query.inviteeEmail,
        config
      );
    },
    [authState.token, send]
  );

  const create = useCallback(
    async (params) => {
      const config = {
        method: "post",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
        body: JSON.stringify(params),
      };

      return await send(apiBaseUrl + "/wapi/invitations", config);
    },
    [authState.token, send]
  );

  const update = useCallback(
    async (id, params) => {
      const config = {
        method: "post",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
        body: JSON.stringify(params),
      };

      return await send(apiBaseUrl + "/wapi/invitations/" + id, config);
    },
    [authState.token, send]
  );

  const destroy = useCallback(
    async (id) => {
      const config = {
        method: "delete",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
      };

      return await send(apiBaseUrl + "/wapi/invitations/" + id, config);
    },
    [authState.token, send]
  );

  const findPendingInvite = useCallback(
    async (inviteCode) => {
      const config = {
        method: "get",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
      };

      return await send(publicBaseUrl + "/" + inviteCode + "/pending", config);
    },
    [authState.token, send]
  );

  const acceptInvitation = useCallback(
    async (id, params) => {
      const config = {
        method: "post",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
        body: JSON.stringify(params),
      };

      return await send(publicBaseUrl + "/" + id + "/accept", config);
    },
    [authState.token, send]
  );

  return {
    findAll,
    create,
    update,
    destroy,
    findPendingInvite,
    acceptInvitation,
  };
};

export { useInvitation };
