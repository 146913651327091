import React from "react";
import { Form, Input, Select } from "antd";
import countryList from "country-list";

const { Option } = Select;

const SignUpFormBillingInfo = (props) => {
  const { hidden } = props;
  return (
    <>
      <h4 style={{ marginBottom: 24 }} hidden={hidden}>
        Please enter billing information:
      </h4>

      <Form.Item name="billingFirstName" label="First Name" rules={[{ required: true }]} hidden={hidden}>
        <Input allowClear />
      </Form.Item>

      <Form.Item name="billingLastName" label="Last Name" rules={[{ required: true }]} hidden={hidden}>
        <Input allowClear />
      </Form.Item>

      <Form.Item name="billingAddress" label="Address" rules={[{ required: true }]} hidden={hidden}>
        <Input allowClear />
      </Form.Item>

      <Form.Item name="billingCity" label="City" hidden={hidden}>
        <Input allowClear />
      </Form.Item>

      <Form.Item name="billingState" label="State" hidden={hidden}>
        <Input allowClear />
      </Form.Item>

      <Form.Item name="billingPostcode" label="Postcode" rules={[{ required: true }]} hidden={hidden}>
        <Input allowClear />
      </Form.Item>

      <Form.Item name="billingCountry" label="Country" rules={[{ required: true }]} hidden={hidden}>
        <Select
          showSearch
          filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >
          {countryList.getData().map((item) => (
            <Option key={item.code} value={item.code}>
              {item.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </>
  );
};

export default SignUpFormBillingInfo;
