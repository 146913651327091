import { useCallback, useContext } from "react";
import { AuthContext, fp } from "../App";
import Constants from "../utils/Constants";
import { useFetch } from "../hooks/useFetch";

const apiBaseUrl = Constants.SERVER_URL;

const useDashboard = () => {
  const { authState } = useContext(AuthContext);
  const { send } = useFetch();

  const findTotalSales = useCallback(
    async (query) => {
      const config = {
        method: "get",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
      };
      return await send(
        apiBaseUrl + "/wapi/dashboard/findTotalSales?year=" + query.year + "&companyId=" + query.companyId,
        config
      );
    },
    [authState.token, send]
  );

  const findInventory = useCallback(
    async (query) => {
      const config = {
        method: "get",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
      };
      return await send(apiBaseUrl + "/wapi/dashboard/findInventory?companyId=" + query.companyId, config);
    },
    [authState.token, send]
  );

  // const findBroodstockData = useCallback(async () => {
  //   const config = {
  //     method: "get",
  //     headers: {
  //       Authorization: "Bearer " + authState.token,
  //       "Content-Type": "application/json",
  //       FpId: (await fp).visitorId,
  //     },
  //   };
  //   return await send(apiBaseUrl + "/wapi/dashboard/hatchery/findBroodstockData", config);
  // }, [authState.token, send]);

  // const findLarvalData = useCallback(async () => {
  //   const config = {
  //     method: "get",
  //     headers: {
  //       Authorization: "Bearer " + authState.token,
  //       "Content-Type": "application/json",
  //       FpId: (await fp).visitorId,
  //     },
  //   };
  //   return await send(apiBaseUrl + "/wapi/dashboard/hatchery/findLarvalData", config);
  // }, [authState.token, send]);

  // const findHarvestData = useCallback(async () => {
  //   const config = {
  //     method: "get",
  //     headers: {
  //       Authorization: "Bearer " + authState.token,
  //       "Content-Type": "application/json",
  //       FpId: (await fp).visitorId,
  //     },
  //   };
  //   return await send(apiBaseUrl + "/wapi/dashboard/hatchery/findHarvestData", config);
  // }, [authState.token, send]);

  // const findChemicalUsageData = useCallback(
  //   async (startDate, endDate, tankId) => {
  //     const config = {
  //       method: "get",
  //       headers: {
  //         Authorization: "Bearer " + authState.token,
  //         "Content-Type": "application/json",
  //         FpId: (await fp).visitorId,
  //       },
  //     };
  //     return await send(
  //       apiBaseUrl +
  //         "/wapi/dashboard/hatchery/findChemicalUsageData?startDate=" +
  //         startDate +
  //         "&endDate=" +
  //         endDate +
  //         "&tankId=" +
  //         tankId,
  //       config
  //     );
  //   },
  //   [authState.token, send]
  // );

  // const findFarmActivity = useCallback(
  //   async (date) => {
  //     const config = {
  //       method: "get",
  //       headers: {
  //         Authorization: "Bearer " + authState.token,
  //         "Content-Type": "application/json",
  //         FpId: (await fp).visitorId,
  //       },
  //     };
  //     return await send(apiBaseUrl + "/wapi/dashboard/farm/activity?date=" + date, config);
  //   },
  //   [authState.token, send]
  // );

  // const findPondFeedingData = useCallback(
  //   async (startDate, endDate, pondId) => {
  //     const config = {
  //       method: "get",
  //       headers: {
  //         Authorization: "Bearer " + authState.token,
  //         "Content-Type": "application/json",
  //         FpId: (await fp).visitorId,
  //       },
  //     };
  //     return await send(
  //       apiBaseUrl +
  //         "/wapi/dashboard/farm/findPondFeedingData?startDate=" +
  //         startDate +
  //         "&endDate=" +
  //         endDate +
  //         "&pondId=" +
  //         pondId,
  //       config
  //     );
  //   },
  //   [authState.token, send]
  // );

  // const findPondPreparationUsageData = useCallback(
  //   async (startDate, endDate, pondId) => {
  //     const config = {
  //       method: "get",
  //       headers: {
  //         Authorization: "Bearer " + authState.token,
  //         "Content-Type": "application/json",
  //         FpId: (await fp).visitorId,
  //       },
  //     };
  //     return await send(
  //       apiBaseUrl +
  //         "/wapi/dashboard/farm/findPondPreparationUsageData?startDate=" +
  //         startDate +
  //         "&endDate=" +
  //         endDate +
  //         "&pondId=" +
  //         pondId,
  //       config
  //     );
  //   },
  //   [authState.token, send]
  // );

  // const findSSCMonitoringData = useCallback(async () => {
  //   const config = {
  //     method: "get",
  //     headers: {
  //       Authorization: "Bearer " + authState.token,
  //       "Content-Type": "application/json",
  //       FpId: (await fp).visitorId,
  //     },
  //   };
  //   return await send(apiBaseUrl + "/wapi/dashboard/farm/findSSCMonitoringData", config);
  // }, [authState.token, send]);

  // const findLarvalStage = useCallback(async () => {
  //   const config = {
  //     method: "get",
  //     headers: {
  //       Authorization: "Bearer " + authState.token,
  //       "Content-Type": "application/json",
  //       FpId: (await fp).visitorId,
  //     },
  //   };
  //   return await send(apiBaseUrl + "/wapi/dashboard/hatchery/findLarvalStage", config);
  // }, [authState.token, send]);

  // const findTotalRawMat = useCallback(
  //   async (query) => {
  //     const config = {
  //       method: "get",
  //       headers: {
  //         Authorization: "Bearer " + authState.token,
  //         "Content-Type": "application/json",
  //         FpId: (await fp).visitorId,
  //       },
  //     };
  //     return await send(
  //       apiBaseUrl +
  //         "/wapi/dashboard/processing/findTotalRawMat?startDate=" +
  //         query.startDate +
  //         "&endDate=" +
  //         query.endDate,
  //       config
  //     );
  //   },
  //   [authState.token, send]
  // );

  // const findProcessingInventory = useCallback(async () => {
  //   const config = {
  //     method: "get",
  //     headers: {
  //       Authorization: "Bearer " + authState.token,
  //       "Content-Type": "application/json",
  //       FpId: (await fp).visitorId,
  //     },
  //   };
  //   return await send(apiBaseUrl + "/wapi/dashboard/processing/findProcessingInventory", config);
  // }, [authState.token, send]);

  return {
    findTotalSales,
    findInventory,
  };
};

export { useDashboard };
