const customerAdmin = [
  // "Hatchery.Add",
];

const manufacturerAdmin = [];

const RolePermission = {
  CUSTOMER: {
    ADMIN: customerAdmin,
  },
  MANUFACTURER: {
    ADMIN: manufacturerAdmin,
  },
};

export default RolePermission;
