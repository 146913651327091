import React, { useState, useEffect, useContext } from "react";
import { Form, Drawer, Row, Col, Spin, Alert, Space, Button } from "antd";
import { useChargebee } from "../../hooks/useChargebee";
import { useAccount } from "../../hooks/useAccount";
import UpgradeForm from "./UpgradeForm";
import { AccountContext } from "../layouts/BaseLayout";

const UpgradeDrawer = (props) => {
  const { defaultPlanId, visible, setVisible } = props;

  const { account } = useContext(AccountContext);

  const { findPlan, createSubscription } = useChargebee();
  const { update } = useAccount();
  const [form] = Form.useForm();

  const [selectedPlan, setSelectedPlan] = useState({});
  const [planOption, setPlanOption] = useState([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [submitting, setSubmitting] = useState(false);

  const [error, setError] = useState();

  useEffect(() => {
    if (visible) {
      findPlan().then((res) => {
        if (res.status === "success") {
          let array = res.data.filter((item) => item.id !== "trade-free");
          setPlanOption(array);
          form.setFieldsValue({
            planId: defaultPlanId,
          });

          const index = array.map((item) => item.id).indexOf(defaultPlanId);

          if (index > -1) {
            setSelectedPlan(array[index]);
          } else {
            setSelectedPlan({});
          }
        }
      });
    }
  }, [form, visible, findPlan]);

  const handleSubmit = () => {
    form.validateFields().then(async (values, err) => {
      if (!err) {
        setSubmitting(true);
        const params = {
          name: values.name,
          email: values.email,
          planId: values.planId,
          billingAddress: values.billingAddress,
          billingCity: values.billingCity,
          billingCountry: values.billingCountry,
          billingFirstName: values.billingFirstName,
          billingLastName: values.billingLastName,
          billingPostcode: values.billingPostcode,
          billingState: values.billingState,
        };

        createSubscription(params).then((res) => {
          if (res.status === "success") {
            const subscriptionResult = res.data;
            update(account.id, {
              cbCustomer: subscriptionResult.customer,
              cbInvoice: subscriptionResult.invoice,
              cbSubscription: subscriptionResult.subscription,
            }).then((res) => {
              if (res.status === "success") {
                next();
              }
            });
          }
          setSubmitting(false);
        });
      }
    });
  };

  const next = () => {
    let fields = null;

    if (currentStep === 0) {
      fields = ["planId"];
    } else if (currentStep === 1) {
      fields = ["billingFirstName", "billingLastName", "billingAddress", "billingPostcode", "billingCountry"];
    }

    form.validateFields(fields).then(async (values, err) => {
      if (!err) {
        setCurrentStep((currentStep) => currentStep + 1);
      }
    });
  };

  const previous = () => {
    setCurrentStep((currentStep) => currentStep - 1);
  };

  return (
    <Drawer
      title="Upgrade Account"
      visible={visible}
      destroyOnClose={true}
      onClose={() => setVisible(false)}
      width={"50%"}
      footer={
        <div style={{ textAlign: "left" }}>
          <Space>
            {currentStep > 0 && currentStep < 2 && (
              <Button onClick={previous} disabled={submitting}>
                Previous
              </Button>
            )}

            {currentStep < 1 && (
              <Button type="primary" onClick={next}>
                Next
              </Button>
            )}

            {currentStep === 1 && (
              <Button type="primary" onClick={handleSubmit} disabled={submitting}>
                Submit
              </Button>
            )}
          </Space>
        </div>
      }
    >
      <div style={{ paddingTop: 16 }}>
        <UpgradeForm
          form={form}
          currentStep={currentStep}
          planOption={planOption}
          selectedPlan={selectedPlan}
          setSelectedPlan={setSelectedPlan}
        />

        {submitting && (
          <Row justify="center" style={{ marginBottom: 24 }}>
            <Col span={24} style={{ textAlign: "center" }}>
              <Spin size="small" />
            </Col>
          </Row>
        )}

        {error && error !== "" ? (
          <Row justify="center" style={{ marginBottom: 24 }}>
            <Col span={24} style={{ textAlign: "center" }}>
              <Alert message={error} type="error" />
            </Col>
          </Row>
        ) : null}
      </div>
    </Drawer>
  );
};

export default UpgradeDrawer;
