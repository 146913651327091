import React from "react";
import { Descriptions, Row, Col, Typography } from "antd";
import { useTranslation } from "react-i18next";
import moment from "moment";

const { Title } = Typography;

const ShipmentOrderInfo = (props) => {
  const { item, type } = props;

  const { t } = useTranslation();

  return (
    <>
      <Title level={5}>
        {t("common.orderNo").toUpperCase()} : {item.orderNo}
      </Title>
      <Row justify="space-between">
        <Col span={16}>
          <Descriptions column={3}>
            <Descriptions.Item label={t("common.company")} span={3}>
              {type === "in" ? item.Requestor?.name : item.Supplier?.name}
            </Descriptions.Item>

            <Descriptions.Item label={t("common.date")} span={3}>
              {moment(item.orderDate).format("YYYY-MM-DD")}
            </Descriptions.Item>

            <Descriptions.Item label={t("blanketOrder.shipTo")} span={3}>
              {item.shipTo ? item.shipTo : "-"}
            </Descriptions.Item>

            <Descriptions.Item label={t("blanketOrder.deliveryInstruction")} span={3}>
              {item.deliveryInstruction ? item.deliveryInstruction : "-"}
            </Descriptions.Item>
          </Descriptions>
        </Col>

        <Col span={8}>
          <Row justify="space-between" align="middle" gutter={[8, 24]}>
            <Col span={12}>
              <div style={{ color: "grey", textAlign: "right", fontSize: 12 }}>{t("common.status")}</div>
              <div style={{ fontSize: 24, textAlign: "right" }}>{item.status ? item.status : "-"}</div>
            </Col>
            <Col span={12}>
              <div style={{ color: "grey", textAlign: "right", fontSize: 12 }}>{t("common.amount")}</div>
              <div style={{ fontSize: 24, textAlign: "right" }}>
                {item.totalAmount ? Number(item.totalAmount).toLocaleString() : "-"}
              </div>
            </Col>
            <Col span={12}>
              <div style={{ color: "grey", textAlign: "right", fontSize: 10 }}>
                {t("shipmentOrder.expectedShipDate")}
              </div>
              <div style={{ fontSize: 18, textAlign: "right" }}>
                {moment(item.expectedShipDate).format("YYYY-MM-DD")}
              </div>
            </Col>
            <Col span={12}>
              <div style={{ color: "grey", textAlign: "right", fontSize: 10 }}>{t("shipmentOrder.actualShipDate")}</div>
              <div style={{ fontSize: 18, textAlign: "right" }}>
                {item.actualShipDate ? moment(item.actualShipDate).format("YYYY-MM-DD") : "-"}
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default ShipmentOrderInfo;
