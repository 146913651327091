import React, { useEffect, useState } from "react";
import { Form, InputNumber, Modal, DatePicker, Input } from "antd";
import { useTranslation } from "react-i18next";
import { useBlanketOrderDetail } from "../../hooks/useBlanketOrderDetail";
import moment from "moment";

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

const EditBlanketOrderDetailModal = (props) => {
  const { editItem, visible, setVisible, dispatch } = props;

  const { t } = useTranslation();

  const [form] = Form.useForm();

  const { update } = useBlanketOrderDetail();

  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        requestShipDate: editItem.requestShipDate ? moment(editItem.requestShipDate) : null,
        quantityInUom: editItem.quantityInUom,
        uomPrice: editItem.uomPrice,
        remarks: editItem.remarks,
      });
    }
  }, [visible, form, editItem]);

  const handleOk = (e) => {
    form.validateFields().then((values, err) => {
      if (!err) {
        const params = {
          requestShipDate: values.requestShipDate ? moment(values.requestShipDate).format() : null,
          quantityInUom: values.quantityInUom,
          uomPrice: values.uomPrice,
          remarks: values.remarks,
        };
        setSubmitting(true);
        update(editItem.id, params).then((res) => {
          if (res.status === "success") {
            dispatch({ type: "refresh" });
            setVisible(false);
            form.resetFields();
          }
          setSubmitting(false);
        });
      }
    });
  };

  const handleCancel = () => setVisible(false);

  return (
    <Modal
      title={editItem.Product?.productName}
      confirmLoading={submitting}
      centered
      visible={visible}
      destroyOnClose
      onCancel={handleCancel}
      onOk={handleOk}
      okText={t("action.submit")}
      cancelText={t("action.cancel")}
    >
      <Form form={form} {...layout} name="order-item-form">
        <Form.Item name="requestShipDate" label={t("blanketOrder.requestShipDate")}>
          <DatePicker allowClear />
        </Form.Item>

        <Form.Item
          name="quantityInUom"
          label={t("common.quantity")}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <InputNumber min={0} allowClear />
        </Form.Item>

        <Form.Item
          name="uomPrice"
          label={t("common.price")}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <InputNumber min={0} allowClear />
        </Form.Item>

        <Form.Item label={t("common.uom")}>{editItem.Uom ? editItem.Uom.shortCode : "-"}</Form.Item>

        <Form.Item name="remarks" label={t("common.remarks")}>
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditBlanketOrderDetailModal;
