import React from "react";
import { Table, Spin, Alert, Empty } from "antd";
// import { useTranslation } from "react-i18next";

const TableLoader = props => {

    // const { t } = useTranslation();

    const { error, loading, dataSource, columns, rowKey } = props;

    return (
        <>
            {error && error.length > 0 ? (
                <Spin spinning={loading}>
                    <Alert
                        style={{ padding: 20, marginTop: 20 }}
                        message={error}
                        type="error"
                    />
                </Spin>
            ) : dataSource && dataSource.length > 0 ? (
                props.children ? props.children :
                    <Table
                        rowKey={rowKey ?? "id"}
                        loading={loading}
                        dataSource={dataSource}
                        columns={columns}
                        {...props}
                    />
            ) : (
                        <Spin spinning={loading}>
                            {/* <Empty description={t("status.noDataFound")} /> */}
                            <Empty />
                        </Spin>
                    )}
        </>
    )
}

export default TableLoader