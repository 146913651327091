import React, { useState, useEffect } from "react";
import { Form, DatePicker, Checkbox, Input, Row, Col, Select } from "antd";
import { useTranslation } from "react-i18next";

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

const { RangePicker } = DatePicker;

const { Option } = Select;

const BlanketOrderForm = (props) => {
  const { t } = useTranslation();

  const { form, companyOptions, mode, onCompanyChanged } = props;

  const [isChecked, setIsChecked] = useState();

  useEffect(() => {
    setIsChecked(form.getFieldValue("effectiveUntilCancel"));
  }, [form]);

  useEffect(() => {
    if (isChecked) {
      form.setFieldsValue({ effectiveDate: null });
      form.validateFields(["effectiveDate"]);
    }
  }, [isChecked, form]);

  const onEffectiveUntilCancelChanged = (e) => {
    setIsChecked(e.target.checked);
  };

  return (
    <Form form={form} {...layout} name="blanket-order-form" initialValues={{ effectiveUntilCancel: isChecked }}>
      <Row gutter={16}>
        <Col span={12}>
          {mode !== "edit" ? (
            <Form.Item
              name="companyId"
              label={t("common.company")}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select onChange={onCompanyChanged} allowClear>
                {companyOptions.map((item) => (
                  <Option key={item.CollaboratorCompany.id} value={item.CollaboratorCompany.id}>
                    {item.CollaboratorCompany.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          ) : (
            <Form.Item name="companyName" label={t("common.company")}>
              <Input disabled />
            </Form.Item>
          )}
        </Col>

        <Col span={12}>
          <Form.Item name="shipTo" label={t("blanketOrder.shipTo")}>
            <Input />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name="effectiveDate"
            label={t("blanketOrder.effectiveDate")}
            rules={[
              {
                required: !isChecked,
              },
            ]}
          >
            <RangePicker disabled={isChecked} style={{ width: "100%" }} />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item name="effectiveUntilCancel" label={t("blanketOrder.effectiveUntilCancel")} valuePropName="checked">
            <Checkbox onChange={onEffectiveUntilCancelChanged} />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item name="carrier" label={t("blanketOrder.carrier")}>
            <Input />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item name="commercialTerm" label={t("blanketOrder.commercialTerm")}>
            <Input />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item name="comment" label={t("blanketOrder.comment")}>
            <Input.TextArea rows={3} />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item name="deliveryInstruction" label={t("blanketOrder.deliveryInstruction")}>
            <Input.TextArea rows={3} />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default BlanketOrderForm;
