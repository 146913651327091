const fetchDataReducer = (state, action) => {
    switch (action.type) {
        case 'refresh':
            return {
                ...state,
                refresh: true,
                loading: true,
            }
        case 'complete':
            return {
                ...state,
                refresh: false,
                loading: false,
                error: null
            }
        case 'error':
            return {
                ...state,
                refresh: false,
                loading: false,
                error: action.error
            }
        default:
            return state;
    }
}

export { fetchDataReducer }