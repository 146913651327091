import React, { useState, useContext } from "react";
import { Form, Modal, Typography, Input } from "antd";
import { useTranslation } from "react-i18next";
// import { useCompany } from "../../../hooks/useCompany";
// import { useCollaborator } from "../../../hooks/useCollaborator";
import { AccountContext } from "../../../components/layouts/BaseLayout";
import { useInvitation } from "../../../hooks/useInvitation";

// const { Option } = Select;

const { Title } = Typography;

const AddModal = (props) => {
  const { account } = useContext(AccountContext);

  const { visible, setVisible, dispatch } = props;

  const { t } = useTranslation();

  const [form] = Form.useForm();

  // const { findAll } = useCompany();
  // const { invite } = useCollaborator();
  const { create } = useInvitation();

  const [submitting, setSubmitting] = useState(false);
  // const [companyList, setCompanyList] = useState([]);

  // useEffect(() => {
  //   if (visible && account.Companies) {
  //     findAll({ companyId: account.Companies[0].id }).then((res) => {
  //       if (res.status === "success") {
  //         setCompanyList(res.data);
  //       }
  //     });
  //   }
  // }, [visible, account.Companies, findAll]);

  const handleOk = (e) => {
    form.validateFields().then((values, err) => {
      if (!err) {
        const params = {
          accountId: account.id,
          companyId: account.Companies[0].id,
          guestEmail: values.email,
          url: process.env.REACT_APP_DOMAIN,
        };
        create(params).then((res) => {
          if (res.status === "success") {
            dispatch({ type: "refresh" });
            setVisible(false);
            form.resetFields();
          }
          setSubmitting(false);
        });
      }
    });
  };

  const handleCancel = () => setVisible(false);

  return (
    <Modal
      // title={t("collaborator.addNewTitle")}
      confirmLoading={submitting}
      centered
      visible={visible}
      destroyOnClose
      onCancel={handleCancel}
      onOk={handleOk}
      okText={t("collaborator.sendInvitation")}
      cancelText={t("action.cancel")}
    >
      <Title level={5} style={{ marginTop: 16, marginBottom: 16 }}>
        {t("message.inviteCollaborator")}
      </Title>
      <Form form={form}>
        <Form.Item name="email" rules={[{ required: true, type: "email" }]}>
          <Input placeholder="Invite by email" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddModal;
