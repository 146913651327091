import React, { useState, useEffect, useContext } from "react";
import { BrowserRouter as Router, Switch, Route, Redirect, useParams } from "react-router-dom";
import { Layout, notification, Spin } from "antd";

import "./BaseLayout.css";
import SideMenu from "./components/SideMenu";
import AppHeader from "./components/AppHeader";

import Account from "../../pages/account/Account";
import Notification from "../../pages/account/Notification";

import { AuthContext } from "../../App";
import Product from "../../pages/product/Product";
import Uom from "../../pages/uom/Uom";
import Collaborator from "../../pages/collaborator/Collaborator";
// import BlanketOrder from "../../pages/blanketOrder/BlanketOrder";
// import ShipmentOrder from "../../pages/shipmentOrder/ShipmentOrder";
// import SignUp from "../../pages/signUp/SignUp";
import Home from "../../pages/home";
import { useAuth } from "../../hooks/useAuth";
import { useAccount } from "../../hooks/useAccount";
// import Login from "../login/Login";
import Dashboard from "../../pages/dashboard/Dashboard";
import SalesOrder from "../../pages/salesOrder/SalesOrder";
import PurchaseOrder from "../../pages/purchaseOrder/PurchaseOrder";

const { Content } = Layout;

export const AccountContext = React.createContext();

const PrivateRoute = ({ component: Component, accessRoute, ...rest }) => {
  // temporarily disable access control
  const { authState, can } = useContext(AuthContext);

  const [collapsed, setCollapsed] = useState(false);

  return authState.isAuthenticated ? (
    <Route
      {...rest}
      render={
        (props) => (
          // can(accessRoute) === true ? (
          <Layout style={{ minHeight: "100vh" }}>
            <SideMenu collapsed={collapsed} setCollapsed={setCollapsed} />

            <Layout className="site-layout">
              <AppHeader collapsed={collapsed} setCollapsed={setCollapsed} />

              <Content style={{ margin: "0 16px" }}>
                {accessRoute === "Account" ? <Component {...props} /> : <Component {...props} />}
              </Content>
            </Layout>
          </Layout>
        )
        // ) : (
        //   <Redirect to="/not-found" />
        // )
      }
    />
  ) : (
    // <Login />
    <Redirect to="/" />
  );
};

const PublicRoute = ({ component: Component, accessRoute, ...rest }) => {
  return <Route {...rest} render={(props) => <Component {...props} />} />;
};

const Activate = (props) => {
  const { token } = useParams();

  const { activate } = useAuth();

  const [redirect, setRedirect] = useState();

  useEffect(() => {
    if (token) {
      activate({
        token: token,
      }).then((res) => {
        if (res.status === "success") {
          setRedirect("/login?activation=success");
        } else {
          setRedirect("/not-found");
        }
      });
    }
  }, [token]);

  return redirect ? (
    <Redirect to={redirect} />
  ) : (
    <div
      style={{
        display: "flex",
        height: "100%",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        position: "absolute",
        background: "rgba(255,255,255,0.1)",
      }}
    >
      <Spin />
    </div>
  );
};

const BaseLayout = () => {
  const { authState, dispatch } = useContext(AuthContext);

  const { findAccountInfo } = useAccount();

  const [account, setAccount] = useState({});

  useEffect(() => {
    if (authState.isAuthenticated) {
      findAccountInfo().then((res) => {
        if (res.status === "success") {
          setAccount(res.data);
        } else {
          notification.error({
            message: "Error",
            description: res.message,
          });
          dispatch({
            type: "LOGOUT",
          });
        }
      });
    }
  }, [authState.isAuthenticated]);

  return (
    <AccountContext.Provider value={{ account, setAccount }}>
      <Router>
        <Switch>
          <PrivateRoute
            account={account}
            setAccount={setAccount}
            accessRoute="Account"
            exact
            path="/account/settings"
            component={Account}
          />
          <PrivateRoute
            account={account}
            setAccount={setAccount}
            accessRoute="Account"
            exact
            path="/account/notifications"
            component={Notification}
          />
          <PrivateRoute account={account} accessRoute="Dashboard" exact path="/dashboard" component={Dashboard} />
          <PrivateRoute account={account} accessRoute="Product" exact path="/product" component={Product} />
          <PrivateRoute account={account} accessRoute="Uom" exact path="/uom" component={Uom} />
          <PrivateRoute
            account={account}
            accessRoute="Collaborator"
            exact
            path="/collaborator"
            component={Collaborator}
          />
          <PrivateRoute account={account} accessRoute="BlanketOrder" exact path="/salesOrder" component={SalesOrder} />
          <PrivateRoute
            account={account}
            accessRoute="BlanketOrder"
            exact
            path="/purchaseOrder"
            component={PurchaseOrder}
          />

          <PublicRoute exact path="/activate/:token" component={Activate} />
          {/* <PublicRoute exact path="/signUp" component={SignUp} /> */}
          {/* <PublicRoute exact path="/login" component={Login} /> */}
          <PublicRoute exact path="/" component={Home} />

          <Route render={() => <Redirect to="/" />} />
        </Switch>
      </Router>
    </AccountContext.Provider>
  );
};

export default BaseLayout;
