import { useCallback, useContext } from "react";
import { AuthContext, fp } from "../App";
import Constants from "../utils/Constants";

const apiBaseUrl = Constants.SECURITY_URL;

const useAuth = () => {
  const authCtx = useContext(AuthContext);

  const register = useCallback(async (params) => {
    const config = {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        FpId: (await fp).visitorId,
      },
      body: JSON.stringify(params),
    };
    return await fetch(apiBaseUrl + "/register", config)
      .then((res) => res.json())
      .catch((err) => err);
  }, []);

  const activate = useCallback(async (params) => {
    const config = {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        FpId: (await fp).visitorId,
      },
      body: JSON.stringify(params),
    };
    return await fetch(apiBaseUrl + "/activate", config)
      .then((res) => res.json())
      .catch((err) => err);
  }, []);

  const login = useCallback(async (params) => {
    const config = {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        FpId: (await fp).visitorId,
      },
      body: JSON.stringify(params),
      credentials: "include",
    };
    return await fetch(apiBaseUrl + "/login", config)
      .then((res) => res.json())
      .catch((err) => err);
  }, []);

  const logout = useCallback(async () => {
    const config = {
      method: "post",
      headers: {
        Authorization: "Bearer " + authCtx.authState.token,
        "Content-Type": "application/json",
        FpId: (await fp).visitorId,
      },
      credentials: "include",
    };
    return await fetch(apiBaseUrl + "/logout", config)
      .then((res) => res.json())
      .catch((err) => err);
  }, [authCtx]);

  const auth = useCallback(async () => {
    const config = {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        FpId: (await fp).visitorId,
      },
      credentials: "include",
    };
    return await fetch(apiBaseUrl + "/auth", config)
      .then((res) => res.json())
      .catch((err) => err);
  }, []);

  const updatePassword = useCallback(
    async (params) => {
      const config = {
        method: "post",
        headers: {
          Authorization: "Bearer " + authCtx.authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
        body: JSON.stringify(params),
      };
      return await fetch(apiBaseUrl + "/auth/profile/securitySettings/updatePassword", config)
        .then((res) => res.json())
        .catch((err) => err);
    },
    [authCtx]
  );

  return { register, activate, login, logout, auth, updatePassword };
};

export { useAuth };
