import React, { useEffect, useState } from "react";
import { Typography, List, Button } from "antd";
import { useTranslation } from "react-i18next";
import { CloseCircleOutlined, CheckCircleOutlined } from "@ant-design/icons";
import { green, red } from "@ant-design/colors";
import { useInvitation } from "../../../hooks/useInvitation";

const { Title } = Typography;

const Invitation = (props) => {
  const { account } = props;
  const { t } = useTranslation();

  const { findAll, update } = useInvitation();

  const [refresh, setRefresh] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (refresh) {
      findAll({ inviteeEmail: account.email, status: "Pending" }).then((res) => {
        if (res.status === "success") {
          setData(res.data);
        }
        setRefresh(false);
      });
    }
  }, [account, refresh]);

  const accept = (item) => {
    update(item.id, { status: "Accept" }).then((res) => {
      if (res.status === "success") {
        setRefresh(true);
      }
    });
  };

  const reject = (item) => {
    update(item.id, { status: "Reject" }).then((res) => {
      if (res.status === "success") {
        setRefresh(true);
      }
    });
  };

  return (
    <>
      <Title level={4}>{t("account.setting.invitation")}</Title>
      <List
        itemLayout="horizontal"
        dataSource={data}
        renderItem={(item) => (
          <List.Item
            actions={[
              <Button
                type="text"
                icon={<CheckCircleOutlined style={{ color: green.primary }} />}
                onClick={() => accept(item)}
              />,
              <Button
                type="text"
                icon={<CloseCircleOutlined style={{ color: red.primary }} />}
                onClick={() => reject(item)}
              />,
            ]}
          >
            <List.Item.Meta title={item.Company?.name} />
            <div>{item.status}</div>
          </List.Item>
        )}
      />
    </>
  );
};

export default Invitation;
