import { useCallback, useContext } from "react";
import { AuthContext, fp } from "../App";
import Constants from "../utils/Constants";
import { useFetch } from "../hooks/useFetch";

const apiBaseUrl = Constants.SERVER_URL;

const useDocumentUpload = () => {
  const { authState } = useContext(AuthContext);
  const { send } = useFetch();

  const findAll = useCallback(
    async (query) => {
      const url = apiBaseUrl + "/wapi/documentUploads?docType=" + query.docType + "&foreignId=" + query.foreignId;

      const config = {
        method: "get",
        headers: {
          Authorization: "Bearer " + authState.token,
          FpId: (await fp).visitorId,
        },
      };

      return await send(url, config);
    },
    [authState.token, send]
  );

  const uploadFileByDocument = useCallback(
    async (docId, params) => {
      const url = apiBaseUrl + "/wapi/documentUploads/" + docId + "/updateAssets/upload";

      const config = {
        method: "post",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "multipart/form-data",
          FpId: (await fp).visitorId,
        },
        body: params,
      };

      return await send(url, config);
    },
    [authState.token, send]
  );

  const uploadDocument = useCallback(
    async (params) => {
      const url = apiBaseUrl + "/wapi/documentUploads";

      const config = {
        method: "post",
        headers: {
          Authorization: "Bearer " + authState.token,
          // 'Content-Type': 'multipart/form-data',
          FpId: (await fp).visitorId,
        },
        body: params,
      };

      return await send(url, config);
    },
    [authState.token, send]
  );

  const getAssetsByDocument = useCallback(
    async (id) => {
      const url = apiBaseUrl + "/wapi/documentUploads/" + id + "/assetFileList";

      const config = {
        method: "get",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
      };

      return await send(url, config);
    },
    [authState.token, send]
  );

  const removeAssetByDocument = useCallback(
    async (id, params) => {
      const url = apiBaseUrl + "/wapi/documentUploads/" + id + "/updateAssets/remove";

      const config = {
        method: "post",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
        body: JSON.stringify(params),
      };

      return await send(url, config);
    },
    [authState.token, send]
  );

  const fileSrc = (endpoint) => {
    return apiBaseUrl + "/public/images/" + endpoint;
  };

  const download = useCallback(
    async (params) => {
      const config = {
        method: "post",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
        body: JSON.stringify(params),
      };

      return await fetch(apiBaseUrl + "/wapi/documentUploads/download", config);
    },
    [authState.token]
  );

  return {
    findAll,
    // uploadFileByDocument,
    uploadDocument,
    // getAssetsByDocument,
    // removeAssetByDocument,
    // fileSrc,
    download,
  };
};

export { useDocumentUpload };
