import React, { useContext, useEffect, useState, useReducer } from "react";
import { useTranslation } from "react-i18next";
import { Drawer, Tabs, Row, Col, Button } from "antd";

import { useBlanketOrderDetail } from "../../hooks/useBlanketOrderDetail";

import NewBlanketOrderDetailModal from "./NewBlanketOrderDetailModal";
import EditBlanketOrderDetailModal from "./EditBlanketOrderDetailModal";
import ConfirmDeleteModal from "../common/ConfirmDeleteModal";

import DocumentList from "../document_upload/components/DocumentList";
import OrderNote from "../order_note/OrderNote";

import BlanketOrderInfo from "./BlanketOrderInfo";
import BlanketOrderDetailTable2 from "./BlanketOrderDetailTable2";
import { fetchDataReducer } from "../../reducers/fetchDataReducer";
import { useBlanketOrder } from "../../hooks/useBlanketOrder";
import { AccountContext } from "../layouts/BaseLayout";
import PageLoader from "../common/PageLoader";

const { TabPane } = Tabs;

// Use reducer here because these 3 states are all inter-connected.
const initialState = {
  refresh: true,
  loading: true,
  error: null,
};

const BlanketOrderDetailDrawer = (props) => {
  const { account } = useContext(AccountContext);
  const { t } = useTranslation();

  const { type, defaultTabKey, id, visible, setVisible } = props;

  const { findOne } = useBlanketOrder();

  const { destroy } = useBlanketOrderDetail();

  const [state, dispatch] = useReducer(fetchDataReducer, initialState);

  const [order, setOrder] = useState({});
  const [orderItem, setOrderItem] = useState({});
  const [newBlanketOrderDetailModal, setNewBlanketOrderDetailModal] = useState(false);
  const [editBlanketOrderDetailModal, setEditBlanketOrderDetailModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [canEdit, setCanEdit] = useState(false);

  useEffect(() => {
    if (visible && id) {
      findOne(id).then((res) => {
        if (res.status === "success") {
          setOrder(res.data);
        }
        dispatch({ type: "complete" });
      });
    }
  }, [visible, id]);

  useEffect(() => {
    if (visible && id && state.refresh) {
      findOne(id).then((res) => {
        if (res.status === "success") {
          setOrder(res.data);
        }
        dispatch({ type: "complete" });
      });
    }
  }, [visible, id, state.refresh]);

  useEffect(() => {
    if (order.id && account.Companies) {
      setCanEdit(order.companyId === account.Companies[0].id);
    }
  }, [order, account.Companies]);

  const onClose = () => {
    setVisible(false);
  };

  const editOrderItem = (item) => {
    setOrderItem(item);
    setEditBlanketOrderDetailModal(true);
  };

  const deleteOrderItem = (item) => {
    setOrderItem(item);
    setDeleteModal(true);
  };

  const confirmDelete = () => {
    destroy(orderItem.id, { blanketOrderId: orderItem.blanketOrderId }).then((res) => {
      if (res.status === "success") {
        dispatch({ type: "refresh" });
        setDeleteModal(false);
      }
    });
  };

  return (
    <Drawer
      // title={t("blanketOrder.detailTitle")}
      placement="right"
      onClose={onClose}
      visible={visible}
      width={"75%"}
    >
      {state.loading ? (
        <PageLoader />
      ) : (
        <>
          <BlanketOrderInfo item={order} type={type} />

          <Tabs defaultActiveKey={defaultTabKey}>
            <TabPane tab={t("common.productList")} key="productList">
              {canEdit && (
                <Row style={{ marginBottom: 16 }}>
                  <Col>
                    <Button type="primary" onClick={() => setNewBlanketOrderDetailModal(true)}>
                      {t("action.add")}
                    </Button>
                  </Col>
                </Row>
              )}
              <BlanketOrderDetailTable2
                loading={false}
                error={false}
                dataSource={order.BlanketOrderDetails}
                editRow={editOrderItem}
                deleteRow={deleteOrderItem}
                blanketOrder={order}
              />
            </TabPane>

            <TabPane tab={t("common.document")} key="documentList">
              <DocumentList docType="BlanketOrder" foreignId={id} />
            </TabPane>

            <TabPane tab={t("orderNote.title")} key="orderNote">
              <OrderNote orderId={id} orderType="BlanketOrder" />
            </TabPane>
          </Tabs>
        </>
      )}
      <NewBlanketOrderDetailModal
        order={order}
        visible={newBlanketOrderDetailModal}
        setVisible={setNewBlanketOrderDetailModal}
        dispatch={dispatch}
      />

      <EditBlanketOrderDetailModal
        editItem={orderItem}
        visible={editBlanketOrderDetailModal}
        setVisible={setEditBlanketOrderDetailModal}
        dispatch={dispatch}
      />

      <ConfirmDeleteModal visible={deleteModal} setVisible={setDeleteModal} onOk={confirmDelete} />
    </Drawer>
  );
};

export default BlanketOrderDetailDrawer;
