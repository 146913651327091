import React from "react";
import { Form, Input, Button } from "antd";
import { useTranslation } from "react-i18next";

const BasicInfoForm = (props) => {
  const { t } = useTranslation();

  const { form, submitting, handleSubmit } = props;

  return (
    <Form form={form} name="basic-info-form" layout="vertical">
      <Form.Item name="email" label={t("common.email")} rules={[{ required: true }]}>
        <Input type="email" disabled />
      </Form.Item>

      <Form.Item name="name" label={t("common.name")} rules={[{ required: true }]}>
        <Input />
      </Form.Item>

      <Form.Item name="refereeCode" label={t("common.referralCode")}>
        <Input disabled />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" disabled={submitting} onClick={handleSubmit}>
          {t("action.submit")}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default BasicInfoForm;
