import React, { Text } from "react";
import { List } from "antd";
import TradeManagerLogo from "../../assets/TradeManager.png";
import Centralize from "../../assets/Centralize.png";
import Immutability from "../../assets/Immutability.png";
import Realtime from "../../assets/Realtime.png";
import Consolidate from "../../assets/Consolidate.png";
import Compliance from "../../assets/Compliance.png";
import Banner from "../../assets/Banner.png";

export const Nav00DataSource = {
  wrapper: { className: "header0 home-page-wrapper" },
  page: { className: "home-page" },
  logo: {
    className: "header0-logo",
    // children: "https://os.alipayobjects.com/rmsportal/mlcYmsRilwraoAe.svg",
    children: TradeManagerLogo,
  },
  Menu: {
    className: "header0-menu",
    children: [
      {
        name: "item0",
        className: "header0-item",
        children: {
          href: "#",
          children: [{ children: "Home", name: "home" }],
        },
      },
      {
        name: "item1",
        className: "header0-item",
        children: {
          href: "#priceList",
          children: [{ children: "Pricing", name: "pricing" }],
        },
      },
      {
        name: "signUp",
        className: "header0-item",
        children: {
          // href: "/signUp",
          children: [{ children: "Sign Up", name: "signUp" }],
        },
      },
      {
        name: "item3",
        className: "header0-item",
        children: {
          href: "https://www.sinisana.net",
          target: "_blank",
          children: [{ children: "About Us", name: "aboutUs" }],
        },
      },
      {
        name: "login",
        className: "header0-item",
        children: {
          // href: "https://www.sinisana.net",
          children: [{ children: "Login", name: "login" }],
        },
      },
    ],
  },
  mobileMenu: { className: "header0-mobile-menu" },
};
export const Banner50DataSource = {
  wrapper: { className: "home-page-wrapper banner5" },
  page: { className: "home-page banner5-page" },
  childWrapper: {
    className: "banner5-title-wrapper",
    children: [
      { name: "title", children: "Simplify Trade", className: "banner5-title" },
      {
        name: "explain",
        className: "banner5-explain",
        children:
          "Consolidate orders, prioritise shipments, reduce claims, and gain clarity into your supply chain. Sinisana Trade Manager cuts through the clutter to deliver the data you need to keep your supply chain running smoothly.",
      },
      {
        name: "button",
        className: "banner5-button-wrapper",
        children: {
          // href: "/signUp",
          className: "banner5-button",
          type: "primary",
          children: "Sign Up",
        },
      },
    ],
  },
  image: {
    className: "banner5-image",
    children: Banner,
  },
};
export const Content00DataSource = {
  wrapper: { className: "home-page-wrapper content0-wrapper" },
  page: { className: "home-page content0" },
  OverPack: { playScale: 0.3, className: "" },
  titleWrapper: {
    className: "title-wrapper",
    children: [{ name: "title", children: "Features & Benefits" }],
  },
  childWrapper: {
    className: "content0-block-wrapper",
    children: [
      {
        name: "block0",
        className: "content0-block",
        md: 8,
        xs: 24,
        children: {
          className: "content0-block-item",
          children: [
            {
              name: "image",
              className: "content0-block-icon",
              children: Realtime,
            },
            {
              name: "title",
              className: "content0-block-title",
              children: "Real-time Visibility",
            },
            {
              name: "content",
              children:
                "See what's happening in your supply chain in real-time. React instantly to possible stock shortages, shipment delays, and other issues that has an impact on your supply chain.",
            },
          ],
        },
      },
      {
        name: "block1",
        className: "content0-block",
        md: 8,
        xs: 24,
        children: {
          className: "content0-block-item",
          children: [
            {
              name: "image",
              className: "content0-block-icon",
              children: Centralize,
            },
            {
              name: "title",
              className: "content0-block-title",
              children: "Centralize Communication",
            },
            {
              name: "content",
              children:
                "Consolidate all your buyer/supplier communication in one place, across your entire organization. Cut out friction due to miscommunication and keep everyone on point in delivering supply chain success.",
            },
          ],
        },
      },
      {
        name: "block2",
        className: "content0-block",
        md: 8,
        xs: 24,
        children: {
          className: "content0-block-item",
          children: [
            {
              name: "image",
              className: "content0-block-icon",
              children: Immutability,
            },
            {
              name: "title",
              className: "content0-block-title",
              children: "Immutable Data",
            },
            {
              name: "content",
              children:
                "Create a single source of truth across your supply chain. All order commitments and communication are logged on the blockchain for an audit trail that you can trust 100%. ",
            },
          ],
        },
      },
    ],
  },
};
export const Content10DataSource = {
  wrapper: { className: "home-page-wrapper content1-wrapper" },
  OverPack: { className: "home-page content1", playScale: 0.3 },
  imgWrapper: { className: "content1-img", md: 10, xs: 24 },
  img: {
    children: Consolidate,
  },
  textWrapper: { className: "content1-text", md: 14, xs: 24 },
  title: { className: "content1-title", children: "Order Consolidation" },
  content: {
    className: "content1-content",
    children: (
      <List
        dataSource={[
          "Order Management.",
          "Track performance of each order.",
          "Easy to view dashbord showing status for all orders.",
          "Self-lookup features removes bottleneck due to timezone differences.",
          "Create blanket orders with multiple shipment orders.",
          "Keep track of outstanding, packed, and shipped quantities.",
        ]}
        renderItem={(item) => (
          <List.Item style={{ textAlign: "justify", padding: 0, margin: 2, border: 0 }}>
            <List.Item.Meta avatar="◾️" title={item} />
          </List.Item>
        )}
      />
    ),
  },
};
export const Feature20DataSource = {
  wrapper: { className: "home-page-wrapper content1-wrapper" },
  OverPack: { className: "home-page content1", playScale: 0.3 },
  imgWrapper: { className: "content1-img", md: 10, xs: 24 },
  img: {
    children: Compliance,
  },
  textWrapper: { className: "content1-text", md: 14, xs: 24 },
  title: { className: "content1-title", children: "Compliance" },
  content: {
    className: "content1-content",
    children: (
      <List
        dataSource={[
          "Track inspection reports, test reports, container loading pattern, and any other requirements on per-shipment basis.",
          "Reduce compensation claims due to poor compliance or late submission of required documentation.",
          "Supports PDF documents, photos, and video proofs.",
        ]}
        renderItem={(item) => (
          <List.Item style={{ textAlign: "justify", padding: 0, margin: 2, border: 0 }}>
            <List.Item.Meta avatar="◾️" title={item} />
          </List.Item>
        )}
      />
    ),
  },
};

export const Pricing10DataSource = {
  wrapper: { id: "priceList", className: "home-page-wrapper pricing1-wrapper" },
  page: { className: "home-page pricing1" },
  OverPack: { playScale: 0.3, className: "pricing1-content-wrapper" },
  titleWrapper: {
    className: "pricing1-title-wrapper",
    children: [{ name: "title", children: "Pricing", className: "pricing1-title-h1" }],
  },
  block: {
    className: "pricing1-block-wrapper",
    children: [
      {
        name: "block0",
        className: "pricing1-block",
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: "pricing1-block-box alternate" },
          topWrapper: { className: "pricing1-top-wrapper" },
          name: { className: "pricing1-name", children: "Trade 5" },
          money: { className: "pricing1-money", children: "999", currency: "US$", frequency: "per month" },
          content: {
            className: "pricing1-content",
            children: (
              <List
                dataSource={[
                  "Order Management",
                  "Compliance Document",
                  "Blockhain Immutability",
                  "Attach photos/images",
                  "Up to US$5 million in orders processed annually",
                  "Up to 50 order transactions per month",
                  "Up to 15 external collaborators",
                  "1 Gb of storage for images and documents",
                ]}
                renderItem={(item) => (
                  <List.Item style={{ textAlign: "start", padding: 0, margin: 2, border: 0 }}>
                    <List.Item.Meta avatar="✔️" title={item} />
                  </List.Item>
                )}
              />
            ),
          },
          line: { className: "pricing1-line" },
          buttonWrapper: {
            className: "pricing1-button-wrapper",
            children: {
              a: {
                className: "pricing1-button",
                // href: "/signUp",
                children: "Buy Now",
                type: "primary",
              },
            },
          },
        },
      },
      {
        name: "block1",
        className: "pricing1-block",
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: "pricing1-block-box active" },
          topWrapper: { className: "pricing1-top-wrapper" },
          name: { className: "pricing1-name", children: "Trade 10" },
          money: { className: "pricing1-money", children: "1499", currency: "US$", frequency: "per month" },
          content: {
            className: "pricing1-content",
            children: (
              <List
                dataSource={[
                  "Order Management",
                  "Compliance Document",
                  "Blockhain Immutability",
                  "Attach photos/images",
                  "Up to US$10 million in orders processed annually",
                  "Up to 100 order transactions per month",
                  "Up to 25 external collaborators",
                  "4 Gb of storage for images and documents",
                ]}
                renderItem={(item) => (
                  <List.Item style={{ textAlign: "start", padding: 0, margin: 2, border: 0 }}>
                    <List.Item.Meta avatar="✔️" title={item} />
                  </List.Item>
                )}
              />
            ),
          },
          line: { className: "pricing1-line" },
          buttonWrapper: {
            className: "pricing1-button-wrapper",
            children: {
              a: {
                className: "pricing1-button",
                // href: "/signUp",
                children: "Buy Now",
                type: "primary",
              },
            },
          },
        },
      },
      {
        name: "block2",
        className: "pricing1-block",
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: "pricing1-block-box alternate " },
          topWrapper: { className: "pricing1-top-wrapper" },
          name: { className: "pricing1-name", children: "Trade 20" },
          money: { className: "pricing1-money", children: "1999", currency: "US$", frequency: "per month" },
          content: {
            className: "pricing1-content",
            children: (
              <List
                dataSource={[
                  "Order Management",
                  "Compliance Document",
                  "Blockhain Immutability",
                  "Attach photos/images",
                  "Up to US$20 million in orders processed annually",
                  "Up to 250 order transactions per month",
                  "Up to 35 external collaborators",
                  "10 Gb of storage for images and documents",
                ]}
                renderItem={(item) => (
                  <List.Item style={{ textAlign: "start", padding: 0, margin: 2, border: 0 }}>
                    <List.Item.Meta avatar="✔️" title={item} />
                  </List.Item>
                )}
              />
            ),
          },
          line: { className: "pricing1-line" },
          buttonWrapper: {
            className: "pricing1-button-wrapper",
            children: {
              a: {
                className: "pricing1-button",
                // href: "/signUp",
                children: "Buy Now",
                type: "primary",
              },
            },
          },
        },
      },
    ],
  },
};
export const Footer00DataSource = {
  wrapper: { className: "home-page-wrapper footer0-wrapper" },
  OverPack: { className: "home-page footer0", playScale: 0.05 },
  copyright: {
    className: "copyright",
    children: (
      <span>
        ©2021 <a href="https://www.sinisana.net">Sinisana Technologies Sdn. Bhd.</a> All Rights Reserved
      </span>
    ),
  },
};
