import React, { useContext } from "react";
import { Dropdown, Menu, Button } from "antd";
import { EditOutlined, DeleteOutlined, EllipsisOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import TableLoader from "../common/TableLoader";
import { AccountContext } from "../layouts/BaseLayout";

const moment = require("moment");

const BlanketOrderDetailTable2 = (props) => {
  const { account } = useContext(AccountContext);
  const { t } = useTranslation();

  const { dataSource, loading, error, editRow, deleteRow, blanketOrder } = props;

  const columns = [
    {
      title: t("common.product"),
      render: (text, row) => row.Product.productName,
    },
    {
      title: t("blanketOrder.requestShipDate"),
      dataIndex: "requestShipDate",
      render: (text) => (text ? moment(text).format("YYYY-MM-DD") : "-"),
    },
    {
      title: t("common.price"),
      dataIndex: "uomPrice",
      render: (text) => (text ? Number(text).toLocaleString() : "-"),
    },
    {
      title: t("common.quantity"),
      dataIndex: "quantityInUom",
      render: (text) => Number(text).toLocaleString(),
    },
    {
      title: t("common.uom"),
      render: (text, row) => (row.Uom ? row.Uom.shortCode : "-"),
    },
    {
      title: t("common.remarks"),
      dataIndex: "remarks",
      render: (text) => (text ? text : "-"),
    },
    {
      title: t("action.title"),
      align: "center",
      key: "action",
      hidden: blanketOrder.companyId !== account.Companies[0].id,
      render: (text, row) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item key="edit" icon={<EditOutlined />} onClick={() => editRow(row)}>
                {t("action.edit")}
              </Menu.Item>
              <Menu.Item key="delete" danger icon={<DeleteOutlined />} onClick={() => deleteRow(row)}>
                {t("action.delete")}
              </Menu.Item>
            </Menu>
          }
        >
          <Button type="link" icon={<EllipsisOutlined />} />
        </Dropdown>
      ),
    },
  ].filter((item) => !item.hidden);

  return (
    <TableLoader error={error} dataSource={dataSource} loading={loading} rowKey="id" size="small" columns={columns} />
  );
};

export default BlanketOrderDetailTable2;
