import React from "react";
import { Form, Select, Card } from "antd";

const { Option } = Select;

const SignUpFormSubscription = (props) => {
  const { hidden, planOption, selectedPlan, setSelectedPlan, loading } = props;

  const onChange = (value) => {
    const index = planOption.map((item) => item.id).indexOf(value);

    if (index > -1) {
      setSelectedPlan(planOption[index]);
    }
  };

  return (
    <>
      <h4 style={{ marginBottom: 24 }} hidden={hidden}>
        Select a subscription plan:
      </h4>
      <Form.Item name="planId" label="Subscription Plan" rules={[{ required: true }]} hidden={hidden}>
        <Select allowClear onChange={onChange} loading={loading}>
          {planOption.map((item) => (
            <Option key={item.id} value={item.id}>
              {item.name}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Card
        loading={loading}
        title={selectedPlan.name}
        size="small"
        style={{ marginTop: 16 }}
        hidden={hidden}
        extra={
          selectedPlan.currency_code
            ? selectedPlan.currency_code +
              " " +
              Number(selectedPlan.price / 100).toLocaleString() +
              " / " +
              selectedPlan.period_unit
            : ""
        }
      >
        {selectedPlan.description?.split("\n").map((item) => (
          <div>{item}</div>
        ))}
      </Card>
    </>
  );
};

export default SignUpFormSubscription;
