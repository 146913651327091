import React from 'react';
import { useTranslation } from "react-i18next";
import { Button } from 'antd';
import TableLoader from '../../../components/common/TableLoader';

const UomTable = (props) => {

    const { t } = useTranslation();

    const { dataSource, loading, error, editRow } = props;

	const columns = [
		{
			title: t("common.id"),
			dataIndex: "id"
		},
		{
			title: t("uom.shortCode"),
			dataIndex: "shortCode"
		},
		{
			title: t("common.name"),
			dataIndex: "name"
		},
		{
			title: t("common.action"),
			key: 'action',
			render: (text, row) => (
				<Button type="link" onClick={() => editRow(row)}>{t("action.edit")}</Button>
			),
		},
	];

    return (
        <TableLoader
            error={error}
            loading={loading}
            dataSource={dataSource}
            columns={columns}
            style={{ marginTop: 24 }} />
    )
}

export default UomTable;