import React from "react";
import { Form, Steps, Divider } from "antd";
import SignUpFormAccountInfo from "../signUp/SignUpFormAccountInfo";
import SignUpFormContactInfo from "../signUp/SignUpFormContactInfo";
import SignUpFormComplete from "../signUp/SignUpFormComplete";

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

const { Step } = Steps;

const AcceptInvitationForm = (props) => {
  const { form, currentStep } = props;

  return (
    <Form {...layout} form={form} name="signup-form" autoComplete="true" labelAlign="left">
      <Steps current={currentStep} size="small">
        <Step title="Account" />
        <Step title="Company" />
        <Step title="Complete" />
      </Steps>

      <Divider />

      <SignUpFormAccountInfo hidden={!(currentStep === 0)} />

      <SignUpFormContactInfo hidden={!(currentStep === 1)} />

      {currentStep === 2 && <SignUpFormComplete />}
    </Form>
  );
};

export default AcceptInvitationForm;
