import React from "react";
import { Descriptions, Row, Col, Typography } from "antd";
import { useTranslation } from "react-i18next";
import moment from "moment";

const { Title } = Typography;

const BlanketOrderInfo = (props) => {
  const { item, type } = props;

  const { t } = useTranslation();

  return (
    <>
      <Title level={5}>
        {t("common.orderNo").toUpperCase()} : {item.orderNo}
      </Title>
      <Row justify="space-between">
        <Col span={16}>
          <Descriptions column={3}>
            <Descriptions.Item label={t("common.company")} span={2}>
              {type === "in" ? item.Requestor?.name : item.Supplier?.name}
            </Descriptions.Item>

            <Descriptions.Item label={t("common.date")}>
              {moment(item.orderDate).format("YYYY-MM-DD")}
            </Descriptions.Item>

            <Descriptions.Item label={t("blanketOrder.effectiveDate")} span={2}>
              {item.effectiveStartDt && item.effectiveEndDt
                ? moment(item.effectiveStartDt).format("YYYY-MM-DD") +
                  " " +
                  t("common.to") +
                  " " +
                  moment(item.effectiveEndDt).format("YYYY-MM-DD")
                : t("blanketOrder.effectiveUntilCancel")}
            </Descriptions.Item>

            <Descriptions.Item label={t("blanketOrder.carrier")}>{item.carrier ? item.carrier : "-"}</Descriptions.Item>

            <Descriptions.Item label={t("blanketOrder.comment")} span={2}>
              {item.comment ? item.comment : "-"}
            </Descriptions.Item>

            <Descriptions.Item label={t("blanketOrder.commercialTerm")}>
              {item.commercialTerm ? item.commercialTerm : "-"}
            </Descriptions.Item>

            <Descriptions.Item label={t("blanketOrder.shipTo")} span={3}>
              {item.shipTo ? item.shipTo : "-"}
            </Descriptions.Item>

            <Descriptions.Item label={t("blanketOrder.deliveryInstruction")} span={3}>
              {item.deliveryInstruction ? item.deliveryInstruction : "-"}
            </Descriptions.Item>
          </Descriptions>
        </Col>

        <Col span={8}>
          <Row justify="space-between" align="middle">
            <Col span={12}>
              <div style={{ color: "grey", textAlign: "right" }}>{t("common.status")}</div>
              <div style={{ fontSize: 24, textAlign: "right" }}>{item.status ? item.status : "-"}</div>
            </Col>
            <Col span={12}>
              <div style={{ color: "grey", textAlign: "right" }}>{t("common.amount")}</div>
              <div style={{ fontSize: 24, textAlign: "right" }}>
                {item.totalAmount ? Number(item.totalAmount).toLocaleString() : "-"}
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default BlanketOrderInfo;
